import React from 'react';
import { Button, Typography } from "@mui/material";
import { decodeString } from "findem-helpers";
import styled from "styled-components";
import { colors } from '../../../common/constants';

const MAX_DESCRIPTION_LENGTH: number = 200;

export const Description: React.FC<{
  description: string;
  maxLength?: number;
  autoExpand?: boolean;
}> = ({ autoExpand, description, maxLength }) => {
  const [expand, setExpand] = React.useState<boolean>(autoExpand ?? false);

  const hasMore: boolean = React.useMemo(
    () => description.length > (maxLength || MAX_DESCRIPTION_LENGTH),
    [description.length, maxLength]
  );
  const showMore: React.MouseEventHandler = React.useCallback((event) => {
    event.stopPropagation();
    setExpand(true);
  }, []);

  const truncatedDescription: string = React.useMemo(
    () => description.substring(0, maxLength || MAX_DESCRIPTION_LENGTH),
    [description, maxLength]
  );

  return (
    <>
      <Typography variant='body2' sx={{color: colors['grey-600']}}>
        {decodeString(expand ? description : truncatedDescription)}
        {hasMore && !expand && (
          <SeeMoreButton className="see-more" variant="text" onClick={showMore}>
            See more
          </SeeMoreButton>
        )}
      </Typography>
    </>
  );
};

export const SeeMoreButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: -4px;
  padding-bottom: 0;
  box-shadow: -3px -18px 16px 2px ${colors.white};
  /* TODO: update this button to allow this. */
  background-color: ${colors.white} !important;
`;