import { createGlobalStyle } from 'styled-components';
import { colors } from './common/constants';



const AppStyles = createGlobalStyle`
  html {
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    background-color: ${colors['white']};
  }

  #root {
    height: 100%;
  }

  button {
    cursor: pointer;
  }

  blockquote {
    color: ${colors['grey-500']};
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export default AppStyles;