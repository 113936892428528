// Copyright 2024 and onwards, All rights reserved.
// Author: Hariharan Kolam
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Box } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { imgOnError } from './utils';
import {
  GridActionsCellItem,
  GridFilterInputValue,
} from '@mui/x-data-grid-pro';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { biz_models } from './Defaults';
import styled from "styled-components";
import colors from './common/constants/colors.json';
import LanIcon from '@mui/icons-material/Lan';
import { fetchCompanyOrgDetails } from './Apis';

export const LOGO_URL = 'https://fm-companylogos.s3.us-east-2.amazonaws.com/'

function renderRecentFundraise(funding) {
  const sortedFunding = funding.sort((a, b) => new Date(b.date) - new Date(a.date));
  const recentFunding = sortedFunding.slice(0, 3);
  let map = [];
  recentFunding.forEach((fund) => {
    let fund_series = (fund.series ? (fund.series + '-' + new Date(fund.date).toLocaleString('default', { month: 'short', year: 'numeric' })) : '');
    map.push(fund_series);
  });
  return (
    map.join(', ')
  );
}
const bizModelsOptions = biz_models.map(bizModel => ({
  label: bizModel,
}));

const CompanyOrgChart = (props) => {
  const [hasChart, setHasChart] = React.useState(false);

  React.useEffect(() => {
    if (props.id) {
      async function orgChartApi() {
        // TODO: Replace with better api.
        const data = await fetchCompanyOrgDetails(props.id);
        if (data && data.org_info) {
          setHasChart(true);
        }
      }
      orgChartApi();
    }
  }, [props.id]);

  return (
    <>
      {hasChart && <LanIcon
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          props.handleOrgChartClick(props.id);
        }}
        style={{ 
          color: "#167FF9",
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          marginLeft: '5px',
          cursor: 'pointer',
          border: '1px solid #167FF9',
          padding: '2px',
        }} 
      />}
    </>
  );
}

export const ColumnConfig = (show_shortlist = true, show_ignore = true, shortlist_cb, ignore_cb, handleOrgChartClick) => {
  const columns = [
    {
      field: 'company_name',
      headerName: 'Company Name',
      minWidth: 200,
      group: 'Group A',
      renderCell: (params) => {
        // Adjusting for the case where company_name is an object
        const url = `https://${params.row.company_name.url}`;
        const name = params.row.company_name.name;
        return (
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <CompanyImage onError={imgOnError} src={LOGO_URL + unescape(params.row.linkedIn_id) + '.jpg'} alt={name} style={{ width: '30px', marginRight: '10px' }} />
            <Box sx={{
              color: '#8A8A8A',
              cursor: 'pointer',
              '&:hover': {
                color: '#313131',
              }
            }}>{name}</Box>
            <CompanyOrgChart id={params.row.id} handleOrgChartClick={handleOrgChartClick} />
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row.company_name.name;
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      group: 'Group A',
      renderCell: (params) => {
        const truncateText = (text, limit) => {
          const words = text.split(' ');
          if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
          }
          return text;
        };

        const fullText = params.value ? unescape(params.value) : ''; // Ensure the text is unescape
        const truncatedText = truncateText(fullText, 50); // Truncate after 100 words

        return (
          <div style={{
            whiteSpace: 'normal', // Allow text to wrap
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            lineHeight: '1.5', // Adjust line height as needed
            padding: '6px' // Make sure there is padding for the text to 'breathe'
          }} title={fullText}>
            {unescape(truncatedText)}
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row.description;
      }
    },
    { field: 'org_type', headerName: 'Organization Type', width: 200, group: 'Group A' },
    { field: 'pb_data.ownership_status', headerName: 'Ownership Status', minWidth: 200, group: 'Group B' },
    { field: 'pb_data.acquirer', headerName: 'Acquirer', minWidth: 200, group: 'Group B' },
    { field: 'pb_data.financing_status', headerName: 'Financing Status', minWidth: 200, group: 'Group B' },
    { field: 'pb_data.org_type_status', headerName: 'Org Status', minWidth: 200, group: 'Group B' },
    {
      field: 'industry.crunchbase_category_groups',
      headerName: 'Industry (Groups)',
      group: 'Group A',
      minWidth: 200,
      maxWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}>
            {params.value ? params.value.join(', ') : ""}
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row["industry.crunchbase_category_groups"];
      }
    },
    {
      field: 'industry.sub_categories',
      headerName: 'Industry',
      minWidth: 200,
      maxWidth: 300,
      group: 'Group A',
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}>
            {params.value?.join(', ') || ''}
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row["industry.sub_categories"];
      }
    },
    {
      field: 'competitors.name',
      headerName: 'Competitors',
      minWidth: 200,
      maxWidth: 300,
      group: 'Group A',
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}>
            {params.value?.join(', ') ?? ''}
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row["competitors.name"];
      }
    },
    {
      field: 'products.product_categories',
      headerName: 'Product Categories',
      minWidth: 200,
      maxWidth: 300,
      group: 'Group A',
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}>
            {params.value?.join(', ') ?? ''}
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row["products.product_categories"];
      }
    },
    {
      field: 'parent_companies.company_name',
      headerName: 'Parent Company',
      minWidth: 200,
      maxWidth: 300,
      group: 'Group A',
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}>
            {params.value?.join(', ') ?? ''}
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row["parent_companies.company_name"];
      }
    },
    {
      field: 'products.product_competitors',
      headerName: 'Product Competitors',
      minWidth: 200,
      maxWidth: 300,
      group: 'Group A',
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}>
            {params.value?.join(', ') ?? ''}
          </div>
        );
      },
      valueGetter: (params, row) => {
        // Adjusting for the case where company_name is an object
        return row["products.product_competitors"];
      }
    },
    {
      field: 'curr_financial_info.est_revenue',
      headerName: 'Estimated Revenue',
      minWidth: 200,
      group: 'Group B',
      filterOperators: [
        {
          label: 'Greater than',
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => (params) => params.value > filterItem.value,
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'Less than',
          value: 'lessThan',
          getApplyFilterFn: (filterItem) => (params) => params.value < filterItem.value,
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'Equal',
          value: 'equal',
          getApplyFilterFn: (filterItem) => (params) => params.value === filterItem.value,
          InputComponent: GridFilterInputValue,
        },
      ],
      renderCell: (params) => {
        // Function to format number to a more readable string
        const formatNumber = (num) => {
          if (num >= 1e9) {
            return `$${(num / 1e9).toFixed(2)}B`;
          } else if (num >= 1e6) {
            return `$${(num / 1e6).toFixed(2)}M`;
          } else if (num >= 1e3) {
            return `${(num / 1e3).toFixed(2)}K`;
          }
          return num ? `$${num}` : '?';
        };

        return <div>{formatNumber(params.row["curr_financial_info.est_revenue"])}</div>;
      },
      // Ensuring we have a fallback for null/undefined values
      valueGetter: (params, row) => row["curr_financial_info?.est_revenue"] || 'N/A',
    },
    {
      field: 'comp_size',
      headerName: 'Company Size',
      minWidth: 200,
      group: 'Group B',
      filterOperators: [
        {
          label: 'Greater than',
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => (params) => params.value > filterItem.value,
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'Less than',
          value: 'lessThan',
          getApplyFilterFn: (filterItem) => (params) => params.value < filterItem.value,
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'Equal',
          value: 'equal',
          getApplyFilterFn: (filterItem) => (params) => params.value === filterItem.value,
          InputComponent: GridFilterInputValue,
        },
      ],
      renderCell: (params) => {
        // Check if comp_size exists and has at least one entry
        if (params.row.comp_size && params.row.comp_size.length > 0) {
          const lastEntry = params.row.comp_size[params.row.comp_size.length - 1];
          const formattedDate = new Date(lastEntry.date).toLocaleDateString();
          const employeeCount = lastEntry.employees;

          return (
            <div>
              {employeeCount}
            </div>
          );
        } else {
          // Handle the case where comp_size is missing or empty
          return <div>No Data</div>;
        }
      },
      valueGetter: (params) => {
        // Check if comp_size exists and has at least one entry
        if (params.row && params.row.comp_size && params.row.comp_size.length > 0) {
          const lastEntry = params.row.comp_size[params.row.comp_size.length - 1];
          return parseInt(lastEntry.employees, 10);
        } else {
          // Provide a default value or handle missing comp_size
          return 0; // or null, depending on how you want to handle sorting/filtering for these cases
        }
      }
    },
    {
      field: 'funding',
      headerName: 'Funding Details',
      minWidth: 200,
      group: 'Group B',
      maxWidth: 300,
      flex: 1,
      // filterable: false,
      filterOperators: [
        {
          label: 'Greater than',
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => (params) => params.value > filterItem.value,
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'Less than',
          value: 'lessThan',
          getApplyFilterFn: (filterItem) => (params) => params.value < filterItem.value,
          InputComponent: GridFilterInputValue,
        },
        {
          label: 'Equal',
          value: 'equal',
          getApplyFilterFn: (filterItem) => (params) => params.value === filterItem.value,
          InputComponent: GridFilterInputValue,
        },
      ],

      renderCell: (params) => {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.5',
          }}>
            {renderRecentFundraise(params.row.funding)}
          </div>
        )
      },
      valueGetter: (params) => {
        // Check if comp_size exists and has at least one entry
        if (params.row && params.row.comp_size && params.row.comp_size.length > 0) {
          const lastEntry = params.row.funding[params.row.funding.length - 1];
          return parseInt(lastEntry.series, 10);
        } else {
          // Provide a default value or handle missing comp_size
          return 0; // or null, depending on how you want to handle sorting/filtering for these cases
        }
      }
    },
    { field: 'last_known_state', headerName: 'Status', minWidth: 200, group: 'Group A' },
    {
      field: 'biz_models',
      headerName: 'Business Models',
      minWidth: 200,
      renderFilterInputComponent: (props) => {
        const { item, applyValue } = props;
        return (
          <Autocomplete
            options={bizModelsOptions}
            getOptionLabel={(option) => option.label}
            value={bizModelsOptions.find(option => option.code === item.value) || null}
            onChange={(event, newValue) => {
              applyValue({ ...item, value: newValue ? newValue.code : '' });
            }}
            renderInput={(params) => <TextField {...params} label="Select Business Model" variant="standard" />}
          />
        );
      }
    },
    { field: 'hq_location.state', headerName: 'HQ Location (State)', minWidth: 200, group: 'Group B' },
    { field: 'hq_location.country', headerName: 'HQ Location (Country)', minWidth: 200, group: 'Group A' },
    { field: 'hq_location.city', headerName: 'HQ Location (City)', minWidth: 200, group: 'Group A' },
    {
      field: 'actions',
      type: 'actions',
      width: 125,
      getActions: (params) => [
        ...(show_shortlist ? [
          <Button
            variant="contained"
            size="small"
            color="primary"
            sx={{ textTransform: 'none', background: '#4599fa', borderColor: '#4599fa' }}
            startIcon={<CheckCircleIcon />}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              shortlist_cb(params.row)
            }}
          >
            Shortlist
          </Button>
        ] : []),

        ...(show_ignore ? [
          <GridActionsCellItem
            icon={<RemoveCircleIcon />}
            title="Remove Shortlist"
            label="Remove Shortlist"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              ignore_cb(params.id)
            }}
          />
        ] : []),
      ]

    },
  ];
  return columns;
}

export const CompanyImage = styled.img`
  border-radius: 50%;
  border: 1px solid #E0E0E0;
  margin-right: 4px;
`