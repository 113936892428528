// Copyright 2024 and onwards, All rights reserved.
// Author: Hariharan Kolam

import * as React from 'react';
import {
  DataGridPro
} from '@mui/x-data-grid-pro';

import { Box, Tabs, Tab } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { default as init, CustomToolbar, CustomNoRowsOverlay, myLocaleText, debounce, fetchRows, runDynColMatchCount2, CidMatchCountCache } from './utils';
import Badge from '@mui/material/Badge';
import { ColumnConfig } from './CompanyColumns';
import DynamicColumns from './DynamicColumns';
import CircularProgress from '@mui/material/CircularProgress';
import { useUrlParams } from 'findem-helpers';
import UserContext from './common/contexts/UserContext';
import BusinessIcon from '@mui/icons-material/Business';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { APP_THEME } from './common/constants/themes/AppTheme';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const appTheme = createTheme(APP_THEME);

init();
const cid_res_map = {};
export default function CreateCompanyList(oldProps) {
 
  const { joid, tab } = useUrlParams();

  const { user } = React.useContext(UserContext);

  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [sortModel, setSortModel] = React.useState([]);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [isDynamicColOpen, setIsDynamicColOpen] = React.useState(false);
  const [dynColType, setDynColType] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedDynCols, setSelectedDynCols] = React.useState([]);
  const [insertionIndex, setInsertionIndex] = React.useState(1);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const params = React.useRef(oldProps);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(() => {
    const savedModel = localStorage.getItem('columnVisibility');
    return savedModel ? JSON.parse(savedModel) : {
      id: false,
      company_name: true,
      description: true, // Default settings if nothing is saved
      org_type: true,
      "industry.crunchbase_category_groups": true,
      "industry.sub_categories": true,
      "parent_companies.company_name": false,
      "curr_financial_info.est_revenue": true,
      comp_size: true,
      funding: false,
      last_known_state: true,
      biz_models: true,
      "hq_location.state": false,
      "hq_location.country": true,
      "hq_location.city": false,
      "pb_data.ownership_status": true,
      actions: true,
    };
  });

  React.useEffect(() => {
    params.openRef = oldProps.openRef;
    params.selectedList = oldProps.selectedList;
    params.selectedCompanies = oldProps.selectedCompanies;
    params.handleRemoveCompany = oldProps.handleRemoveCompany;
    params.handleSelectCompany = oldProps.handleSelectCompany;
    params.handleOrgChartClick = oldProps.handleOrgChartClick;
  }, [oldProps]); // Update the ref when `myProp` changes

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
    localStorage.setItem('columnVisibility', JSON.stringify(newModel));
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const dynamicColumnOpen = (type) => {
    setIsDynamicColOpen(true);
    setDynColType(type);
    // navigate('#dyn_col_open');
  };

  const dynamicColumnClose = () => {
    setIsDynamicColOpen(false);
    setDynColType('');
    // navigate('#company-research');
  };

  const handleSelectCompany = React.useCallback((company) => {
    setSelectedCompanies((prev) => [...prev, company.id]);
    params.handleSelectCompany(company, params.openRef);
  },[]);

  const handleRemoveCompany = (companyId) => {
    ;
    params.handleRemoveCompany(companyId, params.openRef);
    setRows((prevRows) => prevRows.filter(row => row.id !== companyId)); // Also remove from the UI
  };

  const handleOrgChartClick = React.useCallback((companyId) => {
    params.handleOrgChartClick(companyId);
  },[]);


  let cols_search = ColumnConfig(true, false, handleSelectCompany, null, handleOrgChartClick);
  cols_search = cols_search.map((col) => ({ ...col, sortable: false }));

  let cols_sl = ColumnConfig(false, true, null, handleRemoveCompany, handleOrgChartClick);
  const [searchCols, setSearchCols] = React.useState(cols_search);

  const addDynamicColumn = (columnName, vals) => {
    const newColumn = {
      field: columnName,
      headerName: columnName,
      width: 150,
      rule: vals,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        console.log('Rendering cell with data:', params.row[columnName]);  // Debug output
        if (params.row[columnName] != undefined) {
          return <div>{params.row[columnName]}</div>
        } else {
          return <CircularProgress size={20} />
        }
      }
    };

    // Insert the new column at the calculated index
    const newSearchCols = [
      ...searchCols.slice(0, insertionIndex),
      newColumn,
      ...searchCols.slice(insertionIndex)
    ];

    setInsertionIndex(insertionIndex + 1);
    setSearchCols(newSearchCols);
  };

  React.useEffect(() => {
    // Example async initialization
    const initDynamicColumns = async () => {
      // update
      populateDynCols();
      // for (let it = 0; it < selectedDynCols.length; it++) {
      //   await populateDynamicColumnValues(selectedDynCols[it].field, selectedDynCols[it].values);
      // }
    };
    initDynamicColumns().catch(console.error);
  }, [rows.length, sortModel, filterModel, hasMoreData]); // Empty dependencies array means this effect runs once on mount

  const handleOnRowsScrollEnd = React.useCallback(debounce(async () => {
    if (!hasMoreData) return; // Prevent fetching if end of data reached

    setLoading(true);
    console.log('fetching data = handleOnRowsScrollEnd ' + rows.length + ' to ' + (rows.length + 20) + '...');
    const { rows: fetchedRows, hasMoreData: newHasMoreData } = await fetchRows({
      fromIndex: rows.length,
      toIndex: rows.length + 20, // Adjust based on your pagination step
      sortModel,
      filterModel,
      searchTerm,
      selectedCompanies: params.selectedCompanies ? params.selectedCompanies : []
    });
    setLoading(false);
    setHasMoreData(newHasMoreData); // Update state based on fetch result
    setRows((prevRows) => prevRows.concat(fetchedRows));
  }, 500), [rows.length, sortModel, filterModel, hasMoreData, searchTerm]); // 500ms debounce time

  const debounceDelay = 500; // 500 ms delay

  const dynColsSelect = (name, vals) => {
    let raw_vals = [];
    vals.forEach((v) => {
      raw_vals.push(v.raw_value);
    });
    console.log("dynColsSelect: ", selectedDynCols);
    setSelectedDynCols((prev) => [...prev, {
      name: name,
      values: raw_vals
    }]);

    addDynamicColumn(name, raw_vals.join("OR"));
    populateDynCols();
    console.log(name, vals);
  }

  const populateDynCols = async () => {
    if (rows.length === 0) return;  // Early return if no rows
    // Loop over each dynamic column configuration
    for (let it = 0; it < selectedDynCols.length; it++) {
      const raw_vals = selectedDynCols[it].values;
      await runDynColMatchCount2(rows.map(row => row.id), raw_vals);  // Update cache
      const cacheKey = raw_vals.join(' OR ');

      // Initialize sub-map for this cacheKey if it doesn't already exist in cid_res_map
      cid_res_map[cacheKey] = cid_res_map[cacheKey] || {};

      // Fill cid_res_map[cacheKey] with match results from the cache
      CidMatchCountCache[cacheKey].forEach(res => {
        cid_res_map[cacheKey][res.company_id] = res.match;
      });
    }

    // Map through rows to update each with new dynamic column values
    const finalRows = rows.map(row => {
      const updatedRow = { ...row };  // Copy existing row data

      // Update row with each dynamic column's data from cid_res_map
      selectedDynCols.forEach(col => {
        const cacheKey = col.values.join(' OR ');
        updatedRow[col.name] = cid_res_map[cacheKey] && cid_res_map[cacheKey][row.id] !== undefined
          ? cid_res_map[cacheKey][row.id]  // Use new value from cache, including if it's 0
          : updatedRow[col.name];  // Preserve existing value if no new data
      });

      return updatedRow;  // Return the updated row
    });
    // console.log(finalRows);
    setRows(finalRows);  // Set the updated rows in state
  };

  const onFilterCallback = React.useCallback((y) => {
    setFilterModel(y); 
    setRows([]); 
  },[]);

  const handleOnSearch = React.useCallback(debounce((searchTerm) => {
    setSearchTerm(searchTerm);
  }, 500), []); // 500ms debounce time

  React.useEffect(() => {
    if (selectedDynCols.length > 0) {
      populateDynCols();
    }
  }, [selectedDynCols]);  // Depend on selectedDynCols to trigger updates

  React.useEffect(() => {
    // This flag is used to avoid updating the state after the component is unmounted or if the fetch has been cancelled
    let isFetchCancelled = false;

    // Debounce handler with a delay
    const handler = setTimeout(async () => {
      setLoading(true);
      try {
        const { rows: fetchedRows, hasMoreData: newHasMoreData } = await fetchRows({
          fromIndex: 0,
          toIndex: 20,
          sortModel,
          filterModel,
          searchTerm,
          selectedCompanies: params.selectedCompanies ? params.selectedCompanies : []
        });
        if (!isFetchCancelled) { // Check if the fetch was not cancelled
          setLoading(false);
          setHasMoreData(newHasMoreData);
          setRows(fetchedRows);
        }
      } catch (error) {
        if (!isFetchCancelled) { // Check if the fetch was not cancelled
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      }
    }, debounceDelay);

    // Cleanup function to cancel the fetch operation if the component is unmounted or if dependencies change
    return () => {
      isFetchCancelled = true;
      clearTimeout(handler);
    };
  }, [sortModel, filterModel, setLoading, setHasMoreData, setRows, searchTerm]); // Dependencies

  React.useEffect(() => {
    localStorage.setItem(`filterdModel_${joid || user.uid}`, JSON.stringify(filterModel));
  }, [filterModel, joid, user.uid]);

  React.useEffect(() => {
    if(tab && tab === 'shortlisted'){
      setSelectedTab(1);
    }
  },[tab]);

  React.useEffect(() => {
    if(oldProps.selectedCompanies.length > 0){
      setSelectedCompanies(oldProps.selectedCompanies.map((c) => c.id));
    }
  }, [oldProps.selectedCompanies])

  React.useLayoutEffect(() => {
    const savedFilterModel = localStorage.getItem(`filterdModel_${joid || user.uid}`);
    if(oldProps.passFilterModel){
      setFilterModel(oldProps.passFilterModel);
    }else if (savedFilterModel) {
      setFilterModel(JSON.parse(savedFilterModel));
    }
  }, [joid, user.uid, oldProps.passFilterModel]);

  React.useLayoutEffect(() => {
    if (oldProps.passDynamicColumns && oldProps.passDynamicColumns.length > 0) {
      setSelectedDynCols(oldProps.passDynamicColumns);
      const _first = oldProps.passDynamicColumns[0];
      addDynamicColumn(_first.name, _first.values.join("OR"));
      populateDynCols();
    }
  }, [oldProps.passDynamicColumns]);

  { !hasMoreData && <div>You've reached the end of the list.</div> }

  const createNoRowsOverlay = (message) => () => <CustomNoRowsOverlay message={message} />;

  if (isDynamicColOpen) {
    return <DynamicColumns selectCb={dynColsSelect} type={dynColType} open={isDynamicColOpen} handleClose={dynamicColumnClose} />;
  }

  let columnGroupingModel = [];
  if (selectedDynCols.length > 0) {
    let fields = [];
    selectedDynCols.forEach((s) => {
      fields.push({ field: s.name });
    });
    columnGroupingModel.push({
      groupId: 'dyncols',
      headerName: 'Aggregated Columns',
      description: 'Aggregated columns',
      children: fields,
    })
  }
  return (
    <ThemeProvider theme={appTheme}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs sx={{ textTransform: 'none' }} value={selectedTab} onChange={handleTabChange} aria-label="company tabs">
            <Tab sx={{ textTransform: 'none' }} label={<Box><BusinessIcon sx={{fontSize: 18, position: 'relative', top: 2, right: '2px'}}/>All Companies</Box>} />
            <Tab sx={{ textTransform: 'none' }} label={
              <Badge badgeContent={params?.selectedCompanies?.length} color="primary">
                {<Box><BookmarkIcon sx={{fontSize: 18, position: 'relative', top: 2, right: '2px'}}/>Shortlisted Companies</Box>}
              </Badge>
            } />
          </Tabs>
        </Box>
        {selectedTab === 0 && (
          <div style={{ height: 800, width: '100%', marginTop: 20 }}>
            <DataGridPro
              sx={{
                textTransform: 'none',
                '& .selected-row': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04) !important', // Highlight color
                },
              }}
              columns={searchCols}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              rows={rows.filter(row => !selectedCompanies.includes(row.id))}
              loading={loading || oldProps.isProcessing}
              headerFilters
              onRowsScrollEnd={handleOnRowsScrollEnd}
              scrollEndThreshold={200}
              sortingMode="server"
              sortModel={sortModel}
              columnGroupingModel={columnGroupingModel}
              onSortModelChange={(x) => { setSortModel(x); setRows([]); }}
              localeText={myLocaleText}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={onFilterCallback}
              getRowHeight={(params) => {
                const baseHeight = 52; // Minimum row height
                const subCat = params.model["industry.sub_categories"];
                if (Array.isArray(subCat) && subCat.length > 1) {
                  // Adjust the row height based on the number of items in industry.crunchbase_category_groups
                  return baseHeight + (subCat.length - 1) * 18; // Example: increase height for each additional item
                }
                const industryGroups = params.model["industry.crunchbase_category_groups"];
                if (Array.isArray(industryGroups) && industryGroups.length > 1) {
                  // Adjust the row height based on the number of items in industry.crunchbase_category_groups
                  return baseHeight + (industryGroups.length - 1) * 18; // Example: increase height for each additional item
                }
                return baseHeight; // Default height for rows without multiple industry groups
              }}
              initialState={{
                columns: { columnVisibilityModel: { id: false } },
                pinnedColumns: { left: ['company_name'], right: ['actions'] }
              }}
              slots={{
                toolbar: CustomToolbar,
                showQuickFilter: true,
                noRowsOverlay: createNoRowsOverlay("No matching companies"),
              }}
              slotProps={{
                toolbar: { 
                  show_dc: true, 
                  show_export: false, 
                  columns: ColumnConfig(true, false, handleSelectCompany, null, handleOrgChartClick), 
                  customCol: dynamicColumnOpen,
                  onSearch: handleOnSearch
                }
              }}
              hideFooterPagination
              onRowClick={(params) => {
                oldProps.showCompanyDetailsId(params.row.id);
                oldProps.showCompanyDetails(true);
              }}
            />
          </div>)}
        {selectedTab === 1 && (
          <Box sx={{ marginTop: `20px` }}>
            {/* Placeholder for shortlisted companies. You can add more content here later. */}
            <DataGridPro
              sx={{
                textTransform: 'none',
                '& .selected-row': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04) !important', // Highlight color
                },
              }}
              rows={params.selectedCompanies}
              columns={cols_sl}
              loading={loading}
              headerFilters
              scrollEndThreshold={200}
              localeText={myLocaleText}
              onRowClick={(params) => {
                oldProps.showCompanyDetailsId(params.row.id);
                oldProps.showCompanyDetails(true);
              }}
              getRowHeight={(params) => {
                const baseHeight = 52; // Minimum row height
                const subCat = params.model["industry.sub_categories"];
                if (Array.isArray(subCat) && subCat.length > 1) {
                  // Adjust the row height based on the number of items in industry.crunchbase_category_groups
                  return baseHeight + (subCat.length - 1) * 18; // Example: increase height for each additional item
                }
                const industryGroups = params.model["industry.crunchbase_category_groups"];
                if (Array.isArray(industryGroups) && industryGroups.length > 1) {
                  // Adjust the row height based on the number of items in industry.crunchbase_category_groups
                  return baseHeight + (industryGroups.length - 1) * 18; // Example: increase height for each additional item
                }
                return baseHeight; // Default height for rows without multiple industry groups
              }}
              initialState={{
                columns: { columnVisibilityModel: { id: false } },
                pinnedColumns: { left: ['company_name'], right: ['actions'] }
              }}
              slots={{
                toolbar: () => <CustomToolbar show_dc={false} show_export={true} columns={ColumnConfig(false, true, null, handleRemoveCompany, handleOrgChartClick)} customCol={dynamicColumnOpen} />,
                loadingOverlay: LinearProgress,
                noRowsOverlay: createNoRowsOverlay("No shortlisted companies"),
              }}
            />
          </Box>)}
      </Box>
    </ThemeProvider>
  );
}