import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../common/constants';
import { createTheme, ThemeProvider } from '@mui/material';
import { APP_THEME } from '../../../common/constants/themes/AppTheme';

interface ITableDetailsProps {
  data: {
    title: string;
    value: string | JSX.Element | undefined;
  }[]
}

const appTheme = createTheme(APP_THEME);

const TableDetails: React.FC<ITableDetailsProps> = ({ data }) => {
  return (
    <ThemeProvider theme={appTheme}>
      <Table>
        <tbody>
          {data.map(({ title, value }) => (
            <React.Fragment key={title}>
              {value && value !== '' ? <Row key={title}>
                <Cell>{title}</Cell>
                <Cell $left={true}>{value}</Cell>
              </Row> : <></>}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </ThemeProvider>
  );
};

export default TableDetails;

export const Table = styled.table`
  width: 100%;
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  margin: 12px 0px;
`;

export const Cell = styled.td<{ $left?: boolean }>`
  vertical-align: baseline;
  text-align: ${({ $left }) => ($left ? 'left' : 'right')};
  text-overflow: ellipsis;
  padding: 0.5em;
  overflow: hidden;
  color: ${colors['grey-500']};
  overflow-wrap: break-word;
  border-bottom: 1px solid ${colors['grey-300']};
  line-height: 19.6px;

  &:first-child {
    text-align: left;
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

export const Row = styled.tr`
  border-bottom: 1px solid ${colors['grey-300']};
`;

export const Thead = styled.thead`
  border-bottom: 2px solid ${colors['grey-300']};
  white-space: nowrap;
`;