export enum Role {
  FM_HM_ACL = 'FmHmAcl',
  FM_Exec_ACL = 'FmExecAcl',
  Fm_CRM = 'FmCRM'
}

export function hasUserBasicAccess(roles: string[]): boolean {
  return roles.includes(Role.FM_HM_ACL);
}

export function hasUserMapAccess(roles: string[]): boolean {
  return roles ? roles.includes(Role.FM_HM_ACL) : false;
}

export function hasUserCompanyViewAccess(roles: string[]): boolean {
  return roles ? roles.includes(Role.FM_HM_ACL) : false;
}

export function hasUserQueriesAccess(roles: string[]): boolean {
  return roles ? roles.includes(Role.FM_HM_ACL): false;
}

export function hasUserCrmAccess(roles: string[]): boolean {
  return roles ? roles.includes(Role.Fm_CRM) : false;
}