import axios from "axios";
import { pubUrl, searchUrl } from "findem-helpers";
import { router } from 'react-query-kit'

const companyList = {
  getAllCompanyLists: router.query({
    fetcher: (variables: { id: string }) =>
    axios.post(`${pubUrl}/pub/api/execmap${variables.id ? `?joid=${variables.id}` : ''}`,{joid: variables.id, type: 'fetch_all_company_lists'}, { withCredentials: true})
  }),
  getCompaniesById: router.query({
    fetcher: (variables: { refs: string[]}) =>
    axios.post(`${pubUrl}/pub/api/execmap`,{refs: variables.refs, type: 'fetchCompaniesById'}, { withCredentials: true})
  }),
  delete: router.mutation({
    mutationFn: async (variables: { ids: string[], joid?: string }) =>
      axios.post(`${pubUrl}/pub/api/execmap${variables.joid ? `?joid=${variables.joid}` : ''}`,{ids: variables.ids, type: 'remove_company_list'}, { withCredentials: true})
  }),
  getMacros: router.query({
    fetcher: (variables: { joid: string }) =>
    axios.post(`${searchUrl}/pub/api/fetch_queries${variables.joid ? `?joid=${variables.joid}` : ''}`, { only_user: true} ,{ withCredentials: true})
  }),
  getCompanyDetails: router.query({
    fetcher: (variables: { joid: string, id: string }) =>
    axios.get(`${pubUrl}/pub/api/company_details?meta_refs=${variables.id}${variables.joid ? `&joid=${variables.joid}` : ''}`, { withCredentials: true})
  }),
};

// get root key
// companyList.getKey();

export default companyList;