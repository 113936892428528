import { Container, Divider, Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import PageHeader from '../common/PageHeader';
import UserContext from '../../common/contexts/UserContext';
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import UserSelect from '../common/UserSelect/UserSelect';
import { INSIGHTS_SIDEBAR_ITEM } from '../../utils/navigationUtils';
import { constructUrlWithParams, useUrlParams } from 'findem-helpers';
import { useNavigate } from 'react-router-dom';
import { IMap } from '../../common/types/mapTypes';
import MapsSelect from './MapsSelect';
import EmptyInsights from './EmptyInsights';

interface IOptions {
  label: string;
  value: string;
}

const Insights = () => {
  const {user} = React.useContext(UserContext);
  const navigate = useNavigate();
  const {joid, ref} = useUrlParams();

  const [selectedUser, setSelectedUser] = React.useState<string>('');
  const [selectedMap, setSelectedMap] = React.useState<string|undefined>(undefined);
  const [searchLink, setSearchLink] = React.useState<string | undefined>(undefined);

  const searchId = useMemo(() => {
    // get from Map searchlink
    if(searchLink){
      return searchLink.split('/').at(-1);
    }
    return undefined;
  }, [searchLink]);


  const scriptProps = useMemo(() => {
    if(!searchId) return undefined;
    return {
      talentGroup: searchId,//(requirements ?? []).filter((req) => req.section_type === "Must Have"),
      showSidebar: true,
      joid: selectedUser || user.uid,
      size: "medium"
    };
  }, [searchId, selectedUser, user.uid]);

  const handleUserSelect = useCallback((value: string) => {
    setSelectedUser(value);
    setSearchLink(undefined);
    setSelectedMap(undefined);
    if(value.length > 0){
      navigate(constructUrlWithParams(INSIGHTS_SIDEBAR_ITEM.path, {joid: value}));
    }else{
      navigate(INSIGHTS_SIDEBAR_ITEM.path);
    }
  },[navigate]);

  const handleMapSelect = useCallback((value: string|undefined) => {
    setSelectedMap(value);
    if(value){
      const _params: Record<string, string> = {};
      if(joid){
        _params['joid'] = joid;
      }
      _params['ref'] = value;
      navigate(constructUrlWithParams(INSIGHTS_SIDEBAR_ITEM.path, _params));
    }else if(joid){
      navigate(constructUrlWithParams(INSIGHTS_SIDEBAR_ITEM.path, {joid}));
    }else{
      navigate(INSIGHTS_SIDEBAR_ITEM.path);
    }
  },[joid, navigate]);

  React.useLayoutEffect(() => {
    if(joid){
      setSelectedUser(joid);
    }
  }, [joid]);
  
  React.useLayoutEffect(() => {
    if(ref){
      setSelectedMap(ref);
    }
  }, [ref]);

  return (
    <Container sx={{ mt: 4, minWidth: "100%", display: 'inline-flex', flexDirection: 'column' }}>
      <PageHeader 
        title='Map Insights'
      />
      <Box sx={{ width: '100%', fontSize: 14, fontFamily: "Roboto, sans-serif", mb: 2, mt: 2 }}>
        <UserSelect value={selectedUser || user.uid} onChangeCB={handleUserSelect} />
        <MapsSelect 
          value={selectedMap || ''} 
          selectedUser={selectedUser || user.uid} 
          onChangeCB={handleMapSelect} 
          setSearchLink={setSearchLink}
        />
      </Box>
      <Divider sx={{m: '20px 0', borderColor: '#EAECF0'}}/>
      <Box sx={{ width: '100%', fontSize: 14, fontFamily: "Roboto, sans-serif" }}>
        {!selectedMap && <EmptyInsights/>}
        {selectedMap && searchId && <InsightsTabWrapper>
          <>
            <Helmet>
              <script
                src={`https://insights.findem.ai/static/js/main.js`}
                type="text/javascript"
                data-embed-mode-type="insights_templates_list"
                data-embed-props={JSON.stringify(scriptProps)}
                data-display-options={JSON.stringify({
                  themeOverrides: {
                    components: {
                      MuiTab: {
                        styleOverrides: {
                          root: {
                            color: "#101828", // Color for unselected tabs
                            "& .MuiTab-iconWrapper": {
                              color: "rgb(102, 112, 133)",
                            },
                          },
                        },
                      },
                    },
                  },
                })}
              />
              <link href={`https://insights.findem.ai/static/css/main.css`} type="text/css" rel="stylesheet" />
              <link href={`https://insights.findem.ai/index.css`} type="text/css" rel="stylesheet" />
              <link href="https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Round%7CMaterial+Icons+Outlined" rel="stylesheet"></link>
            </Helmet>
            <StyledInsightsRoot id="platform-analytics-root" />
          </>
        </InsightsTabWrapper>}
      </Box>
    </Container>
  );
}

export default Insights;

export const InsightsTabWrapper = styled.div`
  margin: 0 38px;
  padding-top: 4px;
  display: flex;
  flex: 1 1 auto;
  height: calc(100vh - 120px);
  #platform-analytics-root {
    height: unset;
  }
`;
export const StyledInsightsRoot = styled.div`
  display: flex;
  flex: 1 1 auto;
  form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .MuiScopedCssBaseline-root {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;