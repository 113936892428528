import React, { ChangeEvent } from "react";
import { ICompanyProduct, ICompanyProfile } from "../../../common/types/companyDetails";
import { colors } from "../../../common/constants";
import { Box, Button, Divider, Pagination, Typography } from "@mui/material";
import { appendPlural, decodeString } from "findem-helpers";
import { SeeMoreLessButton } from "./Funding";
import { Description } from "./Description";
import styled from "styled-components";
import { MAX_CONTENT_LENGTH } from "./CompanyDetails";

const PRODUCTS_PAGE_SIZE: number = 5;

const Products: React.FC<Pick<ICompanyProfile, 'products'>> = ({
  products
}) => {
  const [expand, setExpand] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const showMore: React.MouseEventHandler = React.useCallback(
    () => setExpand(true),
    []
  );
  const showLess: React.MouseEventHandler = React.useCallback(
    () => setExpand(false),
    []
  );
  const productsList: string | undefined = React.useMemo(
    () =>
      products
        ?.slice(0, MAX_CONTENT_LENGTH)
        .map((product) => product.product_name)
        .join(', '),
    [products]
  );
  const numProducts: number = React.useMemo(
    () => products?.length || 0,
    [products]
  );

  const currentProductsPage: ICompanyProduct[] = React.useMemo(
    () =>
      products?.slice(
        (currentPage - 1) * PRODUCTS_PAGE_SIZE,
        currentPage * PRODUCTS_PAGE_SIZE
      ) ?? [],
    [currentPage, products]
  );

  const handleCurrentPageChange = React.useCallback((event: ChangeEvent<any>, page: number) => {
    setCurrentPage(page);
  }, []);

  interface IProductProps {
    product: ICompanyProduct;
    forceExpand?: boolean;
  }
  
  const Product: React.FC<IProductProps> = ({ product, forceExpand }) => {
    const [expand, setExpand] = React.useState<boolean>(false);
    const showMore: React.MouseEventHandler = React.useCallback(
      () => setExpand(true),
      []
    );
    const hasMore: boolean = React.useMemo(
      () => product.product_competitors.length > MAX_CONTENT_LENGTH,
      [product.product_competitors]
    );
    const competitors: string[] = React.useMemo(
      () => product.product_competitors.map((competitor: any) => competitor.name),
      [product.product_competitors]
    );
    const truncatedContent: string = React.useMemo(
      () => competitors?.slice(0, MAX_CONTENT_LENGTH).join(', '),
      [competitors]
    );
  
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}>
        <Typography variant='h6'>{product.product_name}</Typography>
        {product.product_description && (
          <GreyText variant="body1" sx={{fontSize: '12px'}}>
            <Description description={product.product_description} />
          </GreyText>
        )}
        {competitors.length > 0 && (
          <>
            <GreyText variant="body2" sx={{fontSize: '12px'}}>
              Competitors:{' '}
              {decodeString(
                expand || forceExpand ? competitors.join(', ') : truncatedContent
              )}
            </GreyText>
            {hasMore && !(expand || forceExpand) && (
              <Button variant="text" onClick={showMore} className='detail'>
                See more
              </Button>
            )}
          </>
        )}
        <Divider style={{ display: 'flex' }} />
      </Box>
    );
  };


  return products ?
    <>
      <Typography 
          variant="h5"
          sx={{ fontSize: '14px', color: colors['grey-500'], margin: '8px 0px', fontWeight: 400, fontStretch: 'condensed', textTransform: 'uppercase'}}
          >Products</Typography>
      {expand ? (
        <>
          <Typography>
            {numProducts || 'No'} Product{appendPlural(numProducts)}
          </Typography>
          <SeeMoreLessButton
            variant="text"
            onClick={showLess}
            style={{ padding: '0' }}
          >
            See less
          </SeeMoreLessButton>
          <HeaderDivider style={{ display: 'flex' }} />
          {currentProductsPage.map((product) => (
            <Product
              product={product}
              forceExpand={true}
              key={product.product_name}
            />
          ))}
          <Pagination
            defaultPage={1}
            count={Math.ceil(numProducts / PRODUCTS_PAGE_SIZE)}
            page={currentPage}
            onChange={handleCurrentPageChange}
          />
        </>
      ) : (
        <>
          <Typography>
            {numProducts || 'No'} Product{appendPlural(numProducts)}{' '}
            {numProducts > 0 && (
              <>
                ({productsList}
                {numProducts && numProducts > MAX_CONTENT_LENGTH && ', ...'})
              </>
            )}
          </Typography>
          {numProducts > 0 && (
            <SeeMoreLessButton variant="text" onClick={showMore} className='detail'>
              See all
            </SeeMoreLessButton>
          )}
        </>
      )}
    </> : null;
}

export default Products;

export const HeaderDivider = styled(Divider)`
  border-width: 2px;
`;

export const GreyText = styled(Typography)`
  color: ${colors['grey-600']};
`;