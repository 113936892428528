import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import styled from "styled-components";
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "react-edit-text/dist/index.css";
import Divider from '@mui/material/Divider';
import { fetchTitles, fetchProductCat, fetchIndustries, fetchCompanyFields} from './Apis'
import { Button, DialogContent, DialogTitle, Box, Breadcrumbs, Link, Chip } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "./common/constants"
import { EditText } from 'react-edit-text';
import EditIcon from '@mui/icons-material/Edit';
import EastIcon from '@mui/icons-material/East';
import { BIZMODELS, LKS, COUNTRIES } from './utils';
import {CompanySearchableFields} from './common/constants/CompanySearchableFields';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const SIZE = [
  { label: "any", value:"any" },
  { label: "100+", value:"100" },
  { label: "1000+", value:"1000" },
  { label: "5000+", value:"5000" },
  { label: "25000+", value:"25000" },
  { label: "100000+", value:"100000+" },
];
const ROLECOUNT = [
  { label: "any", value:"any" },
  { label: "0-10", value:"0_10" },
  { label: "10-100", value:"10_100" },
  { label: "100-500", value:"100_500" },
  { label: "500-1000", value:"500_1000" },
];
const JOB_TITLES = [
  { label: "any title", value: "any" },
  { label: "VP Sales", value: "vp-sales" },
  { label: "VP Finance", value: "vp-finance" },
  { label: "VP Marketing", value: "vp-marketing" },
];
const CustomAutocomplete = styled(Autocomplete)({
  [`& .${autocompleteClasses.inputRoot}`]: {
    color: 'inherit',
    fontSize: 'inherit', // Use the font size from the parent
    padding: '0px !important', // Reset padding
    '&[class*="MuiOutlinedInput-root"]': {
      padding: '0px !important',
    },
    [`& .${autocompleteClasses.input}`]: {
      minWidth: '0', // Make input only as wide as its content
      padding: '8px 0px', // Vertical padding to align with other text, adjust as needed
      '&::placeholder': {
        fontSize: '1rem', // Ensures placeholder text matches size
      },
    },
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    top: 'calc(50% - 12px)', // Vertically center the dropdown icon
    right: '0px', // Align the icon to the right
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      textDecoration: 'underline', // Underline the text
      padding: '8px 0 !important', // Align text vertically
      // width: 'auto !important', // Allow the text to be as wide as needed
    },
    '& fieldset': {
      border: 'none', // Remove border around the TextField
    },
    '&:hover fieldset': {
      border: 'none', // Remove border on hover
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Remove border on focus
    },
  },
});

const TextWidthMeasure = styled.div`
  visibility: hidden;
  white-space: nowrap;
  position: absolute;
  pointer-events: none;
`;

const StyledLink = styled(Link)(() => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "inherit",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  "&:hover": {
    textDecoration: "underline",
  },
  // Apply any additional styles if needed
}));

const SelectionButton = ({ fn, opts, defaultValue, fieldKey, operator, cb, isMultiSelect }) => {
  const [inputWidth, setInputWidth] = useState(null); // State to hold the input width
  const [value, setValue] = useState(isMultiSelect ? [defaultValue] : defaultValue); // State to hold the current value
  const textWidthMeasureRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(opts);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  let inputRef = useRef(null);
  // This useEffect will calculate the width of the autocomplete based on its value's label
  useEffect(() => {

    // Function to calculate the width of the text
    const calculateTextWidth = (font = '26px Roboto') => {
      // Create a span element to measure the text width in the DOM
      if (textWidthMeasureRef.current) {
        const width = textWidthMeasureRef.current.offsetWidth;
        return width;
      }
      return 60;
    };

    // Calculate the width of the selected option label
    let labelWidth = 0;
    if (value && value.label) {
      labelWidth = calculateTextWidth(value.label)
    } else if (inputValue) {
      labelWidth = 125;
    }
    // Add some extra width for padding and the dropdown arrow
    const totalWidth = labelWidth + 45; // Adjust 30 if more space is needed
    // console.log(JSON.stringify(defaultValue) + ' ' + totalWidth);
    setInputWidth(totalWidth);
  }, [value, loading, inputValue]);

  // Custom render function for the input component
  const renderInput = params => (
    <StyledTextField
      variant="standard"
      ref={input => {
        inputRef = input;
      }}
      sx={{ 
        '&:after': {
          borderBottom: 'none',
        }
       }}
      {...params}
      InputProps={{
        ...params.InputProps,
        style: {
          ...params.InputProps.style,
          width: isMultiSelect ? 'fit-content' : inputWidth + 'px', // Apply the calculated width
        },
        endAdornment: (
          <>
            {params.InputProps.endAdornment}
            <ArrowDropDownIcon  onClick={(e) => {
              inputRef.focus();
              setOpen(true);
            }}/>
          </>
        ),
      }}
    />
  );

  const renderInputRO = (params) => (
    <StyledTextField
      variant="standard"
      inputRef={input => {
        inputRef = input;
      }}
      sx={{ 
        '&:after': {
          borderBottom: 'none',
        }
       }}
      {...params}
      InputProps={{
        ...params.InputProps,
        readOnly: true, // Prevent typing by making the input read-only
        style: {
          ...params.InputProps.style,
          width: inputWidth + 'px', // Apply the calculated width
        },
        endAdornment: (
          <>
            {params.InputProps.endAdornment}
            <ArrowDropDownIcon  onClick={(e) => {
              inputRef.focus();
              setOpen(true);
            }}/>
          </>
        ),
      }}
    />
  );

  useEffect(() => {
    if (!fn) {
      return;
    }
    setLoading(true);
    let active = true;
    (async () => {
      const fetchedOptions = await fn(inputValue);
      if (active) {
        setLoading(false);
        setOptions(fetchedOptions);
      }
    })();

    return () => {
      active = false;
    };
  }, [fn, inputValue]);

  useEffect(() => {
    if (!open && !fn) {
      setOptions([]);
    } else {
      setOptions(opts);
    }
  }, [fn, open, opts]);
  const isGrouped = options.some(option => option.options);

  if (!fn) {
    return (
      <>
        <TextWidthMeasure ref={textWidthMeasureRef}>
          {value && value.label ? value.label : ""}
        </TextWidthMeasure>
        <CustomAutocomplete
          value={value}
          options={opts}
          autoSelect
          disableClearable={true}
          renderInput={renderInputRO}
          componentsProps={{ popper: { style: { width: 'fit-content' } } }}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          style={{ width: inputWidth ? `${inputWidth}px` : 'auto' }} // Use calculated width or 'auto'
          popupIcon={null} // Remove the default dropdown arrow
          sx={{
            '& .MuiChip-root': {
              backgroundColor: 'transparent',
              fontSize: '16px',
            },
            '& .MuiInput-root': {
              '&::after': {
                borderBottom: 'none',
              },
            },
            '& .MuiInputBase-input': {
              minWidth: '0px',
            },
            '& .MuiInput-input':{
              minWidth: '0px !important',
              padding: '0px !important',
            }
          }}
        />
      </>
    );
  }

  const MyChip = (props) => {
  
    return (
        <Chip
          onClick={(e) => {
            inputRef.focus();
            setOpen(true);
          }}
          sx={{
            maxWidth: '120px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          {...props}
        />
    );
  };

  return (
    <>
      <TextWidthMeasure ref={textWidthMeasureRef}>
        {value && value.label ? value.label : ""}
      </TextWidthMeasure>
      <CustomAutocomplete
        multiple={isMultiSelect ? true : false}
        disableClearable={true}
        value={value}
        open={open}
        sx={{
          '& .MuiChip-root': {
            backgroundColor: 'transparent',
            fontSize: '16px',
          },
          '& .MuiInput-root': {
            '&::after': {
              borderBottom: 'none',
            },
          },
          '& .MuiInputBase-input': {
            minWidth: '0px',
          },
          '& .MuiInput-input':{
            minWidth: open ? '30px !important' : '0px !important',
            padding: '0px !important',
          }
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}        
        options={isGrouped ? options.flatMap(group => group.options) : options} // Flattening for rendering individual options
        groupBy={isGrouped ? (option) => {
          // Find the group label that this option belongs to
          const group = options.find(g => g.options.includes(option));
          return group ? group.label : '';
        } : undefined}
        getOptionLabel={(option) => { return (option.label ? option.label : option.title) }}
        loading={loading}
        inputValue={inputValue}
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}        
        renderInput={renderInput}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <MyChip {...getTagProps({ index })} label={option.label ? option.label : option.title} />
          ))
        }
        onChange={(event, newValue) => {
          // if isMultiSelect Filter out the 'any' option from the selected values and has multiple options
          if(isMultiSelect && newValue.length > 1) {
            newValue = newValue.filter((opt) => opt.value !== 'any');
          } else if(isMultiSelect && newValue.length === 0) {
            newValue = [{ label: 'any', value: 'any' }];
          }
          setValue(newValue);
          cb(fieldKey, operator, newValue);
        }}
        style={{ width: isMultiSelect ? 'fit-content' : inputWidth ? `${inputWidth}px` : 'auto' }} // Use calculated width or 'auto'
        popupIcon={null} // Remove the default dropdown arrow
      />
    </>
  );
};

const AdLibComponent = (props) => {

  const [formFields, setFormFields] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [mapName, setMapName] = useState(props.mapName);
  const [showMoreOptions, setShowMoreOptions] = useState(true);
  const [industriesList, setIndustriesList] = useState([]);
  const [prodCatList, setProdCatList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let list = await fetchIndustries();
      // let formattedList = list.map(item => ({ label: item, value: item }));
      setIndustriesList(list);
    };
    fetchData();
  }, []); // Empty dependency array to run only on component mount

  useEffect(() => {
    const fetchData = async () => {
      let list = await fetchProductCat();
      // let formattedList = list.map(item => ({ label: item, value: item }));
      setProdCatList(list);
    };
    fetchData();
  }, []); // Empty dependency array to run only on component mount  

  const handleShowMoreClick = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const passDynamicColumns = useMemo(() => {
    return dynamicColumns.map((column) => column.value);
  }, [dynamicColumns]);

  const handleNameSave = ({
    value,
  }) => {
    setMapName(value);
  };

  const currentBZModels = useMemo(() => {
    return (BIZMODELS.map(item => ({ label: item, value: item })));
  }, []);

  const anyOption = [{
    label: 'any',
    value: 'any'
  }];

  const hqCountry = useMemo(() => {
    const uniqueCountries = Array.from(new Set(COUNTRIES.map(country => country.toUpperCase())));

    return (uniqueCountries.map(item => ({ label: item, value: item })));
  }, []);  

  const currentCompanyStage = useMemo(() => {
    // const macros = fetchMacrosByTaxonomy('', `Stage;Current`) || [];
    return LKS.map((m) => ({ label: m, value: m }));
  }, []); 

  const sortOptionsByInputMatchIndex = (inputValue, options) => {
    const inputValueLower = inputValue.toLowerCase();
    return options.sort((a, b) => {
      const aIndex = a.label.toLowerCase().indexOf(inputValueLower);
      const bIndex = b.label.toLowerCase().indexOf(inputValueLower);
      return aIndex - bIndex;
    });
  };

  const onOptionSelect = useCallback((fieldKey, operator, val) => {
    // if value is any, remove the filter
    if(val.value === 'any' || (val.constructor === Array  && val.some((opt) => opt.value === 'any'))){
      setFormFields(formFields.filter((field) => field.field !== fieldKey));
    } else {
      // if already exists, update the value else add a new filter
      const fieldIndex = formFields.findIndex((field) => field.field === fieldKey);
      if(fieldIndex !== -1) {
        formFields[fieldIndex].value = operator === 'isAnyOf' ? val.map((opt => opt.value)) : val.value;
        formFields[fieldIndex].operator = operator;
      } else {
        setFormFields([...formFields, {field: fieldKey, operator, value: operator === 'isAnyOf' ? val.map((opt => opt.value)) : val.value}]);
      }
    }
  }, [formFields]);


  const updateDynamicColumn = useCallback((fieldKey, operator, val) => {
    const fieldIndex = dynamicColumns.findIndex((field) => field.field === fieldKey);
    if(val.value === 'any') {
      setDynamicColumns(dynamicColumns.filter((field) => field.field !== fieldKey));
    } else if(fieldIndex !== -1) {
      dynamicColumns[fieldIndex].value = {
        name: val.label,
        values: [val.value],
      };
    } else {
      setDynamicColumns([...dynamicColumns, {field: fieldKey, value: {
        name: val.label,
        values: [val.value],
      }}]);
    }
  }, [dynamicColumns]);
    
  const fetchSize = useCallback(async (inputValue) => {
    if(inputValue === '') {
      return [
        { label: "any", value:"any" },
        ...SIZE
      ];
    }
    return [
      { label: inputValue, value: inputValue },
      ...SIZE.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    ];
  }, []);

  const bizModels = useCallback(async (inputValue) => {
    if(inputValue === '') {
      return [
        { label: "any", value:"any" },
        ...currentBZModels
      ];
    }
    return [
      { label: inputValue, value: inputValue },
      ...currentBZModels.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    ];
  }, [currentBZModels]);

  const hqLocs = useCallback(async (inputValue) => {
    if(inputValue === '') {
      return [
        { label: "any", value:"any" },
        ...hqCountry
      ];
    }
    return [
      { label: inputValue, value: inputValue },
      ...sortOptionsByInputMatchIndex(inputValue, hqCountry.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    ];
  }, [hqCountry]);

  const compStage = useCallback(async (inputValue) => {
    if(inputValue === '') {
      return [
        { label: "any", value:"any" },
        ...currentCompanyStage
      ];
    }
    return [
      { label: inputValue, value: inputValue },
      ...sortOptionsByInputMatchIndex(inputValue, currentCompanyStage.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    ];
  }, [currentCompanyStage]);

  const indList = useCallback(async (inputValue) => {
    const options = await fetchCompanyFields(inputValue, CompanySearchableFields.INDUSTRY_SUB_CATEGORIES);
    console.log('options',options);
    if(inputValue === '') {
      return [
        { label: "any", value:"any" },
        ...options
      ];
    }
    return [
      { label: inputValue, value: inputValue },
      ...sortOptionsByInputMatchIndex(inputValue,options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    ];
  }, []);

  const pCats = useCallback(async (inputValue) => {
    if(inputValue === '') {
      return [
        { label: "any", value:"any" },
        ...prodCatList
      ];
    }
    return [
      { label: inputValue, value: inputValue },
      ...sortOptionsByInputMatchIndex(inputValue, prodCatList.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    ];
  }, [prodCatList]);

  const fetchTitlesByOperator = useCallback(async (inputValue) => {
    return fetchTitles(inputValue, ';Current', false);
  },[]);

  return (<>
    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Breadcrumb-like navigation */}
        <Breadcrumbs
          aria-label="breadcrumb"
        >
          <StyledLink href={"/company_research"} underline="hover">
            <ArrowBackIcon
              fontSize="small"
              sx={{ verticalAlign: "middle", mr: 0.5 }}
            />
            {"Company Research"}
          </StyledLink>
          <Box
            sx={{
              display: "flex",
              '&:hover': {
                backgroundColor: colors['grey-100'],
                '.map-edit-icon': {
                  display: 'block !important',
                }
              }
            }}
          >
            <EditText
              style={{
                position: "relative",
                padding: '0 2px',
                margin: 0,
                lineHeight: "2em",
                borderRadius: "4px",
              }}
              name="textbox1"
              onSave={handleNameSave}
              defaultValue={mapName ? mapName : "New Mapping"}
              showEditButton={true}
              editButtonContent={<EditIcon className="map-edit-icon" sx={{
                fontSize: "14px",
                color: colors['grey-600'],
                cursor: "pointer",
                display: "none",
              }} />}
            />
          </Box>                  
        </Breadcrumbs>
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
          gap: 2,
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            color: "grey",
            fontSize: "small",
            fontWeight: "normal",
          }}
          onClick={()=>{props.setAdLibPopoverOpen(false); props.handleClickOpen(null, formFields, passDynamicColumns);}} // You need to define this function to handle click events
        >
          Advanced View
        </Button>
        <IconButton
          aria-label="close"
          onClick={() => { props.setAdLibPopoverOpen(false) }}
          sx={{
            position: 'relative',
            right: 8,
            top: 0,
            color: colors['grey-600'],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>                              
      </Box>         
    </DialogTitle>

    <DialogContent sx={{
      border: `24px solid ${colors['blue-100']}`,
    }}>
      <CompanyInfoSection>
        <CompanyInfoContainer>
          <CompanyInfoRow>
            <CompanyInfoText>I am researching companies with a business model of </CompanyInfoText>
            <SelectionButton fn={bizModels} isMultiSelect={true} opts={[...anyOption,...currentBZModels]} defaultValue={anyOption[0]} operator="isAnyOf" fieldKey="biz_models" cb={onOptionSelect} />
            <CompanyInfoText>headquartered in</CompanyInfoText>
            <SelectionButton fn={hqLocs} isMultiSelect={true} opts={[...anyOption,...hqCountry]} defaultValue={anyOption[0]} operator="isAnyOf" fieldKey="hq_location.country" cb={onOptionSelect} />
            <CompanyInfoText>and at the</CompanyInfoText>
            <SelectionButton fn={compStage} isMultiSelect={true} opts={[...anyOption,...currentCompanyStage]} defaultValue={anyOption[0]} fieldKey="last_known_state" operator="isAnyOf" cb={onOptionSelect} />
            <CompanyInfoText>stage in the</CompanyInfoText>
            <SelectionButton fn={indList} isMultiSelect={true} opts={[...anyOption]}  defaultValue={anyOption[0]} fieldKey="industry.sub_categories" operator="isAnyOf" cb={onOptionSelect} />
            <CompanyInfoText>industry. These companies have a size of </CompanyInfoText>
            <SelectionButton fn={fetchSize} opts={SIZE} defaultValue={SIZE[0]} fieldKey="comp_size" operator="greaterThan"  cb={onOptionSelect}/>
            <CompanyInfoText>and are developing products in the categories of </CompanyInfoText>
            <SelectionButton fn={pCats} isMultiSelect={true} opts={[...anyOption,...prodCatList]}  defaultValue={anyOption[0]} cb={onOptionSelect} fieldKey="products.product_categories" operator="isAnyOf"/>
          </CompanyInfoRow>
          <Divider />
          {/* Conditional rendering for more options */}
          {showMoreOptions ? 
            <>
              <CompanyInfoRow>
                <CompanyInfoText>I am particularly interested in companies that have at least</CompanyInfoText>
                <SelectionButton opts={ROLECOUNT} defaultValue={ROLECOUNT[0]} />
                <SelectionButton fn={fetchTitlesByOperator} cb={updateDynamicColumn} fieldKey="job_title" operator='any' opts={anyOption} defaultValue={anyOption[0]} />
              </CompanyInfoRow>
            </> :
            <CompanyInfoText
              as="button"
              onClick={() => handleShowMoreClick(!showMoreOptions)}
              style={{
                color: 'grey',
                cursor: 'pointer',
                background: 'none',
                border: 'none',
                padding: '10px 0', // add some padding for better clickability
                textAlign: 'center',
                textDecoration: 'underline',
                width: '100%', // ensure it spans the full width
              }}
            >
              {showMoreOptions ? '' : 'More Options'}
            </CompanyInfoText>
          }
        </CompanyInfoContainer>
      </CompanyInfoSection>
      <ActionButtonsContainer>
        <ActionButton 
          variant='text' 
          onClick={()=>{props.setAdLibPopoverOpen(false); props.handleClickOpen(null, formFields, passDynamicColumns);}}
          sx={{color: colors['grey-600'], fontSize: '16px'}}
        >
          Browse companies
          <ArrowPillWrapper>
            <EastIcon />
          </ArrowPillWrapper>
        </ActionButton>
      </ActionButtonsContainer>
    </DialogContent>
  </>);
}

const CompanyInfoSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  color: #453e39;
  padding: 64px 60px 32px;
  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;
const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This centers the content vertically */
  max-width: 834px;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const CompanyInfoRow = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap as needed */
  justify-content: center;
  align-items: center;
  gap: 8px; /* Consistent gap between items */
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width */
  padding: 0 16px; /* Add padding to prevent content from touching the edges */

  @media (max-width: 991px) {
    padding: 0 8px; /* Reduce padding on smaller screens */
  }

  > * {
    /* Prevent child elements from growing too large */
    max-width: 100%;
    box-sizing: border-box;
  }
`;
const CompanyInfoText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%; /* Ensure it doesn't grow larger than its container */
`;
const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 250px;
  gap: 16px;
  flex-wrap: nowrap; /* Prevent button wrapping */
  margin: auto;
  margin-top: 36px;
`;
const ActionButton = styled(Button)`
  flex: 1;
  padding: 12px 24px;
  font-size: 24px;
  text-transform: 'none'
  font-weight: 500;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: ${colors['white']} !important;
  }
`;

const ArrowPillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 10px 18px;
  margin: 0 8px;
  cursor: pointer;
  border: 1px solid ${colors['grey-600']};
  &:hover {
    background: ${colors['blue-100']};
    border: 1px solid ${colors['blue-400']};
    color: ${colors['blue-400']};
  }
`;

export default AdLibComponent;
