import { IEmbedDisplayOptions, IEmbedMode, IEmbedModeType } from "../types/embed";

export function parseEmbedProps(type: IEmbedModeType, embedProps?: string, displayOptionsString?: string): IEmbedMode {

  const displayOptions: IEmbedDisplayOptions | undefined = displayOptionsString ? parseDisplayOptions(displayOptionsString) : undefined;
  switch (type) {
    case IEmbedModeType.App:
      return { type: IEmbedModeType.App };

    case IEmbedModeType.CompanyApp:
      return { type: IEmbedModeType.CompanyApp, ...displayOptions };
    
    case IEmbedModeType.MapsApp:
      return { type: IEmbedModeType.MapsApp, ...displayOptions };
  }
}

export function parseDisplayOptions(displayOptionsString: string): IEmbedDisplayOptions {
  return JSON.parse(displayOptionsString);
}
