
// Copyright 2024 and onwards, All rights reserved.
// Author: Hariharan Kolam

import React, { useState, useEffect, useCallback, useLayoutEffect, useMemo } from 'react';
import { Button, Menu, MenuItem, Popover, Typography, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, CircularProgress, Autocomplete, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { createTheme, darken, lighten, ThemeProvider } from '@mui/material/styles';
import { createCompanyList, fetchCompanyLists, updateCompanyList, fetchSimilarCompanies, fetchCompanies, createCompanyCompMacros } from '../../Apis'; // Placeholder API functions
import { default as CreateCompanyList } from '../../CreateCompanyList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the dropdown icon
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { EditText } from 'react-edit-text'
import { generateTimestampedName, displayTimeAgo } from '../../utils';
import { APP_THEME } from '../../common/constants/themes/AppTheme';
import { ICompanyDetails, ICompanyDetailsRow, ICompanyListRow, ICompanyListType, RegencyType } from '../../common/types/companyListType';
import { companyListApis } from '../../common/services/apis';
import UserContext from '../../common/contexts/UserContext';
import UserSelect from '../common/UserSelect/UserSelect';
import { constructUrlWithParams, useUrlParams } from 'findem-helpers';
import { COMPANY_SIDEBAR_ITEM } from '../../utils/navigationUtils';
import { getCompLinkedInId } from '../../common/utils/getCompanyLinkedInId';
import { useQueryClient } from '@tanstack/react-query';
import ListIcon from '@mui/icons-material/List';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DoneIcon from '@mui/icons-material/Done';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CompaniesGroup from './CompaniesGroup';
import CompanyProfileDetails from '../../components/common/CompanyDetails/CompanyDetails';
import { default as StarterWidget } from './../../AdLibCompanyResearch';
import {styled as styledComponent} from "styled-components";
import { useRef } from "react";
import { appNextUrl } from '../../url-config';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import TuneIcon from '@mui/icons-material/Tune';
import Divider from '@mui/material/Divider';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { colors } from '../../common/constants';
import { CheckBox } from '@mui/icons-material';
const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  // Apply any additional styles if needed
}));

interface Company {
  meta_ref: string;
  company_name: string;
  domain?: string;  // Assuming domain might not always be provided
}

interface Option {
  label: string;
  value: string;
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const appTheme = createTheme(APP_THEME);

interface ICompanyResearchProps {
  isAppNext?: boolean;
} 

const CompanyResearch = (props:ICompanyResearchProps) => {
  const { isAppNext } = props;

  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const [companyLists, setCompanyLists] = useState<ICompanyListType[]>([]);
  const [listData, setListData] = useState<ICompanyListRow[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState<any>([]);
  const [selectedCompanies, setSelectedCompanies] = React.useState<ICompanyDetailsRow[]>([]);
  const [name, setName] = React.useState(generateTimestampedName("NewList_"));
  const [openRef, setOpenRef] = React.useState<string | null>(null);
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tableKey, setTableKey] = useState(0); // Initialize with 0 or any value
  const [selectedList, setSelectedList] = useState<ICompanyListType | undefined>(undefined);
  const [selectedUser, setSelectedUser] = React.useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [options, setOptions] = React.useState([]);
  const [newListName, setNewListName] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [show, setShow] = React.useState(false);
  const [companyId, setCompanyId] = React.useState(null);
  const [ adLibPopoverOpen, setAdLibPopoverOpen ] = React.useState(false);
  const [passFormFields, setPassFormFields] = React.useState<any>([]);
  const [passDynamicColumns, setPassDynamicColumns] = React.useState<any>([]);
  const [orgId, setOrgId] = React.useState<string | undefined>(undefined);
  const [createCompMacroList, setCreateCompMacroList] = React.useState<ICompanyListType | undefined>(undefined);
  const [createCompMacroListName, setCreateCompMacroListName] = React.useState('');
  const [regencyCheckboxes, setRegencyCheckboxes] = React.useState<any>([
    RegencyType.current,
    RegencyType.recent,
    RegencyType.past,
    RegencyType.past_and_current
  ]);

  const queryClient = useQueryClient();
  const { user } = React.useContext(UserContext);

  const navigate = useNavigate();

  const { joid, ref } = useUrlParams();


  const handleAdLibPopoverClose = () => {
    setAdLibPopoverOpen(false);
  };

  // ----------------------------------------------------
  // Apis
  // ----------------------------------------------------
  const {
    data: companyListData,
    isFetching: companyListDataFetching,
    isLoading: companyListDataLoading,
    refetch: refetchCompanyList
  } = companyListApis.getAllCompanyLists.useQuery({ variables: { id: selectedUser || user.uid }});

  const {
    data: selectedCompaniesData,
    isFetching: selectedCompaniesFetching,
    isLoading: selectedCompaniesLoading,
    refetch: refetchSelectedCompanies
  } = companyListApis.getCompaniesById.useQuery({
    variables: { refs: selectedList?.companies || [] },
    enabled: selectedList !== undefined && selectedList.companies.length > 0
  });

  useEffect(() => {
    if (companyListData && companyListData.data) {
      const data: ICompanyListRow[] = companyListData.data.map((m: ICompanyListType) => ({
        id: m.ref,
        ref: m.ref,
        name: m.name,
        date: m.created_at,
        companies: m.companies,
      }));
      setListData(data);
      setCompanyLists(companyListData.data);
    }
  }, [companyListData]);

  useEffect(() => {
    if (ref && selectedCompaniesData && selectedCompaniesData.data) {
      if (selectedCompaniesData.data.data.length > 0) {
        const rows: ICompanyDetailsRow[] = selectedCompaniesData.data.data.map((it: ICompanyDetails) => {
          return {
            id: it.meta_ref,
            company_name: { url: it.domain, name: it.company_name },
            description: it.description,
            last_known_state: it.last_known_state,
            'industry.crunchbase_category_groups': it.industry?.crunchbase_category_groups,
            'industry.sub_categories': it.industry?.sub_categories,
            'curr_financial_info.est_revenue': it.curr_financial_info?.est_revenue,
            comp_size: it?.comp_size,
            org_type: it?.org_type,
            website: it?.website,
            biz_models: it?.biz_models,
            "hq_location.state": it?.hq_location?.state,
            "hq_location.country": it?.hq_location?.country,
            "pb_data.ownership_status": it?.pb_data?.ownership_status,
            "funding": it?.funding,
            linkedIn_id: getCompLinkedInId(it),
          };
        });
        setSelectedCompanies(rows);
      } else {
        setSelectedCompanies([]);
      }
      setOpenDialog(true);
    } else if(ref){
      setSelectedCompanies([]);
      setOpenDialog(true);
    }
  }, [selectedCompaniesData, ref]);

  // Dropdown button handlers
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownOptionClick = (option: string) => {
    if (option === 'research') {
      // Logic for Research & Add Companies
      console.log('Research & Add Companies option selected');
      // Perform actions or navigation as required
    } else if (option === 'load') {
      // Open popover logic
      setPopoverAnchorEl(anchorEl);
      setPopoverOpen(true);
    }
    handleDropdownClose();
  };

  const handlePopoverClose = () => {
    setAdLibPopoverOpen(false);
  };

  const handleQuickListClose = () => {
    setPopoverOpen(false);
  };

  const handleCloseDialog = React.useCallback(() => {
    setOpenDialog(false);
    setSelectedCompanies([]);
    setOpenRef(null);
    setSelectedList(undefined);
    if (joid) {
      navigate(constructUrlWithParams(COMPANY_SIDEBAR_ITEM.path, { joid: joid }));
    } else {
      navigate(COMPANY_SIDEBAR_ITEM.path);
    }
  }, [joid, navigate]);

  const passFilterModel = useMemo(() => {
    if(passFormFields && passFormFields.length > 0){
      return {items: passFormFields};
    }
    return undefined;
  }, [passFormFields]);

  const handleClickOpen = useCallback((ref?: string, passFormFields?: any, dynamicColumns?:any) => {
    if(passFormFields && passFormFields.length > 0){
      setPassFormFields(passFormFields);
    }
    if(dynamicColumns && dynamicColumns.length > 0){
      setPassDynamicColumns(dynamicColumns);
    }
    if (!ref) {
      setOpenDialog(true);
      setOpenRef(null);
      setSelectedList(undefined);
      setName(generateTimestampedName("NewList_"));
    } else {
      const list = companyLists.find(l => l.ref === ref);
      if (list) {
        setOpenRef(ref);
        setSelectedList(list);
        setName(list.name);
        // setOpenDialog(true);
        const params: Record<string, string> = { ref: ref };
        if (joid) {
          params['joid'] = joid;
        }
        navigate(constructUrlWithParams(COMPANY_SIDEBAR_ITEM.path, params))
      } else {
        setError('Error: List not found.');
      }
    }
  }, [companyLists, joid, navigate]);

  const openExistingList = useCallback((ref: string) => {
    handleClickOpen(ref);
  }, [handleClickOpen]);

  const handleCreateCompListMacro = useCallback((ref: string) => {
    const list = companyLists?.find(l => l.ref === ref);
    if(list){
      setCreateCompMacroList(list);
      setCreateCompMacroListName(list.name);
    }else{
      setCreateCompMacroList(undefined);
    }
  }, [companyLists]);

  const handleCreateCompListMacroClose = useCallback(() => {
    setCreateCompMacroList(undefined);
    setCreateCompMacroListName('');
    setRegencyCheckboxes([
      RegencyType.current,
      RegencyType.recent,
      RegencyType.past,
      RegencyType.past_and_current
    ]);
  }, []);

  const handleCreateCompListMacroSave = useCallback(async () => {
    if(regencyCheckboxes.length === 0){
      setShowNotification(true);
      setSnackMessage("Please select at least one regency type");
      return;
    }
    if(!createCompMacroList){
      setShowNotification(true);
      setSnackMessage("Error: Company List not found");
      return;
    }
    createCompanyCompMacros(
      createCompMacroList.companies,
      joid,
      joid,
      createCompMacroListName,
      createCompMacroListName,
      regencyCheckboxes
    ).then((res) => {
      setShowNotification(true);
      setSnackMessage("Company List created successfully");
      handleCreateCompListMacroClose();
    }).catch((err) => {
      setShowNotification(true);
      setSnackMessage("Error: Company List not created");
    });
  },[regencyCheckboxes, createCompMacroList, joid, createCompMacroListName, handleCreateCompListMacroClose]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "List Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: string, meta: { rowData: any[]; }) => {
          return <Button sx={{ 
            fontSize: '14px',
            textTransform: 'none',
            color: colors['grey-700'],
            '&:hover': {
              background: 'none',
              color: colors['blue-400'],
            },
            paddingLeft: '0px',
          }} disableRipple variant='text' size="small" onClick={() => { openExistingList(meta.rowData[0]) }}>{value}</Button>
        }
      },
    },
    {
      name: 'date',
      label: 'Date Created',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <>{displayTimeAgo(value)} </>
        }
      },
    },
    {
      name: 'companies',
      label: 'Shortlisted Companies',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, meta: { rowData: any[]; }) => {
          return <div style={{textAlign: isAppNext ? 'left' : 'right'}} onClick={() => { openExistingList(meta.rowData[0]) }}>
            {value && value.length > 0 && <CompaniesGroup compIds={value} isAppNext={isAppNext} />}
            {(!value || value.length === 0) && <div>No companies shortlisted</div>}
          </div>
        }
      }
    }
    // Additional columns as needed
  ];

  if(isAppNext){
    columns.push({
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, meta: { rowData: any[]; }) => {
          return <div style={{textAlign: 'right'}}><Button
            variant="contained"
            color="primary"
            onClick={() => { handleCreateCompListMacro(meta.rowData[0]) }}
          >
            Build Custom Lists for Projects
          </Button></div>
        }
      }
    });
  }

  const options2 = [
    { value: 'list1', label: 'List 1' },
    { value: 'list2', label: 'List 2' },
    { value: 'list3', label: 'List 3' }
  ];

  const [selectedOption, setSelectedOption] = React.useState(null);
  const [addedCompanies, setAddedCompanies] = React.useState([]);
  const [removedCompanies, setRemovedCompanies] = React.useState([]);
  const [selectedValue, setSelectedValue] = useState<Option[]>([]); // Ensures TypeScript knows the type of the array
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;
  const [simComps, setSimComps] = React.useState([]);

  const handleChange = (option: any) => {
    setSelectedOption(option); // macroid
    // run matches_analyze and get top 100 companies
    // added company is incremental and removed company is decremental (so companylistid OR added AND !removed)
    // create a company list with the above and close
  };
  React.useEffect(() => {
    let active = true;

    async function fetchOptions() {
      if (!inputValue) {
        setOptions([]);
        return;
      }
      let data = await fetchCompanies(inputValue);
      if (active) {
        setOptions(data || []);
      }
    }

    fetchOptions();
    return () => {
      active = false;
    };
  }, [inputValue]);

  React.useEffect(() => {
    async function fetchSimComps() {
      let ids: string[] = [];
      selectedValue.forEach((v: any) => {
        ids.push(v.value);
      })
      let simcomps = await fetchSimilarCompanies(ids);
      simcomps = simcomps ? simcomps : [];
      let sortedSimComps = simcomps.sort((a: any, b: any) => b.score - a.score);
      const filteredSimComps:any = sortedSimComps.filter((comp:any) => !ids.includes(comp.meta_ref));
      setSimComps(filteredSimComps);
    }
    fetchSimComps();
  }, [selectedValue]);

  const saveMapNew = () => {
    let ids: string[] = [];
    selectedValue.forEach((o: any) => {
      ids.push(o.value);
    })
    saveInternal('', newListName, ids, 'shortlisted');
    setNewListName('');
    setOptions([]);
    setOpen(false);
    setSimComps([]);
    setPopoverOpen(false);
  }

  const onSelectCompany = (c: Company) => {
    const newSelectedCompany: Option = {
      label: c.company_name,
      value: c.meta_ref
    };

    // Update the state to include the new selected company
    setSelectedValue((prevSelected: Option[]) => [...prevSelected, newSelectedCompany]);
  };
  const muioptions: MUIDataTableOptions = {
    filterType: 'checkbox',
    print: false,
    pagination: false,
    download: false,
    filter: false,
    viewColumns: false,
    elevation: 0,
    sortOrder:{
      name: 'date',
      direction: 'desc'
    },
    textLabels: {
      body: {
        noMatch: (companyListDataLoading || companyListDataFetching) ? <CircularProgress /> : "No company list found. Click the 'Create new list' button to get started.",
      },
    },
    onRowsDelete: (rowsDeleted: any) => {
      setDialogOpen(true);
      setRowsToDelete(rowsDeleted);
      return false; // Tell MUIDataTable to not delete the rows yet
    },
    customToolbar: () => {
      return (
        <>
          <UserSelect
            value={selectedUser}
            onChangeCB={handleUserSelect}
          />
          <Button
            aria-controls="dropdown-menu"
            aria-haspopup="true"
            onClick={handleDropdownClick}
            variant="contained"
            endIcon={<ArrowDropDownIcon />} // Add an icon at the end of the button
            sx={{
              textTransform: 'none',
              background: '#4599fa',
              borderColor: '#4599fa',
              padding: '6px 12px',
              fontSize: '14px',
              lineHeight: '140%',
              fontFamily: 'Roboto, sans-serif',
            }}
          >
            + Create new list
          </Button>
          <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleDropdownClose}
          >
            <MenuItem onClick={() => {setAdLibPopoverOpen(true);}}><EditNoteIcon sx={{ mr: 1 }} />Simple Research</MenuItem>
            <MenuItem onClick={() => handleClickOpen()}><TuneIcon sx={{ mr: 1 }} />Advanced Research</MenuItem>
            <Divider />
            <MenuItem onClick={() => handleDropdownOptionClick('load')}><ElectricBoltIcon sx={{ mr: 1 }} />Quick List</MenuItem>
          </Menu>
          {adLibPopoverOpen && <Dialog
            open={adLibPopoverOpen}
            onClose={handlePopoverClose}
            aria-labelledby="dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
            <StarterWidget 
              mapName={name}
              setAdLibPopoverOpen={setAdLibPopoverOpen}
              handleClickOpen={handleClickOpen}
              saveCb={()=>{alert("save");}}
            />
          </Dialog> }

          {createCompMacroList && <Dialog
            open={createCompMacroList ? true : false}
            onClose={handleCreateCompListMacroClose}
            aria-labelledby="dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
            <DialogTitle id="dialog-title">
              <Typography sx={{fontSize:24, fontColor:'black'}}>
                Create Custom Lists
              </Typography>
              <Typography sx={{fontSize:12, fontColor: colors['grey-400']}}>
                Turn your Company Research List into a seat of custom company lists that can be used in your Projects in the Search Filters sidebar.
              </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <TextField
                sx={{ mb: 3 }}
                label="Name"
                value={createCompMacroListName}
                onChange={(e) => { setCreateCompMacroListName(e.target.value) }}
                variant="outlined"
                fullWidth
              />
              {/* checkboxes for picking current | past | past or current */}
              <Typography variant="h6" sx={{fontSize: 16, fontWeight: 500, color: colors['grey-700']}}>
                Build Custom Company Lists:
              </Typography>
              <FormGroup sx={{
                flexDirection: 'row',
              }}>
                <FormControlLabel  control={
                  <Checkbox onChange={
                    (event) => {
                      if(event.target.checked){
                        setRegencyCheckboxes([...regencyCheckboxes, RegencyType.current]);
                      }else{
                        setRegencyCheckboxes(regencyCheckboxes.filter((c: any) => c !== RegencyType.current));
                      }
                    }
                  } 
                  name="current" 
                  checked={regencyCheckboxes.includes(RegencyType.current) ? true : false}
                />} label="Current" />
                <FormControlLabel  control={
                  <Checkbox onChange={
                    (event) => {
                      if(event.target.checked){
                        setRegencyCheckboxes([...regencyCheckboxes, RegencyType.recent]);
                      }else{
                        setRegencyCheckboxes(regencyCheckboxes.filter((c: any) => c !== RegencyType.recent));
                      }
                    }
                  } 
                  name="recent" 
                  checked={regencyCheckboxes.includes(RegencyType.recent) ? true : false}
                />} label="Recent" />
                <FormControlLabel  control={
                    <Checkbox  
                      onChange={
                        (event) => {
                          if(event.target.checked){
                            setRegencyCheckboxes([...regencyCheckboxes, RegencyType.past]);
                          }else{
                            setRegencyCheckboxes(regencyCheckboxes.filter((c: any) => c !== RegencyType.past));
                          }
                        }
                      } 
                      name="past" 
                      checked={regencyCheckboxes.includes(RegencyType.past)}
                    />
                  } label="Past" 
                /> 
                <FormControlLabel  control={
                  <Checkbox 
                    onChange={
                      (event) => {
                        if(event.target.checked){
                          setRegencyCheckboxes([...regencyCheckboxes, RegencyType.past_and_current]);
                        }else{
                          setRegencyCheckboxes(regencyCheckboxes.filter((c: any) => c !== RegencyType.past_and_current));
                        }
                      }
                    } 
                    name="past_or_current" 
                    checked={regencyCheckboxes.includes(RegencyType.past_and_current)}
                  />
                  } 
                  label="Past or Current" 
                /> 
              </FormGroup>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-end', padding: '8px 24px' }}>
              <Button
                variant={(!createCompMacroListName || regencyCheckboxes.length === 0) ? 'outlined' : "contained"}
                color="primary"
                onClick={handleCreateCompListMacroSave}
                disabled={!createCompMacroListName || regencyCheckboxes.length === 0} // Ensure both conditions are met
              >
                Create Custom Lists
              </Button>
            </DialogActions>
          </Dialog>}              

          <Dialog
            open={popoverOpen}
            onClose={handleQuickListClose}
            aria-labelledby="dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
            <DialogTitle id="dialog-title">
              <Typography sx={{fontSize:24, fontColor:'black'}}>
                Create New List
              </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <TextField
                sx={{ mb: 3 }}
                label="Name"
                value={newListName}
                onChange={(e) => { setNewListName(e.target.value) }}
                variant="outlined"
                fullWidth
              />
              <Autocomplete
                // options={fetchCurrentCompanyAttrList()}
                multiple
                freeSolo
                open={open}
                onOpen={() => {
                  console.log("onOpen");
                  setOpen(true);
                }}
                onClose={() => {
                  console.log("onClose");
                  setOpen(false);
                }}
                onChange={(event, value: any) => {
                  setSelectedValue(value);
                }}
                loading={loading}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={options.flatMap((group: any) => { return group.options })} // Flattening for rendering individual options
                groupBy={(option: any) => {
                  // Find the group label that this option belongs to
                  const group: any = options.find((g: any) => { return (g.options.includes(option)) });
                  return group ? group.label : '';
                }}
                getOptionLabel={(option) => { return (option.label ? option.label : option.title) }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Companies"
                    variant="outlined"
                    fullWidth
                  />
                )}
                value={selectedValue}
                renderGroup={(params: any) => (
                  <li key={`${params.group}-${params.key}`}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
              />
              <Stack direction="row" spacing={1} sx={{ overflowX: 'auto', maxWidth: '100%', padding: '10px 0' }}>
                {simComps.map((c: any, idx: number) => (
                  <Chip
                    key={"simcomps-" + idx}
                    label={c.company_name}
                    onDelete={() => { onSelectCompany(c) }}
                    deleteIcon={<DoneIcon />}
                    sx={{ margin: '5px' }} // Adjust margins for visual consistency
                  />
                ))}
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'flex-end', padding: '8px 24px' }}>
              <Button
                color="primary"
                variant='contained'
                onClick={(deps: any) => { saveMapNew() }}
                disabled={!newListName || !selectedValue || selectedValue.length === 0} // Ensure both conditions are met
              >
                Create & Shortlist Companies
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
  const saveInternal = React.useCallback((ref: string | undefined | null, name: string = '', companies: string[], tab?: string) => {
    console.log("saveInternal " + ref);
    if (!ref) {
      // create
      if (!name) {
        name = generateTimestampedName("NewList_");
      }
      createCompanyList(name, companies, joid).then(async (list) => {
        const lists = await fetchCompanyLists(joid);
        const data: any = lists.map((m: any) => ({
          id: m.value,
          ref: m.value,
          name: m.title,
          date: m.created_at,
          companies: m.companies,
        }));
        setListData(data);
        setCompanyLists(data)
        setShowNotification(true);
        setSnackMessage("List created successfully.");
        // let new_hash='#company-research?action=open_existing_list&ref=' + list.ref;
        // navigate(new_hash);
        const params: Record<string, string> = { ref: list.ref };
        if (joid) {
          params['joid'] = joid;
        }
        if(tab){
          params['tab'] = tab;
        }
        setIsProcessing(false);
        navigate(constructUrlWithParams(COMPANY_SIDEBAR_ITEM.path, params));
        queryClient.invalidateQueries({ queryKey: companyListApis.getAllCompanyLists.getKey() });
      }).catch(error => {
        console.error("Error saving list:", error);
      });
    } else {
      updateCompanyList(ref, name, companies, joid).then(async (list) => {
        const lists = await fetchCompanyLists(joid);
        const data = lists.map((m: any) => ({
          id: m.value,
          ref: m.value,
          name: m.title,
          date: m.created_at,
          companies: m.companies,
        }));
        setListData(data);
        setCompanyLists(data);
        setShowNotification(true);
        setSnackMessage("List updated successfully.");
        setIsProcessing(false);
        // queryClient.invalidateQueries({ queryKey: companyListApis.getAllCompanyLists.getKey() });
      }).catch(error => {
        console.error("Error updating list:", error);
      });
    }
  }, [companyLists, joid, navigate, queryClient, listData]);

  useEffect(() => {
    async function loadCompanyLists() {
      try {
        const lists = await fetchCompanyLists(joid);
        const data = lists.map((m: any) => ({
          id: m.value,
          ref: m.value,
          name: m.title,
          date: m.created_at,
          companies: m.companies,
        }));
        setListData(data);
        setCompanyLists(data);
      } catch (error) {
        console.error("Failed to fetch company lists:", error);
      } finally {

      }
    }
    loadCompanyLists();
  }, [joid]);

  const handleOrgChartClick = React.useCallback((companyId: string) => {
    setOrgId(companyId);
  }, []);

  const handleSelectCompany = React.useCallback((company: ICompanyDetailsRow, ref: string) => {
    console.log("handleSelectCompany " + ref);
    setIsProcessing(true);
    // if its a findem attr based comaplly list - then add it baseid, added, removed
    // if not - the normal flow
    // parse teh macro - the size is limited...
    ;
    let dup = false;
    selectedCompanies.forEach((c) => {
      if (c.id === company.id) {
        dup = true;
      }
    });
    if (!dup) {
      // update list
      let ids = [];
      selectedCompanies.forEach((c) => {
        ids.push(c.id);
      });
      ids.push(company.id);
      let name = '';
      if (selectedList) {
        name = selectedList.name;
      }
      saveInternal(ref, name, ids);
      setSelectedCompanies((prevCompanies) => [...prevCompanies, company]);
    }
  }, [saveInternal, selectedCompanies, selectedList]);

  const handleRemoveCompany = React.useCallback((companyId: string, ref: string) => {
    let ids: string[] = [];
    selectedCompanies.forEach((c) => {
      if (c.id == companyId) {
        return;
      }
      ids.push(c.id);
    });
    saveInternal(ref, name, ids);
    setSelectedCompanies((prevCompanies) => prevCompanies.filter(c => c.id !== companyId));
  }, [name, saveInternal, selectedCompanies]);

  const handleSave = React.useCallback(({ name, value, previousValue }: { name: string, value: string, previousValue: string }) => {
    // update list
    let refs: string[] = [];
    setName(value);
    selectedCompanies.forEach((c) => { refs.push(c.id); });
    saveInternal(openRef, value, refs);
  }, [openRef, saveInternal, selectedCompanies]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  const handleDeleteConfirm = React.useCallback(() => {
    // Assuming mapData contains an id for each map to delete
    // const idsToDelete = rowsToDelete.data.map(rowIndex => mapData[rowIndex].id);
    let idsToDelete = rowsToDelete.data.map((d: any) => d.dataIndex);

    let ids: string[] = [];
    idsToDelete.forEach((i: number) => {
      ids.push(listData[i].id);
    })
    let new_listData: ICompanyListRow[] = [];
    listData.forEach((d, i) => {
      if (!ids.includes(d.id)) {
        new_listData.push(d);
      }
    });
    try {
      companyListApis.delete.mutationFn({ ids: ids }).then((data) => {
        setDialogOpen(false);
        setListData(new_listData);
        setTableKey(prevKey => prevKey + 1);
        setShowNotification(true);
        setSnackMessage("List(s) deleted successfully.");
      }).catch((error) => {
        console.error("Delete operation failed:", error);
      });
    } catch (error) {
      console.error("Delete operation failed:", error);
    }
  }, [listData, rowsToDelete.data]);

  const handleNotificationClose = React.useCallback(() => {
    setShowNotification(false);
  }, []);

  const handleUserSelect = useCallback((value: string) => {
    setSelectedUser(value);
    if (value.length > 0) {
      navigate(constructUrlWithParams(COMPANY_SIDEBAR_ITEM.path, { joid: value }));
    } else {
      navigate(COMPANY_SIDEBAR_ITEM.path);
    }
  }, [navigate]);

  const handleCompanyClose = React.useCallback(() => {
    setShow(false);
    setCompanyId(null);
  },[]);

  useEffect(() => {
    if (joid) {
      setSelectedUser(joid);
    }else{
      setSelectedUser(user.uid);
    }
  }, [joid, user.uid]);

  useLayoutEffect(() => {
    if (ref) {
      // is ref present in allMaps
      const compList = companyLists.find(l => l.ref === ref);
      if (compList) {
        setOpenRef(ref);
        setSelectedList(compList);
        setName(compList.name);
      }
    }
  }, [ref, companyLists]);

  return (
    <ThemeProvider theme={appTheme}>
      <Snackbar
        open={(snackMessage && snackMessage.length > 0 && showNotification) ? true : false}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
        message={snackMessage}
      />
      <div>
        {error ? (
          <p>{error}</p>
        ) : (
          <>
            <MUIDataTable key={tableKey} data={listData} columns={columns} title={undefined} options={muioptions} />
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Delete List{rowsToDelete && rowsToDelete.data?.length > 1 ? 's' : ''}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete the selected list{rowsToDelete && rowsToDelete.data?.length > 1 ? 's' : ''}? This action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                <Button variant="contained" color="error" onClick={handleDeleteConfirm} autoFocus>Confirm</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
      {/* Dialog for creating a new company list */}
      <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Breadcrumb-like navigation */}
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <StyledLink underline="hover" onClick={handleCloseDialog}>
                <ArrowBackIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                {"Company Research"}
              </StyledLink>
              <EditText
                name="textbox1"
                onChange={handleNameChange}
                defaultValue={name}
                style={{
                  position: "relative",
                  padding: 0,
                  margin: 0,
                  lineHeight: "2em",
                }}
                onSave={handleSave} />
            </Breadcrumbs>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* Add form fields or inputs here as needed */}
          <CreateCompanyList
            openRef={openRef}
            selectedList={selectedList}
            selectedCompanies={selectedCompanies}
            selectedFindemAttr={selectedOption}
            addedCompanies={addedCompanies}
            removedCompanies={removedCompanies}
            handleSelectCompany={handleSelectCompany}
            handleRemoveCompany={handleRemoveCompany}
            isProcessing={isProcessing}
            showCompanyDetails={setShow}
            showCompanyDetailsId={setCompanyId}
            passFilterModel={passFilterModel}
            passDynamicColumns={passDynamicColumns}
            handleOrgChartClick={handleOrgChartClick}
          />
        </DialogContent>
      </Dialog>
      {companyId && <CompanyProfileDetails 
        companyId={companyId}
        open={show}
        onClose={handleCompanyClose}
      />}
      {orgId && <Dialog 
        open={true} 
        onClose={() => setOrgId(undefined)}
        maxWidth="lg"
        fullWidth={true}
      >
        <IframeWrapper ref={iFrameRef} src={`${appNextUrl}/orgchart?companyId=${orgId}&fullPage=true&disableEpOnNodeClick=true&disableEditChart=true&iframe=true`}/>
      </Dialog>}
    </ThemeProvider>
  );

}

export default CompanyResearch;

const IframeWrapper = styledComponent.iframe`
  border: none;
  height: 100vh;
  min-width: 100%;
`;
