// MUI sidebar component
import React from 'react';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Drawer, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import styled from 'styled-components';
import { colors } from '../../common/constants';
import UserContext from '../../common/contexts/UserContext';
import { IUser } from 'findem-types';
import { getEnabledSidebarItems, ISidebarMenuItem } from '../../utils/navigationUtils';
import { NavLink, useSearchParams } from 'react-router-dom';
import { getQueryParamsToPersist } from '../../utils/queryUtil';
import NavUserMenu from '../common/NavUserMenu';

const Sidebar = () => {
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const {user} = React.useContext(UserContext);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const sidebarItems: ISidebarMenuItem[] = React.useMemo(() => {
    return getEnabledSidebarItems(user);
  }, [user]);

  const queryParamsToPersist: URLSearchParams = React.useMemo(() => {
    return getQueryParamsToPersist(currentQueryParameters);
  }, [currentQueryParameters]);

  const handleCollapse = React.useCallback(() => {
    setDrawerOpen(!drawerOpen);

    setTimeout(() => {
      global.dispatchEvent(new Event("resize"));
    }, 500);
  }, [drawerOpen]);
  return (
    <Box component="nav">
      <Drawer
        variant="permanent"
        open={drawerOpen}
        sx={{ width: drawerOpen 
          ? "240px" : "40px", maxWidth: drawerOpen 
            ? "240px" : "40px", transition: "all 0.1s ease-out 0s", padding: "40px 24px 24px",
            height: "100vh",
        }}
        PaperProps={{
          sx: {
            width: drawerOpen ? "240px" : "40px",
            maxWidth: drawerOpen ? "240px" : "40px",
            transition: "all 0.1s ease-out 0s",
            padding: "40px 24px 24px",
            background: "#101828",
            overflow: "initial",
          },
        }}
      >
        <CollapseButton onClick={handleCollapse} $collapsed={!drawerOpen}>
          <ChevronLeftIcon sx={{ fontSize: "20px!important" }} />
        </CollapseButton>
        <Box flex={1} display="flex" flexDirection="column">
          <Stack display="flex" alignItems="center" direction="row" margin="0 8px 40px" textAlign="left">
            <Box component="img" sx={{ height: "29px", textAlign: "center", marginTop: "14px" }} src={'/images/findem-logo.png'} alt="Findem Logo" />
            <Box
              component="img"
              sx={{ height: "29px", textAlign: "center", marginTop: "12px", marginLeft: "8px", opacity: drawerOpen ? 1 : 0, display: drawerOpen ? "unset" : "none" }}
              src={'/images/findem-name-logo.png'}
              alt="Findem Logo"
            />
          </Stack>
          <Stack margin="0 -6px" direction="column" flex={1}>
            <MenuList sx={{ padding: 0 }}>
              {sidebarItems.map((sidebarItem: ISidebarMenuItem) => {
                return (
                  <NavLink
                    key={`sidebar-nav-item-${sidebarItem.name}`}
                    style={{ textDecoration: "none" }}
                    to={{ pathname: sidebarItem.path, search: queryParamsToPersist.toString() }}
                    end={!!sidebarItem?.end}
                  >
                    {({ isActive }: { isActive: boolean }) => (
                      <StyledMenuItem selected={isActive} $collapsed={!drawerOpen} $active={isActive}>
                        <Stack alignItems="center" direction="row" flex={1} sx={{overflow: 'hidden'}}>
                          {<sidebarItem.icon sx={{ color: colors.white, fontSize: "18px!important" }} />}
                          <Typography variant="body1" sx={{ color: colors.white, ml: "10px", opacity: drawerOpen ? 1 : 0 }}>
                            {sidebarItem.name}
                          </Typography>
                        </Stack>
                      </StyledMenuItem>
                    )}
                  </NavLink>
                );
              })}
            </MenuList>
          </Stack>
          {user && user.email && <Stack direction="column" alignItems="center" sx={{
            position: 'relative',
            bottom: 50
          }}>
            <NavUserMenu isCollapsed={!drawerOpen} />
          </Stack>}
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

export const CollapseButton = styled.button<{
  $collapsed: boolean;
}>`
  background: black;
  border: none;
  border-radius: 50%;
  color: ${colors.white};
  cursor: pointer;
  height: 20px;
  padding: 0;
  position: relative;
  left: ${({ $collapsed }) => ($collapsed ? "55px" : "255px")};
  top: 38px;
  transform: rotate(${({ $collapsed }) => ($collapsed ? "180deg" : "0deg")});
  transition: all 0.1s ease-out 0s;
  width: 20px;
  z-index: 1201;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMenuItem = styled(MenuItem)<{
  $collapsed: boolean;
  $active: boolean;
}>`
  display: flex;
  flex: 1 1 auto;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  margin-top: 4px;
  margin-bottom: 8px;
  padding: ${({ $collapsed }) => ($collapsed ? "0 17px" : "0 12px")};

  &&.Mui-selected {
    background: #30374f;
    border-radius: 4px;
    color: ${colors.white};
  }

  &:hover {
    background-color: ${colors["grey-600"]};
  }

  &:after {
    border-radius: 4px;
    border-width: 4px;

    display: ${({ $active, $collapsed }) => ($active && !$collapsed ? "block" : "none")};
    right: -18px;

    transform: scaleY(1);

    border-right: 3px solid #4599fa;
    bottom: 0;
    content: "";
    opacity: 1;
    position: sticky;
    top: 0;
  }
`;