import React, { useMemo } from 'react';
import { mapApis } from '../../common/services/apis';
import { Avatar, AvatarGroup, Box, Skeleton, Tooltip } from '@mui/material';
import { LOGO_URL } from '../../CompanyColumns';
import { colors } from '../../common/constants';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { decodeString } from 'findem-helpers';

interface ICompanyGroupProps {
  compIds: string[];
  isAppNext?: boolean;
}

const CompaniesGroup: React.FC<ICompanyGroupProps> = ({ compIds, isAppNext }) => {
  const {
    data: companiesData,
    isLoading: companiesLoading,
  } = mapApis.getCompaniesByIds.useQuery({
    variables: { ids: compIds },
  });
  const avatarLogos = useMemo(() => {
    if (!companiesData || !companiesData.data || !companiesData.data.data) return [];
    const logos: any = [];
    companiesData.data.data.slice(0, 5).forEach((company: any) => {
      const logo = company?.logos?.find((ref: any) => ref.source === 'linkedin')?.ref;
      if (logo && !logo.includes('null')){
        logos.push(<Tooltip
            title={decodeString(company.company_name)} 
            arrow
            key={logo}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 14,
                  padding: '5px 10px',
                  bgcolor: colors['grey-700'],
                  '& .MuiTooltip-arrow': {
                    color: colors['grey-700'],
                  },
                },
              },
            }}
          >
            <Avatar 
              key={logo} 
              alt={company.company_name} 
              src={LOGO_URL + unescape(logo) + '.jpg'} 
              style={{
                border: `1px solid ${colors["grey-300"]}`,
                width: '30px',
                height: '30px',
                borderRadius: '50%',
              }}
            >
              <ApartmentIcon style={{
                width: '26px',
                height: '26px',
                color: colors["blue-400"]
              }}/>
            </Avatar>
          </Tooltip>);
      }else{
        logos.push(<Avatar key={logo} alt={company.company_name} style={{
          width: '30px', height: '30px', background: colors.white,
          border: `1px solid ${colors["grey-300"]}`,
        }}
        ><ApartmentIcon style={{
          width: '26px',
          height: '26px',
          color: colors["blue-400"]
        }}/></Avatar>);
      }
    });
    // add number avatar for the rest
    if (companiesData.data.data.length > 5) {
      const title = companiesData.data.data.slice(5).map((company: any) => decodeString(company.company_name)).join(', ');
      logos.push(<Tooltip
        title={title} 
        arrow
        key={'all'}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: 14,
              padding: '5px 10px',
              bgcolor: colors['grey-700'],
              '& .MuiTooltip-arrow': {
                color: colors['grey-700'],
              },
            },
          },
        }}
      >
        <Avatar key="more" style={{
          width: '30px', height: '30px', background: colors.white,
          border: `1px solid ${colors["grey-300"]}`,
          color: colors["grey-700"],
          fontSize: 14,
        }}>{`+${companiesData.data.data.length - 5}`}</Avatar>
      </Tooltip>);
    }
    return logos;
  }, [companiesData]);
  return (
    <Box sx={{float: isAppNext ? 'left' : 'right', width: '160px', display: 'flex'}}>
      {companiesLoading && <Skeleton variant="circular" width={40} height={40} />}
      {!companiesLoading && <AvatarGroup
        max={8}
        sx={{
          '& .MuiAvatar-root': { width: 30, 
            height: 30, 
            fontSize: 14,
            color: colors["grey-500"],
            background: colors['grey-200'],
          }
        }}
      >
        {avatarLogos}
      </AvatarGroup>}
    </Box>
  );
};

export default CompaniesGroup;