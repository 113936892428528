import React from 'react';
import { ICompanySize } from '../../../common/types/companyDetails';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Area, CartesianGrid, ComposedChart, Legend, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { colors } from '../../../common/constants';
import { humanizeCount } from 'findem-helpers';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { BUMP_INT, CHART_WIDTH } from './CompanyDetails';

dayjs.extend(utc);

export interface IChartData {
  name: string;
  bar: number | undefined | null;
  line: number | null;
}

interface ICompanyGraphProps {
  size: ICompanySize[];
}

const LINE_KEY = 'Number of Employees';

const CompanyGraph: React.FC<ICompanyGraphProps> = ({
  size
}) => {
  
  const BAR_KEY = React.useMemo(
    () =>
      `Candidate's Tenure`,
    []
  );

  const dataByYear: Record<number, number[]> = React.useMemo(
    () =>
      size.reduce<Record<number, number[]>>((acc, datapoint) => {
        const year: number = dayjs.utc(datapoint.date).year();
        const value: number =
          typeof datapoint.employees !== 'number'
            ? Number.parseInt(datapoint.employees)
            : datapoint.employees;

        if (Number.isNaN(value)) {
          return acc;
        }

        return {
          ...acc,
          [year]: acc[year] ? [...acc[year], value] : [value]
        };
      }, {}),
    [size]
  );

  const data: IChartData[] = React.useMemo(
    () => {
      const chartDataByYear: Record<number, IChartData> = Object.entries(dataByYear).reduce((acc, [year, values]) => {
        // average data within a year
        const value: number = Math.floor(
          values.reduce((acc, v) => acc + v, 0) / values.length
        );
        return {
          ...acc,
          [year]: {
            name: year,
            bar: value,
            line: value
          }
        }
      }, {});

      // Some years are missing data, interpolate the missing dates
      const dataYears: number[] = Object.keys(chartDataByYear).map((y) => parseInt(y));
      dataYears.forEach((year: number) => {
        if (!chartDataByYear[year]) {
          const prevYear: number | undefined = dataYears.filter((dataYear: number) => dataYear < year).at(-1);
          const nextYear: number | undefined = dataYears.filter((dataYear: number) => dataYear > year).at(0);

          if (nextYear && chartDataByYear[nextYear].line) {
            chartDataByYear[year] = {
              name: year.toString(),
              bar: chartDataByYear[nextYear].line,
              line: null,
            }
          } else if (prevYear && chartDataByYear[prevYear].line) {
            chartDataByYear[year] = {
              name: year.toString(),
              bar: chartDataByYear[prevYear].line,
              line: null,
            }
          }
        }
      });

      return Object.values(chartDataByYear);
    },
    [dataByYear]
  );

  const CustomTooltip = ({
    active,
    payload
  }: {
    active: boolean;
    payload: Array<{ value: number; payload: { name: string; line: number } }>;
  }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContain>
          <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant='h4'>{payload[0]?.payload?.name}</Typography>
          <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            {humanizeCount(payload[0]?.payload?.line)} employees
          </Typography>
        </TooltipContain>
      );
    }
  
    return null;
  };
  

  return (
    <ComposedChart
      width={CHART_WIDTH}
      height={225}
      data={data}
      margin={{ top: 10, right: 15, bottom: 0, left: 0 }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis
        interval="preserveStartEnd"
        // minTickGap={1}
        tickLine={false}
        tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
        dataKey="name"
      />
      <YAxis
        tickLine={false}
        tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
        tickFormatter={
          // number | string is okay
          humanizeCount as any
        }
      />
      {/* @ts-ignore */}
      <Tooltip content={<CustomTooltip />} />
      <defs>
        <linearGradient id="colorTenure" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="rgb(117, 181, 255)" stopOpacity={0.6} />
          <stop
            offset="100%"
            stopColor="rgb(117, 181, 255)"
            stopOpacity={0.1}
          />
        </linearGradient>
      </defs>
      <Area
        type={BUMP_INT}
        dataKey="bar"
        stroke="transparent"
        fillOpacity={1}
        fill="url(#colorTenure)"
      />
      <Line
        type={BUMP_INT}
        dot={false}
        strokeWidth="2"
        dataKey="line"
        stroke={colors['blue-300']}
        connectNulls
      />
      <Legend
        style={{
          fontFamily: 'Roboto, sans-serif',
        }}
        align="center"
        verticalAlign="bottom"
        payload={[
          {
            id: 'square',
            type: 'square',
            value: BAR_KEY,
            color: colors['blue-200']
          },
          {
            id: 'line',
            type: 'line',
            value: LINE_KEY,
            color: colors['blue-300']
          }
        ]}
        formatter={(value: any) => (
          <span style={{ color: colors['grey-500'], fontFamily: 'Roboto, sans-serif' }}>{value}</span>
        )}
      />
    </ComposedChart>
  );
};

export default CompanyGraph;

export const TooltipContain = styled.div`
  background: ${colors.white};
  padding: 1em;
  border-radius: 1em;
  box-shadow: 0 0 12px #c0c5cd;
  text-align: center;
`;