// Copyright 2024 and onwards, All rights reserved.
// Author: Hariharan Kolam

import React, { useState, useEffect, useMemo, useCallback, useLayoutEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Snackbar, Box, Menu, MenuItem, MenuProps } from '@mui/material';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { deleteMap, createUpdateSearchAndLoadLongList, updateSearchLink, purgeCachedCompanyLists } from '../../../Apis';
import { useNavigate, useLocation } from 'react-router-dom';
import "./Map.css";
import { displayTimeAgo, basename } from '../../../utils';
import BarChartIcon from '@mui/icons-material/BarChart';
import UserSelect from '../../common/UserSelect/UserSelect';
import { mapApis } from '../../../common/services/apis';
import UserContext from '../../../common/contexts/UserContext';
import { IMap } from '../../../common/types/mapTypes';
import CreateMapSimple from '../CreateMap/CreateMap';
import { appendPlural, constructUrlWithParams, useUrlParams } from 'findem-helpers';
import { INSIGHTS_SIDEBAR_ITEM, MAP_SIDEBAR_ITEM } from '../../../utils/navigationUtils';
import { default as StarterWidget } from './../../../AdLib';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the dropdown icon
import EditNoteIcon from '@mui/icons-material/EditNote';
import TuneIcon from '@mui/icons-material/Tune';
import { colors } from '../../../common/constants';
import MapClone from '../Modals/MapClone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Delete from '@mui/icons-material/Delete';
import styled from '@mui/styles/styled';
import Processing from '../../../common/contexts/Processing';

// Tooltip message
const tooltipMessage = (
  <React.Fragment>
    <Typography color="inherit">
      Data Verification Process
    </Typography>
    Findem researchers will verify all matching titles, companies, and industries defined as part of the map, ensure that the profiles are valid, contact information is vetted, and assure the user of data quality and comprehensiveness.
  </React.Fragment>
);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: '8px',
    minWidth: 180,
    color: colors['grey-500'],
    boxShadow: 'none',
    border: `1px solid ${colors['grey-400']}`,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: colors['grey-500'],
        marginRight: '4px',
      },
      '&:active': {
        backgroundColor: colors['grey-100'],
      },
    },
  },
}));

const MapGenerator = () => {
  const [rows, setRows] = useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState<any | undefined>(undefined);
  const [open, setOpen] = React.useState<boolean>(false);
  const [allMaps, setAllMaps] = React.useState<IMap[]>([]);
  const [openRef, setOpenRef] = React.useState<string|null>(null);
  const [selectedMap, setSelectedMap] = React.useState<IMap|undefined>(undefined);
  const [selectedUser, setSelectedUser] = React.useState('');
  const [error, setError] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [tableKey, setTableKey] = React.useState(0);
  const [ adLibPopoverOpen, setAdLibPopoverOpen ] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [actionAnchorEl, setActionAnchorEl] = useState<null | any>(null);
  const [passMapQuery, setPassMapQuery] = useState<any>([]);
  const [cloneMap, setCloneMap] = useState<IMap|undefined>(undefined);

  const {user} = React.useContext(UserContext);
  const {joid, ref} = useUrlParams();

  const {setIsProcessing} = React.useContext(Processing);

  // Dropdown button handlers
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleActionDropdownClick = (mapId: string, event: React.MouseEvent<HTMLButtonElement>) => {
    // setActionAnchorEl(event.currentTarget);
    setActionAnchorEl({ [mapId]: event.currentTarget });
  };

  const handleActionDropdownClose = () => {
    setActionAnchorEl(null);
  };

  const handleDropdownOptionClick = (option: string) => {
    if (option === 'research') {
      // Logic for Research & Add Companies
      console.log('Research & Add Companies option selected');
      // Perform actions or navigation as required
    } else if (option === 'load') {
      // Open popover logic
      setAdLibPopoverOpen(true);
    }
    handleDropdownClose();
  };

  const handlePopoverClose = () => {
    setAdLibPopoverOpen(false);
  };

  const mapName: string = useMemo(() => {
    if (selectedMap) {
      return selectedMap.name;
    }
    return 'Map-' + Math.floor(Date.now() / 1000);;
  }, [selectedMap]);

  const mapQuery: any = useMemo(() => {
    if (selectedMap) {
      return JSON.parse(selectedMap.all_rules_str);
    } else if(passMapQuery.length > 0){
      return passMapQuery;
    }
    return [];
  }, [selectedMap, passMapQuery]);

  const { 
    data: mapsData, 
    isFetching: mapDataFetching, 
    isLoading: mapDataLoading,
    refetch: refetchMaps
  } = mapApis.getAllMaps.useQuery({ variables: { id: joid || selectedUser || user.uid } });

  const {
    data: getICPsByTypeData,
    isLoading: icpsByTypeLoading,
    isFetching: icpsByTypeFetching
  } = mapApis.getICPsByType.useQuery({ 
    variables: { joid: selectedUser || user.uid } 
  });

  const icpMapObject: Record<string, any> = useMemo(() => {
    return getICPsByTypeData?.data?.reduce((acc: any, icp: any) => {
      acc[icp.ref] = icp;
      return acc;
    }, {}) ?? {};
  }, [getICPsByTypeData]);

  const fetchSlCount = useCallback((icpid: string) => {
    return icpMapObject[icpid]?.cxt_data.match_feedback.filter(
      (mf: any) => mf.shortlisted
    ).length || 0;
  } ,[icpMapObject]);

  useEffect(() => {
    if(mapsData && mapsData.data) {
      setAllMaps(mapsData.data);
      
      let data = mapsData?.data?.map((m: { 
        name: any; 
        created_at: any; 
        search_link: string; 
        status: any; 
        ref: any; 
        searchId: string;
        mapLink: string;
        slLink: string;
      }) => ({ // Using map function for cleaner code
        name: m.name,
        date: m.created_at,
        shortlisted: fetchSlCount(basename(m.search_link)),
        status: m.status,
        maplink: m.search_link ? '/searches/' + basename(m.search_link) + `?channel=external&searchView=company&joid=${selectedUser || user.uid}&mapid=${m.ref}` : "",
        id: m.ref,
        searchId: m.search_link ? m.search_link.split('/').at(-1) : "",
        slLink: m.search_link ? '/searches/' + basename(m.search_link) + `?tab=shortlist&searchView=search&joid=${selectedUser || user.uid}&mapid=${m.ref}` : "", 
      })) ?? [];
      setRows(data);
    }else{
      setRows([]);
    }
  }, [fetchSlCount, mapsData, selectedUser, user.uid]);

  const generateInsights = useCallback((id: any) => {
    // make ajax request to generate link
    // let row = rawData[id];
    if(id){
      navigate(constructUrlWithParams(INSIGHTS_SIDEBAR_ITEM.path, {ref: id, joid: joid || user.uid}));
    }
  },[joid, user, navigate]);

  const browseLink = useCallback((link: string) => {
    navigate(link);
  },[navigate]);

  const generateMapResults = useCallback(async(rule?: IMap, redirectTo?: string) => {
    let icpid = '';
    if (selectedMap) {
      icpid = selectedMap.search_link ? basename(selectedMap.search_link) : "";
      if(!rule){
        rule = selectedMap;
      }
    }
    if (!rule) {
      return;
    }
    let res = await createUpdateSearchAndLoadLongList(unescape(rule.name), icpid, JSON.parse(rule.all_rules_str), rule.owner_id);
    if(res && res.ref && rule.search_link.indexOf(res.ref) < 0) {
      await updateSearchLink(rule.ref, res.ref, rule.owner_id);
    }
    if(redirectTo){
      if(redirectTo === 'results'){
        navigate(`/searches/${res.ref}?channel=external&searchView=company&joid=${selectedUser || user.uid}&mapid=${rule.ref}`);
      } else if (redirectTo === 'insights') {
        // navigate(constructUrlWithParams(INSIGHTS_SIDEBAR_ITEM.path, {ref: rule.ref, joid: joid || user.uid}));
        window.open(`https://insights.findem.ai/adhoc_study?ref=${rule.ref}&template=65d522c64c69ecbd2699a0de&talent_group="${res.ref}"&hide_impersonation_banner=true&joid=${joid || user.uid}`, '_blank');
      }
      return;
    }
    setShowNotification(true);
    setSnackMessage(`Successfully saved the ${rule.name} map.`);
    setOpen(false);
    setOpenRef(null);
    setAllMaps([]);
    refetchMaps();
  },[joid, refetchMaps, selectedMap, selectedUser, user.uid, navigate]);

  const handleUserSelect = useCallback((value: string) => {
    setSelectedUser(value);
    if(value.length > 0){
      navigate(constructUrlWithParams(MAP_SIDEBAR_ITEM.path, {joid: value}));
    }else{
      navigate(MAP_SIDEBAR_ITEM.path);
    }
  },[navigate]);
  // console.log("passMapQuery", passMapQuery);
  const handleClickOpen = useCallback((ref: string | null, passMapQuery?: any) => {
    purgeCachedCompanyLists(joid || user.uid);
    if (passMapQuery) {
      setPassMapQuery(passMapQuery);
    }
    if (!ref) {
      setOpen(true);
      setOpenRef(null);
      setSelectedMap(undefined);
    } else {
      const map = allMaps.find(l => l.ref === ref);
      if (map) {
        setOpenRef(ref);
        setSelectedMap(map);
        setOpen(true);
        const params: Record<string, string> = {ref: ref};
        if(joid){
          params['joid'] = joid;
        }
        navigate(constructUrlWithParams(MAP_SIDEBAR_ITEM.path, params))
      } else {
        setError('Error: List not found.');
      }
    }
  }, [allMaps, joid, navigate]);

  const requestVerification = useCallback((rowIndex: any) => {
    console.log("Requesting verification for row index", rowIndex);
  }, []);

  const openMap = useCallback((ref: any) => {
    handleClickOpen(ref)
  }, [handleClickOpen]);
  const saveCb = useCallback(async (rule: any, generate_map:boolean = false, submit:boolean = false, redirectTo: string | undefined = undefined) => {
    if (generate_map) {
      await generateMapResults(rule, redirectTo);
      return;
    }
    setShowNotification(true);
    setSnackMessage(`Successfully saved the ${rule.name} map.`);
    setOpen(false);
    setOpenRef(null);
    refetchMaps();
  },[generateMapResults, refetchMaps]);

  useEffect(() => {
    if (!open && location.hash.indexOf("new_map") >= 0) {
      navigate("#map-generator?action=new_map");
    }
    if (!open && location.hash.indexOf("open_existing_map") >= 0) {
      const hash = location.hash;
      navigate(hash);
    }
    // Depending on your requirements, you might also need to handle the case where you want to close the dialog
    // if the hash changes and no longer matches "new_company_list".
  }, [open, location.hash, navigate]);

  const handleDeleteConfirm = async () => {
    if(!rowsToDelete) return;
    // Assuming mapData contains an id for each map to delete
    const ids = rowsToDelete.mapIds;
    const searchIds = rowsToDelete.searchIds;
    setIsProcessing(true);
    try {
      setDialogOpen(false)
      await deleteMap(ids, joid || user.uid).then(() => {
        mapApis.deleteMapsIcp.mutationFn({ ids: searchIds, joid: selectedUser || user.uid }).then(() => {
          setShowNotification(true);
          setSnackMessage(`Successfully deleted the selected maps.`);
          setRowsToDelete(undefined);
          setOpen(false);
          setOpenRef(null);
          setTableKey(tableKey + 1);
          refetchMaps();
          setIsProcessing(false);
        }).catch((error) => {
          console.error("Delete operation failed:", error);
          setIsProcessing(false);
          setTableKey(tableKey + 1);
        });
      });
      // Reload maps to reflect deletion
      // This is a simplified approach; consider optimizing to avoid refetching all data
    } catch (error) {
      console.error("Delete operation failed:", error);
    }
    setDialogOpen(false); // Close the dialog
  };

  useEffect(() => {
    if(joid){
      setSelectedUser(joid);
    }else{
      setSelectedUser(user.uid)
    }
  },[joid, user.uid]);

  // Column definitions and options for MUIDataTable would go here

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: 'searchId',
      label: 'Search ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: 'slLink',
      label: 'Search Shortlist Link',
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, meta: { rowData: any[]; }) => {
          return (<>
            <Button
              sx={{ 
                fontSize: '14px',
                textTransform: 'none',
                color: colors['grey-700'],
                '&:hover': {
                  background: 'none',
                  color: colors['blue-400'],
                },
                paddingLeft: '0px',
              }} 
              size="small"
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              onClick={() => { 
                openMap(meta.rowData[0]) 
              }}>
              {value}
            </Button>
          </>);
        }
      },
    },
    {
      name: 'date',
      label: 'Date Created',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return <>{displayTimeAgo(value)} </>
        }
      }
    },
    {
      name: 'shortlisted',
      label: 'Shortlisted',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: number, meta: { rowData: any; }) => {
          return meta.rowData[5] > 0 ? <Box
            className="Shortlisted-Link"
            sx={{
              color: colors['blue-400'],
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
            onClick={() => { browseLink(meta.rowData[2]) }}
          >{value}</Box> : <>0</>
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: number, meta: any) => {
          if (value == 0) {
            return (
              <Tooltip
                title={tooltipMessage}
                placement="top"
                arrow
                enterDelay={500}
                leaveDelay={200}
                sx={{
                  tooltip: {
                    backgroundColor: '#f5f5f9',
                    color: 'rgba(0, 0, 0, 0.87)',
                    maxWidth: 220,
                    fontSize: '1em',
                    border: '1px solid #dadde9',
                    '& .MuiTypography-colorInherit': {
                      color: '#555'
                    }
                  },
                  arrow: {
                    color: '#f5f5f9',
                  },
                }}
              >
                {/* <Button size="small" onClick={() => requestVerification(meta.rowIndex)}>
                  Request Data Verification
                </Button> */}
                <Chip clickable className='Chip-Primary' onClick={() => requestVerification(meta.rowIndex)} label={"Submit for verification"} />
              </Tooltip>)
          } else if (value === 1) {
            return <Chip label={"Submitted"} sx={{
              backgroundColor: colors['blue-100'],
              color: colors['blue-400']
            }} />
          } else if (value === 2) {
            return <Chip className='Chip-Info' label={"In progress"}  sx={{
              backgroundColor: colors['yellow-100'],
              color: colors['yellow-400']
            }}/>
          } else if (value === 3) {
            return <Chip className='Chip-Success' label={"Mapping completed"} sx={{
              backgroundColor: colors['green-100'],
              color: colors['green-400']
            }}/>
          } else if (value === 0 || value === 4) {
            return <><Chip label={"Self service"} sx={{
              backgroundColor: colors['blue-100'],
              color: colors['blue-400']
            }}/></>
          }
        }
      }
    },
    {
      name: 'maplink',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          const mapId = meta.rowData[0];
          if (!value) {
            return <Box
              sx={{
                display: 'flex',
                gap: '6px',
                float: 'right',
                paddingRight: '16px',
              }}
            >
              <Button sx={{width: 160}} onClick={() => { generateMapResults() }} size="small" variant="outlined">Generate results</Button>
              <Button onClick={() => { generateInsights(meta.rowData[0]) }} size="small" variant="outlined"><BarChartIcon /></Button>
              <Button 
                sx={{minWidth: '40px'}}
                onClick={(ev) => {
                  handleActionDropdownClick(mapId, ev);
                }}
                size="small" variant="outlined"
                aria-controls={actionAnchorEl && actionAnchorEl[mapId] ? `action-dropdown-menu1-${mapId}` : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(actionAnchorEl) ? 'true' : undefined}
              ><MoreVertIcon /></Button>
              <StyledMenu
                key={meta.rowData[0]}
                id={`action-dropdown-menu1-${mapId}`}
                anchorEl={actionAnchorEl && actionAnchorEl[mapId]}
                keepMounted
                open={actionAnchorEl && actionAnchorEl[mapId] ? true : false}
                onClose={handleActionDropdownClose}
              >
                <MenuItem onClick={(ev) => { 
                  ev.preventDefault();
                  ev.stopPropagation();
                  setCloneMap(mapsData?.data.find((m: { ref: any; }) => m.ref === meta.rowData[0])); 
                  handleActionDropdownClose();}
                }
                ><ContentCopyIcon sx={{ mr: 1 }} />Clone</MenuItem>
                <MenuItem onClick={() => { 
                  setDialogOpen(true);
                  setRowsToDelete({
                    mapIds: [meta.rowData[0]],
                    searchIds: [meta.rowData[1]]
                  });
                  handleActionDropdownClose();
                }}><Delete sx={{ mr: 1 }} />Delete</MenuItem>
              </StyledMenu>  
            </Box>
          }
          return <Box
          sx={{
            display: 'flex',
            gap: '6px',
            float: 'right',
            paddingRight: '16px',
          }}
        >
            <Button sx={{width: 160, textTransform: 'none'}} onClick={() => { browseLink(value)}} size="small" variant="outlined">Browse results</Button>
            <Button className="MapActionBtn" onClick={() => { generateInsights(meta.rowData[0]) }} size="small" variant="outlined"><BarChartIcon /></Button>
            <Button 
              sx={{minWidth: '40px'}}
              onClick={(ev) => {
                handleActionDropdownClick(mapId, ev);
              }}
              size="small" variant="outlined"
              aria-controls={`action-dropdown-menu-${meta.rowData[0]}`}
              aria-haspopup="true"
              aria-expanded={Boolean(actionAnchorEl) ? 'true' : undefined}
            ><MoreVertIcon /></Button>
              <StyledMenu
                key={meta.rowData[0]}
                id={`action-dropdown-menu1-${mapId}`}
                anchorEl={actionAnchorEl && actionAnchorEl[mapId]}
                keepMounted
                open={actionAnchorEl && actionAnchorEl[mapId] ? true : false}
                onClose={handleActionDropdownClose}
              >
                <MenuItem 
                  key={meta.rowData[0]}
                  id={`clone-${meta.rowData[0]}`}
                  onClick={(ev) => { 
                    ev.preventDefault();
                    ev.stopPropagation();
                    setCloneMap(mapsData?.data.find((m: { ref: any; }) => m.ref === meta.rowData[0])); 
                    handleActionDropdownClose();}
                  }
                ><ContentCopyIcon sx={{ mr: 1 }} />Clone</MenuItem>
                <MenuItem onClick={() => { 
                  setDialogOpen(true);
                  setRowsToDelete({
                    mapIds: [meta.rowData[0]],
                    searchIds: [meta.rowData[1]]
                  });
                  handleActionDropdownClose();
                }}><Delete sx={{ mr: 1 }} />Delete</MenuItem>
              </StyledMenu>  
          </Box>
        }
      },
    }
  ];

  // console.log(anchorEl);
  const options: MUIDataTableOptions = useMemo(() =>({
    filterType: 'checkbox',
    print: false,
    pagination: false,
    download: false,
    filter: false,
    viewColumns: false,
    sortOrder:{
      name: 'date',
      direction: 'desc'
    },
    textLabels: {
      body: {
        noMatch: (mapDataLoading || mapDataFetching) ? <CircularProgress/> :  "No executive maps found. Click the 'Create new map' button to get started.",
      },
    },
    onRowsDelete: (rowsToDelete) => {
      setDialogOpen(true);
      const _rowsToDelete: any = {
        mapIds: [],
        searchIds:[]
      }
      rowsToDelete.data.forEach((rowIndex: any) => {
        _rowsToDelete.mapIds.push(rows[rowIndex.dataIndex].id);
        _rowsToDelete.searchIds.push(rows[rowIndex.dataIndex].searchId);
      });
      setRowsToDelete(_rowsToDelete);
      return false; // Tell MUIDataTable to not delete the rows yet
    },
    customToolbar: () => {
      return (
        <div>
          <UserSelect 
            value={selectedUser}
            onChangeCB={handleUserSelect}
          />
          <Button
            aria-controls="dropdown-menu2"
            aria-haspopup="true"
            onClick={handleDropdownClick}
            variant="contained"
            endIcon={<ArrowDropDownIcon />} // Add an icon at the end of the button
            sx={{
              textTransform: 'none',
              background: '#4599fa',
              borderColor: '#4599fa',
              padding: '6px 12px',
              fontSize: '14px',
              lineHeight: '140%',
              fontFamily: 'Roboto, sans-serif',
            }}
          >
            + Create new map
          </Button>

          <Menu
            id="dropdown-menu2"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleDropdownClose}
          >
            <MenuItem onClick={() => { handleDropdownClose(); setAdLibPopoverOpen(true)}}><EditNoteIcon sx={{ mr: 1 }} />Simple Research</MenuItem>
            <MenuItem onClick={() => { handleDropdownClose(); handleClickOpen(null)}}><TuneIcon sx={{ mr: 1 }} />Advanced Research</MenuItem>
          </Menu>   
          {adLibPopoverOpen && <Dialog
            open={adLibPopoverOpen}
            onClose={handlePopoverClose}
            aria-labelledby="dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
            <StarterWidget 
              mapName={mapName}
              setAdLibPopoverOpen={setAdLibPopoverOpen}
              handleClickOpen={handleClickOpen}
              saveCb={saveCb}
            />
          </Dialog> }
        </div>
      )
    },
  }), [mapDataLoading, anchorEl, adLibPopoverOpen, mapDataFetching, selectedUser, handleUserSelect, handleClickOpen]);

  const handleClose = useCallback((submit = false) => {
    setOpen(false);
    setOpenRef(null);
    setPassMapQuery([]);
    if(joid){
      navigate(constructUrlWithParams(MAP_SIDEBAR_ITEM.path, {joid: joid}));
    } else {
      navigate(MAP_SIDEBAR_ITEM.path);
    }
  },[joid])

  const handleNotificationClose = React.useCallback(() => {
    setShowNotification(false);
  },[]);

  useLayoutEffect(() => {
    if(ref){
      // is ref present in allMaps
      const map = allMaps.find(l => l.ref === ref);
      if(map){
        setOpenRef(ref);
        setSelectedMap(map);
        setOpen(true);
      } else if(ref === 'new'){
        setOpen(true);
      }
    }
  },[ref, allMaps]);
  return (
    <>
      <Snackbar
        open={(snackMessage && snackMessage.length > 0 && showNotification) ? true : false}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
        message={snackMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {(
        <div className='map-table'>
          <MUIDataTable
            key={tableKey}
            data={rows}
            columns={columns}
            options={options} 
            title={undefined}
            />
        </div>
      )}
      {open && <Dialog
        open={open}
        onClose={() => { handleClose(false) }}
        fullWidth
        maxWidth="lg"
        style={{ height: '100vh' }}
        sx={{
          height: '100vh',
          '& .MuiDialog-paper': {
            height: '95vh',
            maxHeight: '95vh',
            width: '90vw',
            maxWidth: '90vw',
          },
          '& .MuiDialogContent-root': {
            width: '900px',
            margin: 'auto',
          }
        }}
      >


        {/* <CreateMap handleClose={handleClose} mapvalue={mapvalue} id={id} mapname={mapname} /> */}
        <CreateMapSimple
          handleClose={handleClose}
          id={openRef}
          saveCb={saveCb}
          name={mapName}
          query={mapQuery}
          selectedMap={selectedMap}
        />
      </Dialog>}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Delete Map{rowsToDelete && rowsToDelete.mapIds.length > 1 ? 's' : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected map{appendPlural(rowsToDelete?.mapIds.length || 0)}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button variant='contained' color="error" onClick={handleDeleteConfirm} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
      {cloneMap && <MapClone map={cloneMap} onClose={() => setCloneMap(undefined)} saveCb={saveCb}/>}
    </>
  );
};

export default MapGenerator;
