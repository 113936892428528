const protocol = 'https:'; /* Uncomment for dev */

const envUrls = {
  staging: {
    app: 'app-staging',
    collaborators: 'collaborators-staging',
    jobowners: 'jobowners-staging',
    links: 'links-staging',
    matches: 'matches-staging',
    'oauth-pub': 'oauth-pub-staging',
    pub: 'pub-staging',
    sa: 'sa-staging',
    search: 'search-staging',
    settings: 'settings-staging',
    sourcing: 'sourcing-staging',
    notification: 'notification-staging',
    payments: 'payments-staging',
    appNext: 'app-next-staging'
  },
  testing: {
    app: 'app-testing',
    collaborators: 'collaborators-testing',
    jobowners: 'jobowners-testing',
    links: 'links-testing',
    matches: 'matches-testing',
    'oauth-pub': 'oauth-pub-testing',
    pub: 'pub-testing',
    sa: 'sa-testing',
    search: 'search-testing',
    settings: 'settings-testing',
    sourcing: 'sourcing-testing',
    notification: 'notification-testing',
    payments: 'payments-testing',
    appNext: 'app-next-testing'
  },
  prod: {
    app: 'app',
    collaborators: 'collaborators',
    jobowners: 'jobowners',
    links: 'links',
    matches: 'matches',
    'oauth-pub': 'oauth-pub',
    pub: 'pub',
    sa: 'sa',
    search: 'search',
    settings: 'settings',
    sourcing: 'sourcing',
    notification: 'notification',
    payments: 'payments',
    appNext: 'app-next'
  }
};

const getUrl = (name) => {
  const env = process.env.REACT_APP_DEPLOY_ENV || 'prod';
  return envUrls[env][name];
};

export const appUrl = `${protocol}//${getUrl('app')}.findem.ai`;
export const collabUrl = `${protocol}//${getUrl('collaborators')}.findem.ai`;
export const jobOwnersUrl = `${protocol}//${getUrl('jobowners')}.findem.ai`;
export const linksUrl = `${protocol}//${getUrl('links')}.findem.ai`;
export const matchesUrl = `${protocol}//${getUrl('matches')}.findem.ai`;
export const oauthUrl = `${protocol}//${getUrl('oauth-pub')}.findem.ai`;
export const pubUrl = `${protocol}//${getUrl('pub')}.findem.ai`;
export const saUrl = `${protocol}//${getUrl('sa')}.findem.ai`;
export const searchUrl = `${protocol}//${getUrl('search')}.findem.ai`;
export const settingsUrl = `${protocol}//${getUrl('settings')}.findem.ai`;
export const sourcingUrl = `${protocol}//${getUrl('sourcing')}.findem.ai`;
export const notificationsUrl = `${protocol}//${getUrl(
  'notification'
)}.findem.ai`;
export const paymentsUrl = `${protocol}//${getUrl('payments')}.findem.ai`;
export const appNextUrl = `${protocol}//${getUrl('appNext')}.findem.ai`;


export const SA_LOGIN_PATH = '/sourcing/login';
