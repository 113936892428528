import React from 'react';
import { IFundingHistory } from '../../../common/types/companyDetails';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { Cell, Row, Table, Thead } from './TableDetails';
import { appendPlural, humanizeCount } from 'findem-helpers';
import { colors } from '../../../common/constants';
import { DATE_FORMAT } from './CompanyDetails';

interface IFunding extends Omit<IFundingHistory, 'amount'> {
  amount: number | undefined;
}

const sortFn = (a: IFunding, b: IFunding) => dayjs(b.date).diff(dayjs(a.date));

const Funding: React.FC<{ funding: IFundingHistory[] }> = ({ funding }) => {
  const [expand, setExpand] = React.useState<boolean>(false);
  const showMore: React.MouseEventHandler = React.useCallback(
    () => setExpand(true),
    []
  );
  const showLess: React.MouseEventHandler = React.useCallback(
    () => setExpand(false),
    []
  );
  const sortedFunding: IFunding[] = React.useMemo(
    () =>
      funding
        .map((round) => ({
          ...round,
          amount: round.amount ? Number.parseFloat(round.amount) : undefined
        }))
        .sort(sortFn),
    [funding]
  );
  const numRounds: number = React.useMemo(() => funding.length, [funding]);
  const totalAmount: Record<string, number> = React.useMemo(
    () =>
      sortedFunding.reduce<Record<string, number>>(
        (acc, round) =>
          round.amount && !isNaN(round.amount)
            ? !round.currency || round.currency === 'USD'
              ? { ...acc, USD: (acc.USD || 0) + round.amount }
              : {
                  ...acc,
                  [round.currency]: (acc[round.currency] || 0) + round.amount
                }
            : acc,
        {}
      ),
    [sortedFunding]
  );

  const FundingRound: React.FC<{ round: IFunding }> = ({ round }) => {
    const date: string | undefined = React.useMemo(
      () => (round.date ? dayjs(round.date).format(DATE_FORMAT) : undefined),
      [round.date]
    );
  
    return (
      <Row key={round.series}>
        <Cell>{round.series}</Cell>
        <Cell>{date}</Cell>
        <Cell>
          {round.amount && (
            <>
              {humanizeCount(round.amount)} {round.currency}
            </>
          )}
        </Cell>
      </Row>
    );
  };

  return sortedFunding.length > 0 ?
    <>
      <Typography 
          variant="h5"
          sx={{ fontSize: '14px', color: colors['grey-500'], margin: '8px 0px', fontWeight: 400, fontStretch: 'condensed', textTransform: 'uppercase'}}
          >Funding</Typography>
      <Typography>
        {numRounds} Round{appendPlural(numRounds)}{' '}
        {Object.keys(totalAmount).length > 0 && (
          <>
            for{' '}
            {Object.entries(totalAmount).map(([currency, amount]) => (
              <React.Fragment key={currency}>
                {humanizeCount(amount)} {currency}
              </React.Fragment>
            ))}
          </>
        )}
      </Typography>
      {expand ? (
        <>
          <SeeMoreLessButton variant="text" onClick={showLess}>
            See less
          </SeeMoreLessButton>
          <Table>
            <Thead>
              <Row>
                <Cell>Series</Cell>
                <Cell>Date</Cell>
                <Cell>Amount</Cell>
              </Row>
            </Thead>
            <tbody>
              {sortedFunding.map((round) => (
                <FundingRound round={round} key={round.date || round.series} />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <SeeMoreLessButton variant="text" onClick={showMore} className='detail'>
          See all
        </SeeMoreLessButton>
      )}
    </> : null;
}

export default Funding;

export const SeeMoreLessButton = styled(Button)`
  padding: 0;
`;