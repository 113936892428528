import { ThemeOptions } from "@mui/material";
export enum IEmbedModeType {
  App = "app",
  CompanyApp = "companyList_id",
  MapsApp = "maps_app",
}

export interface IEmbedDisplayOptions {
  hideHeader?: boolean;
  hideActions?: boolean;
  themeOverrides?: ThemeOptions;
}

export interface IBaseEmbedMode {
  type: IEmbedModeType;
  themeOverrides?: ThemeOptions;
}

export interface IAppEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.App;
}

export interface ICompanyAppEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.CompanyApp;
}

export interface IMapsAppEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.MapsApp;
}

export type IEmbedMode =
  | IAppEmbedMode
  | ICompanyAppEmbedMode
  | IMapsAppEmbedMode;