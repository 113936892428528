import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppProviders from './AppProviders';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Container, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Sidebar from './components/Sidebar/Sidebar';
import CustomRoutes from './Routes';
import Login from './components/common/Login/Login';
import { IEmbedMode, IEmbedModeType } from './common/types/embed';
import PermissionedComponent from './components/PermissionedComponent';
import DefaultForbiddenComponent from './components/PermissionedComponent/DefaultForbiddenComponent/DefaultForbiddenComponent';
import { Role } from './utils/accessUtils';
import CompanyList from './components/CompanyLists/CompanyLists';
const MapPage = React.lazy(() => import('./components/Map/MapPage'));

const queryClient = new QueryClient();
interface IAppProps {
  embedMode: IEmbedMode | undefined;
}

const App = (props: IAppProps) =>  {
  const { embedMode } = props;
  // get the path and check if its /login
  const isLogin = React.useMemo(() => {
    const {pathname} = new URL(window.location.href);
    return pathname.startsWith('/login');
  }, []);
  return (<>
    <AppProviders>
      <BrowserRouter>
        {!embedMode && 
          <Stack direction="row" height="100vh">
            {!isLogin && <Sidebar />}
            <Container maxWidth={false} sx={{ display: "flex", flex: "1 1 auto", boxSizing: "border-box", minWidth: 0, p: "0 32px" }}>
              <Routes>
                <Route
                  path={'/login'}
                  element={
                    <Login />
                  }
                />
              </Routes>
              {!isLogin && <CustomRoutes />}
            </Container>
          </Stack>
        }
        {embedMode && embedMode.type === IEmbedModeType.CompanyApp && <Routes>
          <Route
          path={'/*'}
          element={
            <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
              <CompanyList isAppNext={true}/>
            </PermissionedComponent>
          }
        />
        </Routes>}
        {embedMode && embedMode.type === IEmbedModeType.MapsApp && <Routes>
          <Route 
            path={'/*'}
            element={
              <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
                <Suspense fallback={<CircularProgress />}>
                  <MapPage />
                </Suspense>
              </PermissionedComponent>
            }
          />
        </Routes>}
      </BrowserRouter>
    </AppProviders>
  </>);
}

const LiftedApp = (props: IAppProps) => {
  console.log('LiftedApp', props);
  return (
    <QueryClientProvider client={queryClient}>
      <App {...props}/> 
    </QueryClientProvider>
  );
}

export default LiftedApp;

