import { ReactNode, useEffect, useState } from "react";
import { useCurrentUser } from "./common/hooks/useCurrentUser";
import AppStyles from "./App.styled";
import UserContext from "./common/contexts/UserContext";
import { Backdrop, CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { APP_THEME } from "./common/constants/themes/AppTheme";
import useDummyFn from "./common/hooks/useDummyFn";
import { IUser } from "findem-types";
import Processing from "./common/contexts/Processing";

interface IAppProviders {
  children: ReactNode
}
const appTheme = createTheme(APP_THEME);

const AppProviders = ({ children }: IAppProviders) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>({
    uid: "",
    roles: [] as string[],
  } as IUser);
  const { data: currentUser, isLoading } = useCurrentUser();

  useEffect(() => {
    if (currentUser && Object.hasOwnProperty.call(currentUser, "email")) {
      setUser(currentUser);
    }
  }, [currentUser]);
  return <ThemeProvider theme={appTheme}>
    <AppStyles />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || isProcessing}
        onClick={useDummyFn}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    {user && <UserContext.Provider value={{ user, setUser }}>
      <Processing.Provider value={{ isProcessing, setIsProcessing }}>
        {children}
      </Processing.Provider>
    </UserContext.Provider>}
  </ThemeProvider>;
}

export default AppProviders;