import * as React from 'react';
import { styled } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import './UserSelect.css';
import { IUserList } from "findem-types";
import { userApis } from '../../../common/services/apis';
import { colors } from '../../../common/constants';
// Styled components
export const StyledFormControl = styled(FormControl)((props) => ({
  width: 'fit-content',
  minWidth: 200,
  background: 'white',
  borderRadius: 25,
  '& .MuiInputBase-root': {
    borderRadius: 25,
    border: '1px solid #ced4da',
    // @ts-ignore
    backgroundColor: props.bgColor,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  },
  '& .MuiOutlinedInput-notchedOutline':{
    borderColor: 'transparent',
  },
  '&:hover .MuiOutlinedInput-notchedOutline':{
    borderColor: colors['blue-400'],
  }
}));

const StyledInputBase = styled(TextField)({
  '& .MuiInputBase-input': {
    borderRadius: 25,
    marginLeft: '3px', 
    position: 'relative',
    backgroundColor: 'inherit',
    fontSize: 14,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

interface IOption {
  label: string;
  id: string;
  key: string;
}

interface IUserSelectProps {
  value: string;
  onChangeCB: (value: string) => void;
  variant?: 'outlined' | 'filled' | 'standard';
}

const UserSelect = (props: IUserSelectProps) => {
  const [options, setOptions] = React.useState<IOption[]>([]); 
  const [inputValue, setInputValue] = React.useState("");

  //-----------------------------------------------------------------------------
  // memo
  //-----------------------------------------------------------------------------

  const selectedValue = React.useMemo(() => {
    return options.find((option) => option.id === props.value);
  }, [props.value, options]);
  //-----------------------------------------------------------------------------
  // Hooks
  //-----------------------------------------------------------------------------
  const {data: usersData} = userApis.getAllUsers.useQuery();

  //-----------------------------------------------------------------------------
  // Callbacks
  //-----------------------------------------------------------------------------
  const handleChange = React.useCallback((event: React.SyntheticEvent<Element, Event>, val: IOption | null) => {
    props.onChangeCB(val ? val.id : '');
  },[props]);
  //-----------------------------------------------------------------------------
  // Effects
  //-----------------------------------------------------------------------------
  React.useEffect(() => {
    if (usersData) {
      setOptions(usersData.data.map((user: IUserList) => ({ 
        label: `${user.local.name} ${user.local.company_name ? `(${user.local.company_name})` : ''}`, 
        id: user._id, 
        key: user._id
      })));
    }
  }, [usersData]);

  
  return (<>
    {options && options.length > 0 && <div className="UserSelect" style={{float: "left"}}>
      <StyledFormControl 
        variant={props.variant || 'outlined'}
        // @ts-ignore
        bgColor={!!selectedValue && !props.variant ? colors['blue-100'] : 'white'}
      >
        <Autocomplete
          value={selectedValue || null}
          onChange={handleChange}
          options={options}
          isOptionEqualToValue={(option, value) => option.id === selectedValue?.id}
          getOptionLabel={(option) => option.label}
          filterOptions={(options, state) => {
            let _options = options;
            if (state.inputValue.length > 1) {
              _options = options.filter((item) =>
                String(item.label)
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }
            return _options;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.key}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <StyledInputBase {...params}  variant={props.variant || 'outlined'}/>
          )}
        />
      </StyledFormControl>
    </div>}
  </>);
}

export default UserSelect;
