import CompanyList from "../components/CompanyLists/CompanyLists";
import MapPage from "../components/Map/MapPage";
import PermissionedComponent from "../components/PermissionedComponent";
import DefaultForbiddenComponent from "../components/PermissionedComponent/DefaultForbiddenComponent/DefaultForbiddenComponent";
import DefaultNoAccessComponent from "../components/PermissionedComponent/DefaultNoAccessComponent";
import {Route, Routes} from 'react-router-dom';
import { COMPANY_SIDEBAR_ITEM, CRM_SIDEBAR_ITEM, INSIGHTS_SIDEBAR_ITEM, MAP_SIDEBAR_ITEM, QUERIES_SIDEBAR_ITEM } from "../utils/navigationUtils";
import IframePage from "../components/common/IframeContainer/IframePage";
import PrivateRouteWrapper from "./PrivateRouteWrapper";
import Insights from "../components/Insights/Insights";
import { Role } from "../utils/accessUtils";
import Search from "../components/Search/Search";

const CustomRoutes = (props: any) => {
  return (
    <PrivateRouteWrapper>
      <Routes>
        <Route
          path="*"
          key={'no-access'}
          element={
            <DefaultNoAccessComponent />
          }
        />
        {['/', MAP_SIDEBAR_ITEM.path].map(path => <Route key={path} path={path} element={
            <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
              <MapPage />
            </PermissionedComponent>
          } />)}
        <Route
          path={`/searches/:id`}
          element={
            <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
              <Search />
            </PermissionedComponent>
          }
        />
        <Route
          path={COMPANY_SIDEBAR_ITEM.path}
          element={
            <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
              <CompanyList />
            </PermissionedComponent>
          }
        />
        <Route
          path={INSIGHTS_SIDEBAR_ITEM.path}
          element={
            <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
              <Insights />
            </PermissionedComponent>
          }
        />
        <Route
          path={QUERIES_SIDEBAR_ITEM.path}
          element={
            <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
              {QUERIES_SIDEBAR_ITEM.iframe_route && <IframePage iframeRoute={QUERIES_SIDEBAR_ITEM.iframe_route} />}
            </PermissionedComponent>
          }
        />
        <Route
          path={CRM_SIDEBAR_ITEM.path}
          element={
            <PermissionedComponent permissions={[Role.FM_HM_ACL]} NoAccessComponent={DefaultForbiddenComponent}>
              {CRM_SIDEBAR_ITEM.iframe_route && <IframePage iframeRoute={CRM_SIDEBAR_ITEM.iframe_route} />}
            </PermissionedComponent>
          }
        />
      </Routes>
    </PrivateRouteWrapper>
  );
}

export default CustomRoutes;