import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { IUser } from 'findem-types';
import { matchesUrl } from 'findem-helpers';

export const useCurrentUser = () => {
  const { data, isLoading } = useQuery<IUser, Error, IUser, string[]>({
    queryKey: ['CurrentUser'],
    queryFn: async () => {
      const { data } = await axios.get(`${matchesUrl}/api/user_info`, { withCredentials: true });
      return data;
    },
  });

  return { data, isLoading };
}