import { Divider } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface IPageHeaderProps {
  title: string;
  description?: string | JSX.Element;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ title, description }) => {
  return (
    <ExecutiveSearchWrapper>
      <ExecutiveSearchTitle>{title}</ExecutiveSearchTitle>
      {description && <ExecutiveSearchDescription>
        {description}
      </ExecutiveSearchDescription>}
    </ExecutiveSearchWrapper>
  );
};

export default PageHeader;

const ExecutiveSearchWrapper = styled.section`
  align-items: start;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  // padding: 8px 16px 0;
  width: 100%;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const ExecutiveSearchTitle = styled.h2`
  color: var(--Layout-V2-Title-Text, #101828);
  font: 500 24px Roboto, sans-serif;
  text-align: center;
  margin: 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ExecutiveSearchDescription = styled.p`
  color: var(--Layout-V2-Tertiary-Text, #667085);
  font: 12px/14.4px Roboto, sans-serif;
  margin-top: 12px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
