import React, { Suspense } from 'react';
import UserContext from '../../common/contexts/UserContext';
import { IUser } from 'findem-types';
import { CircularProgress } from '@mui/material';


const DefaultNoAccessComponent = React.lazy(
  () => import('./DefaultNoAccessComponent')
);

interface IProps {
  permissions?: string[];
  NoAccessComponent?: React.FC | null | 'default';
  children?: React.ReactNode;
}

// renders children if the supplied acl permissions are included the user role.
// if the permissions are missing, it renders NoAccessComponent instead.
const PermissionedComponent: React.FC<IProps> = ({
  permissions = [],
  NoAccessComponent,
  children
}) => {
  const {user} = React.useContext(UserContext);
  return permissions.reduce(
    (acc, permission) => (user.roles.includes(permission) ? true : acc),
    false
  ) ? (
    <>{children}</>
  ) : (
    <>
      {NoAccessComponent === 'default' ? (
        <Suspense fallback={<CircularProgress />} key="default-no-access">
          <DefaultNoAccessComponent />
        </Suspense>
      ) : NoAccessComponent ? (
        <NoAccessComponent />
      ) : null}
    </>
  );
};

export default PermissionedComponent;
