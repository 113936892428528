import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { IEmbedMode, IEmbedModeType } from './common/types/embed';
import { parseEmbedProps } from './common/utils/embedUtils';

const scriptTag: HTMLOrSVGScriptElement | null = document.currentScript;
const scriptProps: DOMStringMap = scriptTag?.dataset ?? {};
const embedModeType: string | undefined = scriptProps["embedModeType"]
const embedProps: string | undefined = scriptProps["embedProps"];

let embedMode: IEmbedMode | undefined;
console.log('scriptTag', embedModeType, scriptTag);
if (embedModeType) {
  embedMode = parseEmbedProps(embedModeType as IEmbedModeType, embedProps);
}

const root = ReactDOM.createRoot(
  document.getElementById('mapapp-root') as Element
);
root.render(<App embedMode={embedMode} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
