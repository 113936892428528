import * as React from 'react';
import { styled } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import '../../components/common/UserSelect/UserSelect.css';
import { mapApis } from '../../common/services/apis';
import { IMap } from '../../common/types/mapTypes';
import UserContext from '../../common/contexts/UserContext';
// Styled components
export const StyledFormControl = styled(FormControl)({
  width: 'fit-content',
  minWidth: 200,
  background: 'white',
  borderRadius: 25,
  '& .MuiInputBase-root': {
    borderRadius: 25,
    backgroundColor: 'white',
    border: '1px solid #ced4da',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  },
});

const StyledInputBase = styled(TextField)({
  '& .MuiInputBase-input': {
    borderRadius: 25,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    fontSize: 14,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

interface IOption {
  label: string;
  id: string;
  key: string;
}

interface IMapsSelectProps {
  value: string;
  selectedUser: string;
  onChangeCB: (value: string | undefined) => void;
  setSearchLink: (value: string | undefined) => void;
}

const MapsSelect = (props: IMapsSelectProps) => {
  const [options, setOptions] = React.useState<IOption[]>([]); 
  const {user} = React.useContext(UserContext);

  // APIs
  const { 
    data: mapsData, 
    isFetching: mapDataFetching, 
    isLoading: mapDataLoading,
    refetch: refetchMaps
  } = mapApis.getAllMaps.useQuery({ variables: { id: props.selectedUser || user.uid } });

  //-----------------------------------------------------------------------------
  // memo
  //-----------------------------------------------------------------------------
  const selectedValue = React.useMemo(() => {
    return options.find((option) => option.id === props.value);
  }, [props.value, options]);

  const searchLink = React.useMemo(() => {
    // get from Map searchlink
    if(props.value && mapsData?.data){
      return mapsData.data.find((map: IMap) => map.ref === props.value)?.search_link;
    }
    return undefined;
  }, [props.value, mapsData]);
  //-----------------------------------------------------------------------------
  // Hooks
  //-----------------------------------------------------------------------------

  //-----------------------------------------------------------------------------
  // Callbacks
  //-----------------------------------------------------------------------------
  const handleChange = React.useCallback((event: React.SyntheticEvent<Element, Event>, val: IOption | null) => {
    const _map = (mapsData && val) ? mapsData.data.find((map: IMap) => map.ref === val.id) : undefined;
    props.onChangeCB(_map ? _map.ref : undefined);
  },[mapsData, props]);
  //-----------------------------------------------------------------------------
  // Effects
  //-----------------------------------------------------------------------------
  React.useEffect(() => {
    if (mapsData) {
      setOptions(mapsData.data.map((map: IMap) => ({ 
        label: map.name, 
        id: map.ref, 
        key: map.ref
      })));
    }
  }, [mapsData]);

  React.useEffect(() => {
    props.setSearchLink(searchLink);
  }, [props, searchLink]);

  
  return (
    <div className="UserSelect" style={{float: "left"}}>
      <StyledFormControl variant="outlined">
        <Autocomplete
          value={selectedValue || null}
          onChange={handleChange}
          loading={mapDataFetching || mapDataLoading}
          options={options}
          isOptionEqualToValue={(option, value) => option.id === selectedValue?.id}
          getOptionLabel={(option) => option.label}
          
          filterOptions={(options, state) => {
            let _options = options;
            if (state.inputValue.length > 1) {
              _options = options.filter((item) =>
                String(item.label)
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }
            return _options;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.key}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <StyledInputBase {...params} variant="outlined" placeholder='Select Map' />
          )}
        />
      </StyledFormControl>
    </div>
  );
}

export default MapsSelect;
