export const DEFAULT_MAP_RULES = [{
  "rules": [
    {
      "field": "Job Title",
      "operator": "Current Experience",
      "value": [
      {
        "label": "VP Sales",
        "title": "VP Sales",
        "value": "VP Sales",
        "key": "VP Sales",
        "raw_value": "VP Sales"
      }
      ],
    },
    "and",
    {
      "field": "Company Lists",
      "operator": "Current Experience",
      "value":[
        {
          "label": "Fortune 500 Global Companies",
          "title": "Fortune 500 Global Companies",
          "value": "602e737df11096f3626b3e55",
          "raw_value": "602e737df11096f3626b3e55",
          "type": "macro"
        }
      ]
    },
    "and",
    {
      "field": "Company Business Model",
      "operator": "Current Experience",
      "value": []
    },
    "and",
    {
      "field": "Company Stage",
      "operator": "Current Experience",
      "value": []
    }
  ],
  "combinators": {
    "1": "AND",
    "3": "AND",
    "5": "AND"
  }
}];