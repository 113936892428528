import React from 'react';
import { IEdgarFinancialInfo, IFinancialInfoUnit } from '../../../common/types/companyDetails';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { colors } from '../../../common/constants';
import { decodeString, humanizeCount } from 'findem-helpers';
import { Area, CartesianGrid, ComposedChart, Line, XAxis, YAxis, Tooltip, Legend} from 'recharts';
import { TooltipContain } from './CompanySizeGraph';
import { BUMP_INT, CHART_WIDTH } from './CompanyDetails';

interface ICompanyEdgarGraphProps {
  companyEdgarData: IEdgarFinancialInfo;
}

interface IFinChartData {
  name: string;
  revenue: number | null;
  netProfits: number | null;
  assets: number | null;
  grossProfits: number | null;
  totalCapitalization: number | null;
  currency_unit: string;
  bar: number | undefined | null;
}

interface IFinChartData1 {
  name: string;
  ebidta: number | null;
  currency_unit: string;
  bar: number | undefined | null;
}

interface IFinChartData2 {
  name: string;
  basicEps: number | null;
  currency_unit: string;
  bar: number | undefined | null;
}

const CompanyEdgarGraph: React.FC<ICompanyEdgarGraphProps> = ({ companyEdgarData }) => {
  const BAR_KEY = React.useMemo(
    () =>
      `Candidate's Tenure`,
    []
  );

  const years: number[] = React.useMemo(
    () =>
      companyEdgarData.financial_info_annually.revenues.map((revenue: IFinancialInfoUnit) => revenue.year),
    [companyEdgarData]
  );

  const data: {units: IFinChartData[], units1: IFinChartData1[], units2: IFinChartData2[]} = React.useMemo( () => {
    const units: IFinChartData[] = [];
    const units1: IFinChartData1[] = [];
    const units2: IFinChartData2[] = [];
    years.forEach((year: number) => {
      const revenue: IFinancialInfoUnit | undefined = companyEdgarData.financial_info_annually.revenues.find((revenue: IFinancialInfoUnit) => revenue.year === year);
      const netProfits: IFinancialInfoUnit | undefined = companyEdgarData.financial_info_annually.net_profits.find((netProfit: IFinancialInfoUnit) => netProfit.year === year);
      const assets: IFinancialInfoUnit | undefined = companyEdgarData.financial_info_annually.assets.find((asset: IFinancialInfoUnit) => asset.year === year);
      const grossProfits: IFinancialInfoUnit | undefined = companyEdgarData.financial_info_annually.gross_profits.find((grossProfit: IFinancialInfoUnit) => grossProfit.year === year);
      const ebidta: IFinancialInfoUnit | undefined = companyEdgarData.financial_info_annually.ebidta.find((ebidta: IFinancialInfoUnit) => ebidta.year === year);
      const basicEps: IFinancialInfoUnit | undefined = companyEdgarData.financial_info_annually.basic_eps.find((basicEps: IFinancialInfoUnit) => basicEps.year === year);
      const totalCapitalization: IFinancialInfoUnit | undefined = companyEdgarData.financial_info_annually.total_capitalization.find((totalCapitalization: IFinancialInfoUnit) => totalCapitalization.year === year);
      const bar = Math.max(
        revenue?.value || 0,
        netProfits?.value || 0,
        assets?.value || 0,
        grossProfits?.value || 0,
        totalCapitalization?.value || 0
      );
      const unit: IFinChartData = {
        name: `${decodeString(companyEdgarData.company_name)} (${year.toString()})`,
        revenue: revenue ? revenue.value : null,
        netProfits: netProfits ? netProfits.value : null,
        assets: assets ? assets.value : null,
        grossProfits: grossProfits ? grossProfits.value : null,
        totalCapitalization: totalCapitalization ? totalCapitalization.value : null,
        currency_unit: companyEdgarData.financial_info_annually.currency_unit || 'USD',
        bar: bar
      };
      const unit1: IFinChartData1 = {
        name: `${decodeString(companyEdgarData.company_name)} (${year.toString()})`,
        ebidta: ebidta ? ebidta.value : null,
        currency_unit: companyEdgarData.financial_info_annually.currency_unit || 'USD',
        bar: ebidta?.value
      };
      const unit2: IFinChartData2 = {
        name: `${decodeString(companyEdgarData.company_name)} (${year.toString()})`,
        basicEps: basicEps ? basicEps.value : null,
        currency_unit: companyEdgarData.financial_info_annually.currency_unit || 'USD',
        bar: basicEps?.value
      };
      units.push(unit);
      units1.push(unit1);
      units2.push(unit2);
    });
    return {units, units1, units2 };
  }, [years, companyEdgarData.financial_info_annually.revenues, companyEdgarData.financial_info_annually.net_profits, companyEdgarData.financial_info_annually.assets, companyEdgarData.financial_info_annually.gross_profits, companyEdgarData.financial_info_annually.ebidta, companyEdgarData.financial_info_annually.basic_eps, companyEdgarData.financial_info_annually.total_capitalization, companyEdgarData.financial_info_annually.currency_unit, companyEdgarData.company_name]);

  const isUnitsEmpty = React.useMemo(
    () =>
      data.units.every(
        (datum) =>
          datum.revenue === null &&
          datum.netProfits === null &&
          datum.assets === null &&
          datum.grossProfits === null &&
          datum.totalCapitalization === null
      ),
    [data.units]
  );
  const isUnits1Empty = React.useMemo(
    () => data.units1.every((datum) => datum.ebidta === null),
    [data.units1]
  );

  const isUnits2Empty = React.useMemo(
    () => data.units2.every((datum) => datum.basicEps === null),
    [data.units2]
  );


  const CustomEdgarTooltip = ({
    active,
    payload
  }: {
    active: boolean;
    payload: Array<{ value: number; payload: { name: string; revenue: number,
      netProfits: number, assets: number, grossProfits: number, ebidta: number,
      basicEps: number, totalCapitalization: number, currency_unit: string
    } }>;
  }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContain>
          <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant='h4'>{payload[0]?.payload?.name}</Typography>
          {payload[0]?.payload?.revenue && <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            Revenue: {humanizeCount(payload[0]?.payload?.revenue)} {payload[0]?.payload?.currency_unit}
          </Typography>}
          {payload[0]?.payload?.netProfits && <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            Net profit: {humanizeCount(payload[0]?.payload?.netProfits)} {payload[0]?.payload?.currency_unit}
          </Typography>}
          {payload[0]?.payload?.assets && <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            Assets: {humanizeCount(payload[0]?.payload?.assets)} {payload[0]?.payload?.currency_unit}
          </Typography>}
          {payload[0]?.payload?.grossProfits && <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            Gross profits: {humanizeCount(payload[0]?.payload?.grossProfits)} {payload[0]?.payload?.currency_unit}
          </Typography>}
          {payload[0]?.payload?.ebidta && <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            EBITDA: {humanizeCount(payload[0]?.payload?.ebidta)} {payload[0]?.payload?.currency_unit}
          </Typography>}
          {payload[0]?.payload?.basicEps && <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            Basic Eps: {humanizeCount(payload[0]?.payload?.basicEps)} {payload[0]?.payload?.currency_unit}
          </Typography>}
          {payload[0]?.payload?.totalCapitalization && <Typography sx={{fontFamily: 'Roboto, sans-serif'}} variant="body2">
            Total capitalization: {humanizeCount(payload[0]?.payload?.totalCapitalization)} {payload[0]?.payload?.currency_unit}
          </Typography>}
        </TooltipContain>
      );
    }
  
    return null;
  };

  return (
    <EdgarGraphWrapper>
      {(!isUnitsEmpty || !isUnits1Empty || !isUnits2Empty) && (
        <Typography 
          variant="h5"
          sx={{ fontSize: '14px', color: colors['grey-500'], margin: '8px 0px', fontWeight: 400, fontStretch: 'condensed', textTransform: 'uppercase'}}
          >
          Company Financial Info
        </Typography>
      )}
      {!isUnitsEmpty && (
        <ComposedChart
        width={CHART_WIDTH}
        height={225}
        data={data.units}
        margin={{ top: 10, right: 15, bottom: 0, left: 0 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          interval="preserveStartEnd"
          // minTickGap={1}
          tickLine={false}
          tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
          dataKey="name"
        />
        <YAxis
          tickLine={false}
          tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
          tickFormatter={
            // number | string is okay
            humanizeCount as any
          }
        />
        <defs>
          <linearGradient id="colorTenure" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgb(117, 181, 255)" stopOpacity={0.6} />
            <stop
              offset="100%"
              stopColor="rgb(117, 181, 255)"
              stopOpacity={0.1}
            />
          </linearGradient>
        </defs>
        {/* @ts-ignore */}
        <Tooltip content={<CustomEdgarTooltip />} />
        <Area
          type={BUMP_INT}
          dataKey="bar"
          stroke="transparent"
          fillOpacity={1}
          fill="url(#colorTenure)"
        />
        <Line
          type={BUMP_INT}
          dot={false}
          strokeWidth="2"
          dataKey="revenue"
          stroke={colors['blue-300']}
          connectNulls
        />
        <Line
          type={BUMP_INT}
          dot={false}
          strokeWidth="2"
          dataKey="netProfits"
          stroke={colors['green-300']}
          connectNulls
        />
        <Line
          type={BUMP_INT}
          dot={false}
          strokeWidth="2"
          dataKey="assets"
          stroke={colors['red-300']}
          connectNulls
        />
        <Line
          type={BUMP_INT}
          dot={false}
          strokeWidth="2"
          dataKey="grossProfits"
          stroke={colors['grey-400']}
          connectNulls
        />
        <Line
          type={BUMP_INT}
          dot={false}
          strokeWidth="2"
          dataKey="totalCapitalization"
          stroke={colors['yellow-400']}
          connectNulls
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          style={{
            fontFamily: 'Roboto, sans-serif'
          }}
          payload={[
            {
              id: 'square',
              type: 'square',
              value: BAR_KEY,
              color: colors['blue-200']
            },
            {
              id: 'revenue',
              type: 'line',
              value: 'Revenue',
              color: colors['blue-300']
            },
            {
              id: 'netProfits',
              type: 'line',
              value: 'Net profits',
              color: colors['green-300']
            },
            {
              id: 'assets',
              type: 'line',
              value: 'Assets',
              color: colors['red-300']
            },
            {
              id: 'grossProfits',
              type: 'line',
              value: 'Gross profits',
              color: colors['grey-400']
            },
            {
              id: 'totalCapitalization',
              type: 'line',
              value: 'Total capitalization',
              color: colors['yellow-400']
            }
          ]}
          formatter={(value) => (
            <span style={{ color: colors['grey-500'], fontFamily: 'Roboto, sans-serif' }}>{value}</span>
          )}
        />
        </ComposedChart>
      )}
      {/* add gap */}
      <div style={{ height: 20 }} />
      {/* chart 2 */}
      {!isUnits1Empty && (
        <ComposedChart
        width={CHART_WIDTH}
        height={225}
        data={data.units1}
        margin={{ top: 10, right: 15, bottom: 0, left: 0 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          interval="preserveStartEnd"
          // minTickGap={1}
          tickLine={false}
          tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
          dataKey="name"
        />
        <YAxis
          tickLine={false}
          tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
          tickFormatter={
            // number | string is okay
            humanizeCount as any
          }
        />
        <defs>
          <linearGradient id="colorTenure" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgb(117, 181, 255)" stopOpacity={0.6} />
            <stop
              offset="100%"
              stopColor="rgb(117, 181, 255)"
              stopOpacity={0.1}
            />
          </linearGradient>
        </defs>
        {/* @ts-ignore */}
        <Tooltip content={<CustomEdgarTooltip />} />
        <Area
          type={BUMP_INT}
          dataKey="bar"
          stroke="transparent"
          fillOpacity={1}
          fill="url(#colorTenure)"
        />
        <Line
          type={BUMP_INT}
          dot={false}
          strokeWidth="2"
          dataKey="ebidta"
          stroke={colors['yellow-200']}
          connectNulls
        />
        <Legend
        style={{
          fontFamily: 'Roboto, sans-serif'
        }}
          align="center"
          verticalAlign="bottom"
          payload={[
            {
              id: 'square',
              type: 'square',
              value: BAR_KEY,
              color: colors['blue-200']
            },
            {
              id: 'ebidta',
              type: 'line',
              value: 'EBITDA',
              color: colors['yellow-200']
            }
          ]}
          formatter={(value) => (
            <span style={{ color: colors['grey-500'], fontFamily: 'Roboto, sans-serif' }}>{value}</span>
          )}
        />
        </ComposedChart>
      )}
      {/* add gap */}
      <div style={{ height: 20 }} />
      {/* chart 3 */}
      {!isUnits2Empty && (
        <ComposedChart
        width={CHART_WIDTH}
        height={225}
        data={data.units2}
        margin={{ top: 10, right: 15, bottom: 0, left: 0 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          interval="preserveStartEnd"
          // minTickGap={1}
          tickLine={false}
          tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
          dataKey="name"
        />
        <YAxis
          tickLine={false}
          tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
          tickFormatter={
            // number | string is okay
            humanizeCount as any
          }
        />
        <defs>
          <linearGradient id="colorTenure" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgb(117, 181, 255)" stopOpacity={0.6} />
            <stop
              offset="100%"
              stopColor="rgb(117, 181, 255)"
              stopOpacity={0.1}
            />
          </linearGradient>
        </defs>
        {/* @ts-ignore */}
        <Tooltip content={<CustomEdgarTooltip />} />
        <Area
          type={BUMP_INT}
          dataKey="bar"
          stroke="transparent"
          fillOpacity={1}
          fill="url(#colorTenure)"
        />
        <Line
          type={BUMP_INT}
          dot={false}
          strokeWidth="2"
          dataKey="basicEps"
          stroke={colors['purple-200']}
          connectNulls
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          style={{
            fontFamily: 'Roboto, sans-serif',
          }}
          payload={[
            {
              id: 'square',
              type: 'square',
              value: BAR_KEY,
              color: colors['blue-200']
            },
            {
              id: 'basicEps',
              type: 'line',
              value: 'Basic eps',
              color: colors['purple-200']
            }
          ]}
          formatter={(value: any) => (
            <span style={{ color: colors['grey-500'], fontFamily: 'Roboto, sans-serif' }}>{value}</span>
          )}
        />
        </ComposedChart>
      )}
    </EdgarGraphWrapper>
  )
};

export default CompanyEdgarGraph;

const EdgarGraphWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;