export enum CompanySearchableFields {
  COMPANY_NAME= "company_name",
  ATTRIBUTES_NAME = "attributes.attribute_name",
  HQ_CITY = "hq_location.city",
  HQ_STATE = "hq_location.state",
  HQ_COUNTRY = "hq_location.country",
  PARENT_COMPANIES = "parent_companies.company_name",
  SUBSIDERIES = "subsidiaries.company_name",
  OTHER_NAMES = "other_names",
  LAST_KNOWN_STATE = "last_known_state",
  ORG_TYPE = "org_type",
  INDUSTRY_CATEGORIES = "industry.categories",
  INDUSTRY_SUB_CATEGORIES = "industry.sub_categories",
  INDUSTRY_CRUNCHBASE_CATEGORIES = "industry.crunchbase_categories",
  INDUSTRY_CRUNCHBASE_CATEGORY_GROUPS = "industry.crunchbase_category_groups",
  COMPETITORS_NAME = "competitors.name",
  COMPETITORS_PRODUCT_NAME = "competitors.product_name",
  PRODUCTS_PRODUCT_NAME = "products.product_name",
  PRODUCTS_PRODUCT_CATEGORIES = "products.product_categories",
  PRODUCTS_PRODUCT_COMPETITORS_NAME = "products.product_competitors.name",
  PRODUCTS_PRODUCT_COMPETITORS_PRODUCT_NAME = "products.product_competitors.product_name",
  BIZ_MODELS = "biz_models",
  ACQUISITION_INFO_ACQUISITION_COMPANY_NAME = "acquisition_info.acquisition_company_name",
  EXIT_INFO_ACQUIRER_ENTITY_NAME = "exit_info.acquirer_entity_name",
  PB_DATA_OWNERSHIP_STATUS = "pb_data.ownership_status",
  PB_DATA_ACQUIRER = "pb_data.acquirer",
  PB_DATA_FINANCING_STATUS = "pb_data.financing_status",
  PB_DATA_ORG_TYPE_STATUS = "pb_data.org_type_status",
}