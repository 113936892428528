import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from 'react';
import styled from "styled-components";
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "react-edit-text/dist/index.css";
import BarChartIcon from '@mui/icons-material/BarChart';
import { fetchCompanyListsFilter, fetchTitles, fetchIndustriesFilter, fetchCompanies, fetchMacrosByTaxonomy } from './Apis'
import { Button, DialogContent, DialogTitle, Box, Breadcrumbs, Link, Chip } from '@mui/material';
import { DEFAULT_MAP_RULES } from './common/constants/DefaultMapRules';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "./common/constants"
import { EditText } from 'react-edit-text';
import EditIcon from '@mui/icons-material/Edit';
import { useUrlParams } from 'findem-helpers';
import UserContext from './common/contexts/UserContext';
import { SaveMap } from './Apis';
import EastIcon from '@mui/icons-material/East';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const TENURE = [
  { label: "current", value: "Current Experience" },
  { label: "recent", value: "Last 3 years" },
];
const JOB_TITLES = [
  { label: "VP Sales", value: "vp-sales" },
  { label: "VP Finance", value: "vp-finance" },
  { label: "VP Marketing", value: "vp-marketing" },
];
const COMPANYSEL = [
  { label: 'Companies', value: 'Company Lists' },
  { label: 'Industry', value: 'Industry' },
]
const NAMES = [
  {
    "label": "Fortune 500 Global Companies",
    "title": "Fortune 500 Global Companies",
    "value": "602e737df11096f3626b3e55",
    "raw_value": "602e737df11096f3626b3e55",
    "type": "macro"
  }
];

const CustomAutocomplete = styled(Autocomplete)({
  [`& .${autocompleteClasses.inputRoot}`]: {
    color: 'inherit',
    fontSize: 'inherit', // Use the font size from the parent
    padding: '0px !important', // Reset padding
    '&[class*="MuiOutlinedInput-root"]': {
      padding: '0px !important',
    },
    [`& .${autocompleteClasses.input}`]: {
      minWidth: '0', // Make input only as wide as its content
      padding: '8px 0px', // Vertical padding to align with other text, adjust as needed
      '&::placeholder': {
        fontSize: '16px', // Ensures placeholder text matches size
        color: colors['grey-700'], // Ensures placeholder text matches color
        opacity: 1, // Ensures placeholder text is visible
      },
    },
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    top: 'calc(50% - 12px)', // Vertically center the dropdown icon
    right: '0px', // Align the icon to the right
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      textDecoration: 'underline', // Underline the text
      padding: '8px 0 !important', // Align text vertically
      // width: 'auto !important', // Allow the text to be as wide as needed
    },
    '& fieldset': {
      border: 'none', // Remove border around the TextField
    },
    '&:hover fieldset': {
      border: 'none', // Remove border on hover
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Remove border on focus
    },
  },
});

const TextWidthMeasure = styled.div`
  visibility: hidden;
  white-space: nowrap;
  position: absolute;
  pointer-events: none;
`;

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "inherit",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  "&:hover": {
    textDecoration: "underline",
  },
  // Apply any additional styles if needed
}));

const SelectionButton = ({ fn, opts, defaultValue, ruleKey, disableClearable, position, cb, selectedValue, id, isMultiSelect }) => {
  const [inputWidth, setInputWidth] = useState(null); // State to hold the input width
  const [value, setValue] = useState(isMultiSelect ? [defaultValue] : defaultValue); // State to hold the current value
  const textWidthMeasureRef = useRef();
  const [options, setOptions] = useState(opts);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = useState(false);
  let inputRef = useRef(null);

  // This useEffect will calculate the width of the autocomplete based on its value's label
  useEffect(() => {
    // Function to calculate the width of the text
    const calculateTextWidth = (text, font = '16px Roboto') => {
      // Create a span element to measure the text width in the DOM
      if (textWidthMeasureRef.current) {
        const width = textWidthMeasureRef.current.offsetWidth;
        return width;
      }
      return 60;
    };

    // Calculate the width of the selected option label
    let labelWidth = 0;
    if (value && value.label) {
      labelWidth = calculateTextWidth(value.label)
    }
    // Add some extra width for padding and the dropdown arrow
    const totalWidth = (labelWidth ? labelWidth : 20) + 10; // Adjust 30 if more space is needed
    // console.log(JSON.stringify(defaultValue) + ' ' + totalWidth);
    setInputWidth(totalWidth < 60 ? 60 : totalWidth);
  }, [value, inputValue]);

  // Custom render function for the input component
  const renderInput = params => (
    <StyledTextField
      ref={input => {
        inputRef = input;
      }}
      {...params}
      variant="standard"
      sx={{ 
        '&:after': {
          borderBottom: 'none',
        }
      }}
      InputProps={{
        ...params.InputProps,
        style: {
          ...params.InputProps.style,
          width: isMultiSelect ? 'fit-content' : inputWidth + 'px', // Apply the calculated width
          fontWeight: '400 !important',
        },
        endAdornment: (
          <>
            {params.InputProps.endAdornment}
            <ArrowDropDownIcon onClick={(e) => {
              inputRef.focus();
              setOpen(true);
            }}/>
          </>
        ),
      }}
    />
  );
  const renderInputRO = (params) => (
    <StyledTextField
      ref={input => {
        inputRef = input;
      }}
      {...params}
      variant="standard"
      sx={{ 
        '&:after': {
          borderBottom: 'none',
        }
      }}
      placeholder='any'
      InputProps={{
        ...params.InputProps,
        readOnly: true, // Prevent typing by making the input read-only
        style: {
          ...params.InputProps.style,
          width: inputWidth + 'px', // Apply the calculated width
          fontWeight: '400 !important',
        },
        endAdornment: (
          <>
            {params.InputProps.endAdornment}
            <ArrowDropDownIcon onClick={(e) => {
              inputRef.focus();
                setOpen(true);
              }}/>
          </>
        )
      }}
    />
  );
  
  useEffect(() => {
    if (!fn) {
      return;
    }
    let operator = "Last 3 years"
    if (id.indexOf("Current Experience") >= 0) {
      operator = "Current Experience";
    }
    setLoading(true);
    let active = true;
    (async () => {
      const fetchedOptions = await fn(inputValue, operator);
      if (active) {
        setLoading(false);
        setOptions(fetchedOptions);
      }
    })();

    return () => {
      active = false;
    };
  }, [fn, id, inputValue]);

  useEffect(() => {
    if (opts) {
      setOptions([...opts, selectedValue])
      setOptions([...opts])
    }
  }, [opts, selectedValue]);
  const isGrouped = options?.some(option => option?.options);

  if (!fn) {
    return (
      <>
        <TextWidthMeasure ref={textWidthMeasureRef}>
          {value && value.label ? value.label : ""}
        </TextWidthMeasure>
        <CustomAutocomplete
          key={id}
          value={value}
          options={options}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}       
          autoSelect
          disableClearable={true}
          renderInput={renderInputRO}
          componentsProps={{ popper: { style: { width: 'fit-content' } } }}
          getOptionLabel={(option) => { return (option.label ? option.label : option.title) }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            setValue(newValue);
            cb(ruleKey, newValue, position);
          }}
          style={{ width: 'auto', fontSize: '16px', fontWeight: '400' }} // Use calculated width or 'auto'
          popupIcon={null} // Remove the default dropdown arrow
          sx={{
            '& .MuiChip-root': {
              backgroundColor: 'transparent',
              fontSize: '16px',
            },
            '& .MuiInput-root': {
              '&::after': {
                borderBottom: 'none',
              },
            },
            '& .MuiInputBase-input': {
              minWidth: '0px',
            },
            '& .MuiInput-input':{
              minWidth: '0px !important',
              padding: '0px !important',
            }
          }}
        />
      </>
    );
  }

  const MyChip = (props) => {
  
    return (
        <Chip
          onClick={(e) => {
            inputRef.focus();
            setOpen(true);
          }}
          sx={{
            maxWidth: '120px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          {...props}
        />
    );
  };

  return (
    <>
      <TextWidthMeasure ref={textWidthMeasureRef}>
        {value && value.label ? value.label : ""}
      </TextWidthMeasure>
      <CustomAutocomplete
        multiple={isMultiSelect ? true : false}
        disableClearable={true}
        value={value}
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
        options={isGrouped ? options.flatMap(group => group.options) : options} // Flattening for rendering individual options
        groupBy={isGrouped ? (option) => {
          // Find the group label that this option belongs to
          const group = options.find(g => g.options.includes(option));
          return group ? group.label : '';
        } : undefined}
        getOptionLabel={(option) => { return (option.label ? option.label : option.title) }}
        loading={loading}
        inputValue={inputValue}
        renderInput={renderInput}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <MyChip {...getTagProps({ index })} label={option.label ? option.label : option.title} />
          ))
        }
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
          // if isMultiSelect Filter out the 'any' option from the selected values and has multiple options
          if(isMultiSelect && newValue.length > 1) {
            newValue = newValue.filter((opt) => opt.value !== 'any');
          }
          setValue(newValue);
          cb(ruleKey, newValue, position);
        }}
        style={{ width: 'auto', fontSize: '16px', fontWeight: '400' }} // Use calculated width or 'auto'
        popupIcon={null} // Remove the default dropdown arrow
        sx={{
          '& .MuiChip-root': {
            backgroundColor: 'transparent',
            fontSize: '16px',
          },
          '& .MuiInput-root': {
            '&::after': {
              borderBottom: 'none',
            },
          },
          '& .MuiInputBase-input': {
            minWidth: '0px',
          },
          '& .MuiInput-input':{
            minWidth: isMultiSelect && value.length === 0 ? '30px !important' : '0px !important',
            padding: '0px !important',
          }
        }}
      />
    </>
  );
};

const AdLibComponent = (props) => {
  const { joid } = useUrlParams();

  const [rules, setRules] = useState(JSON.parse(JSON.stringify(DEFAULT_MAP_RULES)));
  const [mapName, setMapName] = useState(props.mapName);
  const [showMoreOptions, setShowMoreOptions] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useContext(UserContext);

  const mapFirstConditionRules = useMemo(() => {
    return rules[0].rules;
  }, [rules]);
  const handleShowMoreClick = () => {
    setShowMoreOptions(true);
  };

  const handleNameSave = ({
    name,
    value,
    previousValue,
  }) => {
    setMapName(value);
  };

  const onOptionSelect = useCallback((ruleKey, value, position, resetValue = false) => {
    setRules((prevRules) => {
      const newRules = [...prevRules];
      const _rules = newRules[0].rules;
      _rules[position][ruleKey] = ruleKey === 'value' ? value.constructor === Array ? value : [value] : value.value;
      if (resetValue) {
        _rules[position]['value'] = [];
      }
      console.log('newRules', newRules);
      return newRules;
    });
  }, []);

  const onOptionSelectResetValue = useCallback((ruleKey, value, position) => {
    onOptionSelect(ruleKey, value, position, true);
  }, [onOptionSelect]);

  const fetchOptionsByFieldAndRegency = useCallback((field, regency) => {
    if (field && regency) {
      const taxonomy = regency === 'Current Experience' ? ';Current' : ';Recent';
      return fetchMacrosByTaxonomy('', `${field}${taxonomy}`);
    }
    return [];
  }, []);

  const fetchFnByField = useCallback((field) => {
    switch (field) {
      case 'Company Lists':
        return fetchCompanyListsFilter;
      case 'Industry':
        return fetchIndustriesFilter;
      default:
        return fetchCompanies;
    }
  }, []);

  const saveMap = useCallback(async (redirectTo) => {
    // allQueries
    // map name - name
    setIsSubmitting(true);
    const rulesClone = JSON.parse(JSON.stringify(rules));
    const _allQueriesFiltered = rulesClone.filter(
      (q) => q.rules && q.rules.length > 0
    );
    // check for empty rule values
    if (_allQueriesFiltered.length === 0) {
      setIsSubmitting(false);
      return;
    }
    const _rules = _allQueriesFiltered[0].rules;
    if ((!_rules[0].value || !_rules[0].value.length === 0) &&
      (!_rules[2].value || !_rules[2].value.length === 0) &&
      (!_rules[4].value || !_rules[4].value.length === 0) &&
      (!_rules[6].value || !_rules[6].value.length === 0)
    ) {
      setIsSubmitting(false);
      return;
    }
    // rules remove the rule having empty value
    // _allQueriesFiltered[0].rules = _rules.filter(rule => (rule.value && rule.value.length > 0) || (rule === 'and' || rule === 'or'));
    let res = await SaveMap("", mapName, "", _allQueriesFiltered, joid || user.uid).then(async (res) => {
      await props.saveCb(res, true, true, redirectTo);
      props.setAdLibPopoverOpen(false);
      setIsSubmitting(false);
    });
  }, [rules, mapName, joid, user, props]);

  const saveAndGoToResults = useCallback(async () => {
    saveMap('results');
  }, [saveMap]);

  const saveAndGoToInsights = useCallback(async () => {
    saveMap('insights');
    props.setAdLibPopoverOpen(false)
  }, [saveMap]);

  const fetchTitlesByOperator = useCallback(async (inputValue) => {
    const operator = mapFirstConditionRules[0]['operator'];
    const _regency = operator === 'Current Experience' ? ';Current'
      : operator === 'Last 3 years' ? ';Recent'
        : ';Past or Current';
    return fetchTitles(inputValue, _regency, false);
  }, [mapFirstConditionRules]);

  return (<>
    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Breadcrumb-like navigation */}
        <Breadcrumbs
          aria-label="breadcrumb"
        >
          <StyledLink href={"/maps"} underline="hover">
            <ArrowBackIcon
              fontSize="small"
              sx={{ verticalAlign: "middle", mr: 0.5 }}
            />
            {"Executive Mapping"}
          </StyledLink>
          <Box
            sx={{
              display: "flex",
              '&:hover': {
                backgroundColor: colors['grey-100'],
                '.map-edit-icon': {
                  display: 'block !important',
                }
              }
            }}
          >
            <EditText
              style={{
                position: "relative",
                padding: '0 2px',
                margin: 0,
                lineHeight: "2em",
                borderRadius: "4px",
              }}
              name="textbox1"
              onSave={handleNameSave}
              defaultValue={mapName ? mapName : "New Mapping"}
              showEditButton={true}
              editButtonContent={<EditIcon className="map-edit-icon" sx={{
                fontSize: "14px",
                color: colors['grey-600'],
                cursor: "pointer",
                display: "none",
              }} />}
            />
          </Box>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              color: "grey",
              fontSize: "small",
              fontWeight: "normal",
            }}
            onClick={() => { props.setAdLibPopoverOpen(false); props.handleClickOpen(null, rules); }} // You need to define this function to handle click events
          >
            Advanced View
          </Button>
          <IconButton
            aria-label="close"
            onClick={() => { props.setAdLibPopoverOpen(false) }}
            sx={{
              position: 'relative',
              right: 8,
              top: 0,
              color: colors['grey-600'],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
    <DialogContent sx={{border: `24px solid ${colors['blue-100']}`,}}>
      <CompanyInfoSection>
        <CompanyInfoContainer>
          <CompanyInfoRow>
            <CompanyInfoText>I am researching professionals who hold a </CompanyInfoText>
            <SelectionButton id="rule-1-operator" opts={TENURE} defaultValue={TENURE[0]} selectedValue={mapFirstConditionRules[0]['operator']} disableClearable={true} ruleKey="operator" position={0} cb={onOptionSelect} />
            <CompanyInfoText>position as</CompanyInfoText>
            <SelectionButton isMultiSelect={true} id="rule-1-value" fn={fetchTitlesByOperator} opts={[JOB_TITLES]} defaultValue={JOB_TITLES[0]} selectedValue={mapFirstConditionRules[0]['value'][0]} ruleKey="value" position={0} cb={onOptionSelect} />
            <CompanyInfoText>with</CompanyInfoText>
            <SelectionButton id="rule-2-operator" opts={TENURE} defaultValue={TENURE[0]} selectedValue={mapFirstConditionRules[2]['operator']} ruleKey="operator" position={2} cb={onOptionSelect} />
            <CompanyInfoText>experience in</CompanyInfoText>
            <SelectionButton id="rule-2-field" opts={COMPANYSEL} defaultValue={COMPANYSEL[0]} disableClearable={true} selectedValue={mapFirstConditionRules[2]['field']} ruleKey="field" position={2} cb={onOptionSelectResetValue} />
            <CompanyInfoText>like</CompanyInfoText>
            <SelectionButton
              fn={fetchFnByField(mapFirstConditionRules[2]['field'])}
              defaultValue={NAMES[0]}
              opts={NAMES}
              selectedValue={mapFirstConditionRules[2]['value'][0] ?? ''}
              ruleKey="value"
              position={2}
              cb={onOptionSelect}
              id={`company-${mapFirstConditionRules[2]['field']}-${mapFirstConditionRules[2]['operator']}`}
            />{'. '}
            {/* Conditional rendering for more options */}
            {showMoreOptions && (
              <>
                <CompanyInfoText>They should also have</CompanyInfoText>
                <SelectionButton opts={TENURE} defaultValue={TENURE[0]} disableClearable={true} selectedValue={mapFirstConditionRules[4]['operator']} ruleKey="operator" position={4} cb={onOptionSelectResetValue} />
                <CompanyInfoText>experience with</CompanyInfoText>
                <SelectionButton
                  id={`business-model-${mapFirstConditionRules[4]['operator']}`}
                  opts={fetchOptionsByFieldAndRegency('Business Model', mapFirstConditionRules[4]['operator'])}
                  ruleKey="value"
                  position={4}
                  cb={onOptionSelect}
                  selectedValue={mapFirstConditionRules[4]['value'][0] ?? ''}
                />
                <CompanyInfoText>companies and</CompanyInfoText>
                <SelectionButton opts={TENURE} defaultValue={TENURE[0]} selectedValue={mapFirstConditionRules[6]['operator']} disableClearable={true} ruleKey="operator" position={6} cb={onOptionSelectResetValue} />
                <CompanyInfoText>experience in a</CompanyInfoText>
                <SelectionButton
                  id={`stage-${mapFirstConditionRules[6]['operator']}`}
                  opts={fetchOptionsByFieldAndRegency('Stage', mapFirstConditionRules[6]['operator'])}
                  ruleKey="value"
                  position={6}
                  cb={onOptionSelect}
                  selectedValue={mapFirstConditionRules[6]['value'][0] ?? ''}
                />
                <CompanyInfoText>company.</CompanyInfoText>
              </>
            )}
          </CompanyInfoRow>

          {/* Toggle More/Less text at the end */}
          <CompanyInfoText
            as="button"
            onClick={() => handleShowMoreClick(true)}
            style={{
              color: 'grey',
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              fontSize: 'medium',
              marginTop: 10,
              padding: '10px 0', // add some padding for better clickability
              textAlign: 'center',
              textDecoration: 'underline',
              width: '100%', // ensure it spans the full width
            }}
          >
            {showMoreOptions ? '' : 'More Options'}
          </CompanyInfoText>

          <ActionButtonsContainer>
            <ActionButton 
              variant='text' 
              loading={isSubmitting} 
              onClick={saveAndGoToResults}
              sx={{color: colors['grey-600'], fontSize: '16px'}}
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
            >
              Browse results
              <ArrowPillWrapper>
                <EastIcon />
              </ArrowPillWrapper>
            </ActionButton>
            <ActionButton 
              variant='text' 
              loading={isSubmitting} 
              onClick={saveAndGoToInsights}
              sx={{color: colors['grey-600'], fontSize: '16px'}}
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
            >
                Browse insights
                <ArrowPillWrapper>
                  <BarChartIcon />
                </ArrowPillWrapper>
            </ActionButton>
          </ActionButtonsContainer>
        </CompanyInfoContainer>
      </CompanyInfoSection>
    </DialogContent>
  </>);
}

const CompanyInfoSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  font-size: 24px;
  color: #453e39;
  padding: 64px 60px 32px;
  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;
const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This centers the content vertically */
  max-width: 834px;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const CompanyInfoRow = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap as needed */
  justify-content: center;
  align-items: center;
  gap: 8px; /* Consistent gap between items */
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width */
  padding: 0 16px; /* Add padding to prevent content from touching the edges */

  @media (max-width: 991px) {
    padding: 0 8px; /* Reduce padding on smaller screens */
  }

  > * {
    /* Prevent child elements from growing too large */
    max-width: 100%;
    box-sizing: border-box;
  }
`;
const CompanyInfoText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%; /* Ensure it doesn't grow larger than its container */
`;
const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  gap: 16px;
  flex-wrap: nowrap; /* Prevent button wrapping */
  margin: auto;
  margin-top: 36px;
`;
const ActionButton = styled(Button)`
  flex: 1;
  padding: 12px 24px;
  font-size: 24px;
  text-transform: 'none'
  font-weight: 500;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: ${colors['white']} !important;
  }
`;

const ArrowPillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 10px 18px;
  margin: 0 8px;
  cursor: pointer;
  border: 1px solid ${colors['grey-600']};
  &:hover {
    background: ${colors['blue-100']};
    border: 1px solid ${colors['blue-400']};
    color: ${colors['blue-400']};
  }
`;

export default AdLibComponent;
