import axios from "axios";
import { matchesUrl, pubUrl, searchUrl } from "findem-helpers";
import { router } from 'react-query-kit'

const user = {
  getUserById: router.query({
    fetcher: (variables: { id: string }) =>
    axios.post(`${pubUrl}/pub/api/user_info`,{joid: variables.id}, { withCredentials: true})
  }),
  getAllUsers: router.query({
    fetcher: () =>
    axios.get(`${matchesUrl}/hm/api/users`, { withCredentials: true}),
    staleTime: 1000 * 60 * 60
  }),
  logoutUser: router.mutation({
    mutationFn: async () =>
      axios.get(`${matchesUrl}/auth/logout?api=true`, { withCredentials: true })
  }),
  getCsrfToken: router.query({
    fetcher: () =>
    axios.get(`${matchesUrl}/auth/csrf_token`, { withCredentials: true })
  }),
  authenticateUser: router.mutation({
    mutationFn: async (variables: { email: string, password: string, csrf: string }) =>
      axios.post(`${searchUrl}/auth/login`, { api: true, _csrf: variables.csrf, email: variables.email, password: variables.password }, { withCredentials: true })
  }),
};

export default user;
// hooks
// usersApi.getUserById.useQuery({ variables: { id: 1 } })