import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { QueryBuilderMaterial } from '@react-querybuilder/material'
import React from 'react'
import { QueryBuilder, formatQuery } from 'react-querybuilder'
import { default as CustomComparator } from '../../../CustomComparator'
import { fetchCompanyLists, fetchTitles, fetchSkills, fetchIndustriesFilter, fetchCompanies, fetchCompanyListsFilter, fetchMacrosByTaxonomy} from '../../../Apis'
import { createTheme, ThemeProvider } from "@mui/material";
import './CreateMapFragment.css'
import { categories, operations } from '../../../Options'
import { APP_THEME } from '../../../common/constants/themes/AppTheme'
import { styled, lighten, darken } from '@mui/system';

const appTheme = createTheme(APP_THEME);

const fields = [
  { name: 'Company', label: 'Company' },
  { name: 'Company Lists', label: 'Company Lists' },
  { name: 'Industry', label: 'Industry' },
  { name: 'Job Title', label: 'Job Title' },
  { name: 'Skills', label: 'Skills' },
  { name: 'Company Stage', label: 'Company Stage' },
  { name: 'Company Business Model', label: 'Company Business Model' }
]

const operators = [
  { name: 'Current Experience', label: 'Current Experience' },
  { name: 'Last 3 years', label: 'Last 3 years' },
  { name: 'Anytime', label: 'Anytime' }
]

export const DummyQuery = {
  rules: [
    { field: 'Company', operator: 'Current Experience', value: [] },
  ],
}

let op_menus = []
operations.forEach((m, idx) => {
  op_menus.push(
    <MenuItem key={'op-' + idx} value={m.title}>
      {m.title}
    </MenuItem>
  )
})

let cat_menus = []
categories.forEach((m, idx) => {
  cat_menus.push(
    <MenuItem key={'menu-' + idx} value={m.title}>
      {m.title}
    </MenuItem>
  )
})
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default function CreateMapFragment(props) {
  // const [query, setQuery] = React.useState({
  //   combinator: 'and',
  //   rules: [{ field: 'Company', operator: 'Current Experience', values: [] }]
  // })
  let query = props.query
  const setQuery = props.setQuery;
  const currentUser = props.currentUser;
  const joid = props.joid || currentUser.uid;
  console.log("CreateMapFragment", props)

  if (!query) {
    query = DummyQuery;
  }

  const [selectedCategory, setSelectedCategory] = React.useState({})
  const [selectedOp, setSelectedOp] = React.useState({})

  function saveQuery(q) {
    let formatted_query = formatQuery(q)
    console.log(formatted_query)
    setQuery(q, props.selectedPillIndex)
  }

  function ValueEditor(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [inputValue, setInputValue] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState([]);
  
    // Effect to handle initial value setting and updates for "Company Lists"
    React.useEffect(() => {
      async function fetchAndSetValues() {
        if (props.rule.field === 'Company Lists') {
          let data = await fetchCompanyLists(joid);
          setOptions(data || []);

          if (props.rule.value) {
            let selected = [];
            props.rule.value.forEach((v2)=> {
              let k = data.filter(row => row.value === v2.value)
              if (k.length > 0) {
                selected.push(k[0]);
              }
            });
            // setSelectedValue(selected);
            setSelectedValue(props.rule.value || []);
          }
        } else {  
          // Directly use rule.value for other fields or if no value is set
          setSelectedValue(props.rule.value || []);
        }
      }
      fetchAndSetValues();
    }, [props.rule.field, props.rule.value, joid]);
  
    // Effect for fetching options based on the field and inputValue
    React.useEffect(() => {
      let active = true;
  
      async function fetchOptions() {
        if (!inputValue && !['Company Stage', 'Company Business Model'].includes(props.rule.field)) {
          setOptions([]);
          return;
        }
  
        let data = [];
        const _regency = props.rule.operator === 'Current Experience' ? ';Current' 
                        : props.rule.operator === 'Last 3 years' ? ';Recent'
                        : ';Past or Current';

        switch (props.rule.field) {
          case 'Company':
            data = await fetchCompanies(inputValue);
            break;
          case 'Job Title':
            data = await fetchTitles(inputValue, _regency, false);
            break;
          case 'Industry':
            data = await fetchIndustriesFilter(inputValue);
            break;
          case 'Company Lists':
            data = await fetchCompanyListsFilter(inputValue, props.rule.operator, joid);
            break;
          case 'Skills':
            data = await fetchSkills(inputValue);
            break;   
          case 'Company Stage':
            data = fetchMacrosByTaxonomy(inputValue, `Stage${_regency}`);
            break;
          case 'Company Business Model':
            data = fetchMacrosByTaxonomy(inputValue, `Business Model${_regency}`);   
            break;
          default:
            data = [];
        }
        if (active) {
          setOptions(data || []);
        }
      }
  
      fetchOptions();
      return () => {
        active = false;
      };
    }, [inputValue, props.rule.field, props.rule.operator, joid]);
  
    // Clear options when dropdown closes
    React.useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);

    const isGrouped = options.some(option => option.options);
    return (
      <Autocomplete
        id="asynchronous-demo"
        multiple
        freeSolo
        disableCloseOnSelect={true}
        value={selectedValue}
        options={isGrouped ? options.flatMap(group => group.options) : options} // Flattening for rendering individual options
        groupBy={isGrouped ? (option) => {
          // Find the group label that this option belongs to
          const group = options.find(g => g.options.includes(option));
          return group ? group.label : '';
        } : undefined}
        getOptionLabel={(option) => { return (option.label ? option.label : option.title) }}        
        sx={{ width: "90%" }}
        open={open}
        onOpen={() => {
          console.log("onOpen");
          setOpen(true);
        }}
        onClose={() => {
          console.log("onClose");
          setOpen(false);
        }}
        onChange={(event, value) => {
          setSelectedValue(value);
          props.handleOnChange(value);
        }}
        loading={loading}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selections combine with 'OR'"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        renderGroup={(params) => (
          <li key={`${params.group}-${params.key}`}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    );
  }

  function SelectCategory(props) {
    const { selectedCategory, setSelectedCategory } = props.context
    const selection_str = props.path.toString()

    function changeHandler(e) {
      setSelectedCategory({
        ...selectedCategory,
        [selection_str]: e.target.value
      })
      props.handleOnChange(e.target.value)
    }

    return (
      <Box>
        <FormControl>
          {/* <InputLabel id='demo-simple-select-label'>Category</InputLabel> */}
          <Select
            labelId='demo-simple-select-label'
            sx={{ minWidth: 200 }}
            // fullWidth
            id='demo-simple-select'
            value={props.rule ? props.rule.field : 'Company'}
            onChange={changeHandler}
          >
            {cat_menus}
          </Select>
        </FormControl>
      </Box>
    )
  }

  function SelectOp(props) {
    const { selectedOp, setSelectedOp } = props.context
    const selection_str = props.path.toString()

    function changeHandler(e) {
      setSelectedOp({
        ...selectedOp,
        [selection_str]: e.target.value
      });
      props.handleOnChange(e.target.value)
    }
    return (
      <Box>
        <FormControl>
          {/* <InputLabel id='demo-simple-select-label'>Experience</InputLabel> */}
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            sx={{ minWidth: 200 }}
            // value={selectedOp[selection_str] ? selectedOp[selection_str] : "Current Experience"}
            value={props.rule ? props.rule.operator : 'Current Experience'}
            // label='Experience'
            onChange={changeHandler}
          >
            {op_menus}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const valueEditorType = (field, operator) => {
    return "multiselect";
  }

  const translations = {
    addRule: {
      label: "+ Add another rule", // Customize this label
      title: "Add rule",
    }
  }

  return (
    <>
      <div className="query-builder-label">
        <span>Group-{(props.selectedPillIndex >= 0) ? (props.selectedPillIndex + 1) : "New"}</span>
      </div>

      <ThemeProvider theme={appTheme}>
        <QueryBuilderMaterial>
          <QueryBuilder
            fields={fields}
            operators={operators}
            query={query}
            onQueryChange={saveQuery}
            showCombinatorsBetweenRules={true}
            getValueEditorType={valueEditorType}
            addRuleToNewGroups
            translations={translations}
            resetOnOperatorChange={true}
            controlElements={{
              fieldSelector: SelectCategory,
              operatorSelector: SelectOp,
              valueEditor: ValueEditor,
              combinatorSelector: (props) => {
                // Find the path for the current rule group to manage its state
                const pathString = props.path.join('_');
                const comparator = query.combinators?.[pathString] || 'AND';
                const setComparator = (newValue) => {
                  // Implement logic to update the query state with the new comparator
                  const newCombinators = { ...query.combinators, [pathString]: newValue };
                  const newQuery = { ...query, combinators: newCombinators };

                  saveQuery(newQuery);
                };
                return (
                  <CustomComparator comparator={comparator} setComparator={setComparator} />
                );
              },
            }}
            context={{
              selectedCategory,
              setSelectedCategory,
              selectedOp,
              setSelectedOp
            }}
          />
        </QueryBuilderMaterial>
      </ThemeProvider>
      <Divider sx={{ mt: 3 }} />
    </>
  )
}
