// CustomComparator.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const CustomComparator = ({ rule, comparator, setComparator }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 90 }}>
        {/* <InputLabel id="custom-comparator-label">And/Or</InputLabel> */}
        <Select
          labelId="custom-comparator-label"
          id="custom-comparator"
          value={comparator}
          onChange={e => setComparator(e.target.value)}
          // label="And/Or"
        >
          <MenuItem value="AND">AND</MenuItem>
          <MenuItem value="OR">OR</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomComparator;
