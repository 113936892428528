// Clone Map Modal
import React from 'react';
import { IMap, MapStatus } from '../../../common/types/mapTypes';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material';
import UserSelect from '../../common/UserSelect/UserSelect';
import { SaveMap } from '../../../Apis';
import UserContext from '../../../common/contexts/UserContext';
import useDummyFn from '../../../common/hooks/useDummyFn';
import styled from 'styled-components';
import { colors } from '../../../common/constants';

interface IMapCloneProps {
  map: IMap;
  onClose: () => void;
  saveCb: (map: any, generate_map: boolean) => void;
}

const MapClone: React.FC<IMapCloneProps> = ({ map, onClose, saveCb }) => {

  const {user} = React.useContext(UserContext);
  const [selectedUser, setSelectedUser] = React.useState(user.uid);
  const [mapName, setMapName] = React.useState(map.name);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleUserSelect = (value: string) => {
    setSelectedUser(value);
  }

  const handleClone = async () => {
    setIsLoading(true);
    await SaveMap("", 
      mapName, 
      "", 
      JSON.parse(map.all_rules_str), 
      selectedUser || user.uid, 
      MapStatus.SelfServ
    ).then(async (resp: any) => {
      await saveCb(resp, true)
      setIsLoading(false);
      onClose();
    });

  }

  return (
   <Dialog 
    open={true} 
    onClose={onClose}
  >
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
      onClick={useDummyFn}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <DialogTitle>Clone Map</DialogTitle>
    <DialogContent>
      <FormInputWrapper>
        <Label>Map Name</Label>
        <Input 
          value={mapName} 
          onChange={(e) => setMapName(e.target.value)} 
          sx={{
            width: 'fit-content',
            marginLeft: '6px',
          }}
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>User</Label>
        <UserSelect value={selectedUser} onChangeCB={handleUserSelect} variant='standard' />
      </FormInputWrapper>
    </DialogContent>
    <DialogActions>
      <Button variant='outlined' onClick={onClose}>Cancel</Button>
      <Button variant='contained' onClick={handleClone}>Clone</Button>
    </DialogActions>
  </Dialog>
  );
};

export default MapClone;

const FormInputWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.p`
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: ${colors['grey-700']};
  width: 80px;
`;
