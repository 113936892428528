import { Container, Box, Divider } from '@mui/material';
import PageHeader from '../common/PageHeader';
import CompanyResearch from './CompanyResearch';

interface ICompanyListProps {
  isAppNext?: boolean;
}

const CompanyList = (props: ICompanyListProps) => {
  return (
    <Container sx={{ mt: 4, minWidth: "100%" }}>
      <PageHeader 
        title='Company Research'
        description={<>👋 Build custom company lists using hundreds of filters, including talent analysis, categories, and competitors, for targeted insights.Build custom company lists using hundreds of filters, <br/>
        including talent breakdown, categories, and competitors, for building targeted marketmaps & insights.</>}
      />
      <Divider sx={{m: '20px 0', borderColor: '#EAECF0'}}/>
      <Box sx={{ width: '100%' }}>
        <CompanyResearch {...props}/>
      </Box>
    </Container>
  );
};

export default CompanyList;