import React from 'react';
import IframePage from '../common/IframeContainer/IframePage';
import { appNextUrl } from 'findem-helpers';

const Search = () => {
  
  // get relative path from the URL
  const {pathname , search} = window.location;
  return (
    <IframePage iframeRoute={`${appNextUrl}${pathname}${search}&iframe=true`} />
  );
}

export default Search;