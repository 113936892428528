import React, { useState, useEffect, forwardRef } from 'react';
import { Dialog, Divider, DialogActions, DialogContent, DialogTitle, Button, Typography, Slide, TextField, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { fetchTitles, fetchSkills, prefixQueries, prefixSearch, createOrUpdateDynamicColumn, deleteDynamicColumns } from './Apis';
import { loadDynCol } from './utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { APP_THEME } from "./common/constants/themes/AppTheme";

const appTheme = createTheme(APP_THEME);

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const DynamicColumns = ({ selectCb, type, open, handleClose, fetchOptions }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [columnName, setColumnName] = useState('');
    const [cachedColumns, setCachedColumns] = useState([]);
    const [selectedCachedColumn, setSelectedCachedColumn] = useState(null);
    const [mode, setMode] = useState('create');  // 'create' or 'useExisting'

    const loadOptions = async (inputValue) => {
        // return ["Option 1", "Option 2", "Option 3"].map(option => ({ label: option, value: option }));
        let fn = '';
        if (type === 'title') fn = fetchTitles;
        if (type === 'skills') fn = fetchSkills;
        if (type === 'attrs') fn = prefixQueries;
        if (type === 'search') fn = prefixSearch;

        try {
            const data = await fn(inputValue);
            return data;
        } catch (error) {
            console.error('Error fetching options:', error);
            return [];
        }
    };
    useEffect(() => {
        const fetchCachedColumns = async () => {
            let coltype = 0;
            if (type == "skills") {
                coltype = 1;
            } else if (type == 'attrs') {
                coltype = 2;
            } else if (type == 'search') {
                coltype = 3;
            }
            let data = await loadDynCol(coltype);
            let cached = [];
            data.forEach((d) => {
                cached.push({
                    label: d.name,
                    value: d.ref,
                    meta_value: d.value
                })
            })
            setCachedColumns(cached);
        };
        fetchCachedColumns();
    }, []);

    const handleModeChange = (event) => {
        setMode(event.target.value);
        if (event.target.value === 'useExisting') {
            setSelectedOptions([]);  // Optionally clear selections when switching modes
        }
    };

    const handleNameChange = (event) => {
        setColumnName(event.target.value);
    };

    const handleCachedColumnChange = (selectedOption) => {
        setSelectedCachedColumn(selectedOption);
        setColumnName(selectedOption.label);  // Optionally set the name to the label of selected cached column
        setSelectedOptions(selectedOption.meta_value);
    };

    const handleContinue = () => {
        console.log(`Saving ${type} as "${columnName}" with selection:`, selectedOptions);
        selectCb(columnName, selectedOptions);
        let ctype = ((type == "skills") ? 1 : (type == "attrs") ? 2 : (type == "search") ? 3 : 0);
        if (mode == "create") {
            // create
            // export enum IDynColmnType {
            //   Title = 0,
            //   Company = 1,
            //   Attrs = 2,
            // }
            createOrUpdateDynamicColumn('', ctype, selectedOptions, columnName);
        } else {
            // save selectedCachedColumn
            createOrUpdateDynamicColumn(selectedCachedColumn.value, ctype, selectedOptions, columnName);
        }
        handleClose();
    };

    const handleDelete = () => {
        if (mode == "create") {
            return;
        }
        deleteDynamicColumns([selectedCachedColumn.value]);
        handleClose();
    }
    let type_txt = type === "attrs" ? "Findem Attribute" : (type === "title" ? "Title" : (type === "search" ? "Search" : "Skill"));

    return (
        <Dialog
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: {
                    minWidth: '600px',
                    minHeight: '350px',
                    maxWidth: '80%',
                    width: 'auto',
                },
            }}
        >
            <ThemeProvider theme={appTheme}>
                <DialogTitle id="form-dialog-title">Add Filter: {type_txt}</DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
                        <RadioGroup row name="mode" value={mode} onChange={handleModeChange}>
                            <FormControlLabel value="create" control={<Radio />} label="Create New" />
                            <FormControlLabel value="useExisting" control={<Radio />} label="Use Existing" />
                        </RadioGroup>
                    </FormControl>

                    {mode === 'useExisting' && (
                        <Select
                            value={selectedCachedColumn}
                            onChange={handleCachedColumnChange}
                            options={cachedColumns}
                            placeholder="Select a cached column"
                            sx={{ marginBottom: 2 }}
                            styles={{
                                container: (base) => ({ ...base, marginBottom: '20px', fontSize: '14px', fontFamily: 'Roboto, sans-serif' }),
                                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: '14px', fontFamily: 'Roboto, sans-serif' })
                            }}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                        />
                    )}

                    <TextField
                        label="Name"
                        fullWidth
                        onChange={handleNameChange}
                        value={columnName}
                        sx={{ backgroundColor: 'white', marginBottom: 2 }}
                        variant="outlined"
                        size="small"
                    />

                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={loadOptions}
                        isMulti={type === 'search' ? false : true}
                        onChange={(event) => {
                            if (type === 'search') {
                                setSelectedOptions([{
                                    key: event.value,
                                    label: event.label,
                                    raw_value: event.raw_value,
                                    value: event.value
                                }]);
                            } else {
                                setSelectedOptions([...event]);
                            }
                        }}
                        value={selectedOptions}
                        placeholder={`Select one or more ${type_txt}s. Multiple selections will be combined using "OR" logic`}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999, fontSize: '14px', fontFamily: 'Roboto, sans-serif' }),
                            container: (base) => ({ ...base, fontSize: '14px', fontFamily: 'Roboto, sans-serif' }),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    {(mode == "create") ? "" : <Button variant="outlined" disabled={!selectedOptions.length || !columnName} sx={{ textTransform: 'none', float: 'left' }} onClick={handleDelete} color="error"><DeleteIcon /></Button>}
                    <Button sx={{ textTransform: 'none' }} onClick={handleClose} variant="outlined" color="primary">Cancel</Button>
                    <Button sx={{ textTransform: 'none' }} onClick={handleContinue} variant="outlined" color="primary" disabled={!selectedOptions.length || !columnName}>{(mode == "create") ? "Add Column Aggregation" : "Add Column Aggregation & Save"}</Button>
                </DialogActions>
            </ThemeProvider>
        </Dialog>
    );
};

export default DynamicColumns;
