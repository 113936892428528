export enum MapStatus {
  VerificationNotRequested = 0,
  VerificationPending = 1,
  VerificationInProgress = 2,
  VerificationDone = 3,
  SelfServ = 4,
}

export interface IMap {
  all_rules_str: string;
  created_at: string;
  description: string;
  history: string;
  name: string;
  owner_id: string;
  ref: string;
  search_link: string;
  status: MapStatus;
  updated_at: string;
}