// Copyright 2024 and onwards, All rights reserved.
// Author: Hariharan Kolam

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef} from "react";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { getMapBootStr, SaveMap } from "./../../../Apis";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import CreateMapFragment, { DummyQuery } from "./CreateMapFragment";
import "./../../../styles.css";
import Box from "@mui/material/Box";
import { Popover, Typography, TextField, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import { APP_THEME } from "./../../../common/constants/themes/AppTheme";

import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { humanizeCount, useUrlParams } from "findem-helpers";
import { mapApis } from '../../../common/services/apis';
import UserContext from "../../../common/contexts/UserContext";
import { IRequirement } from "../../../common/types/requirementType";
import { colors } from "../../../common/constants";
import EditIcon from '@mui/icons-material/Edit';
import { IMap, MapStatus } from "../../../common/types/mapTypes";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const appTheme = createTheme(APP_THEME);

export default function CreateMapSimple(props: {
  name?: any;
  query?: any;
  bc_settings?: any;
  saveCb?: any;
  handleClose?: any;
  id?: any;
  selectedMap?: IMap;
}) {
  const { handleClose, id, selectedMap } = props;
  const {joid, ref} = useUrlParams();
  const { user } = React.useContext(UserContext);
  
  const [name, setName] = React.useState(props.name);
  const [allQueries, setAllQueries] = React.useState<any>([]);
  const [newRule, setNewRule] = React.useState(false);
  const [unsavedQuery, setUnsavedQuery] = React.useState(null);
  const [triggerSaveMap, setTriggerSaveMap] = React.useState(false);
  const [submitVerification, setSubmitVerification] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [specialInstructions, setSpecialInstructions] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [requirements, setRequirements] = React.useState<IRequirement[]>([]);

  const navigate = useNavigate();

  let bc_settings = props.bc_settings;
  if (!bc_settings) {
    bc_settings = {
      url: "/maps",
      name: "Executive Mapping",
    };
  }
  const handlePopOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

   // APIs
   const { 
    data: countsData, 
  } = mapApis.getMultiMatchCount.useQuery({ variables: {
    requirements: requirements,
    joid: joid ?? user.uid
  }, enabled: requirements.length > 0}); 

  const handleSubmit = () => {
    // Handle the submission of data
    saveQuery(true);
    navigate("#success_submit");
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const open = Boolean(anchorEl);
  const pid = open ? "simple-popover" : undefined;

  const saveMap = useCallback(async (generate_map: boolean) => {
    // allQueries
    // map name - name
    setIsSubmitting(true);
    const _allQueriesFiltered = allQueries.filter(
      (q: any) => q.rules && q.rules.length > 0
    );
    let status;
    if(selectedMap){
      status = selectedMap.status;
    }
    if(submitVerification){
      status = MapStatus.VerificationPending;
    }else{
      status = MapStatus.SelfServ;
    }
    let res = await SaveMap(id ? id : "", name, "", _allQueriesFiltered, joid, status);
    if (submitVerification) {
      setIsSubmitting(false);
      props.saveCb(res, generate_map, true);
      setSubmitVerification(false);
      handleClose(true);
    } else {
      setIsSubmitting(false);
      props.saveCb(res, generate_map, false);
      handleClose(false);
    }
  },[allQueries, handleClose, id, name, props, submitVerification, joid, selectedMap]);

  React.useEffect(() => {
    if (triggerSaveMap) {
      saveMap(true);
      // Reset the trigger
      setTriggerSaveMap(false);
    }
    if (submitVerification) {
      // submit for manual verification
    }
    // This effect should run only when triggerSaveMap changes
  }, [saveMap, submitVerification, triggerSaveMap]);

  const handleSave = ({
    name,
    value,
    previousValue,
  }: {
    name: any;
    value: any;
    previousValue: any;
  }) => {
    setName(value);
    // ajax api to update name
  };

  const setQuery = (query: any, index: string | number) => {
    // Use a functional update to handle state changes
    setAllQueries((currentQueries: any) => {
      // Clone the current state to avoid direct mutations
      const updatedQueries: any = [...currentQueries];

      // Check if the query should be updated or added
      if (updatedQueries[index]) {
        // Update existing query at the specified index
        updatedQueries[index] = query;
      } else {
        setUnsavedQuery(query);
      }

      // Return the updated queries array to update state
      return updatedQueries;
    });
  };

  const saveQuery = useCallback((submit_verification = true) => {
    if (allQueries.length === 0 && !unsavedQuery) {
      return;
    }
    setSubmitVerification(submit_verification);
    // Instead of calling saveMap here, we set the trigger
    setNewRule(false);
    setTriggerSaveMap(true);
  },[allQueries.length, unsavedQuery]);

  const fragments = useMemo(() => {
    return allQueries.map((q: any, idx: string) => {
      return (
        <CreateMapFragment
          key={"frag-" + idx}
          selectedPillIndex={idx}
          query={q}
          setQuery={setQuery}
          currentUser={user}
          joid={joid}
        />
      );
    });
  },[allQueries, joid, user])


  useLayoutEffect(() => {
    if (newRule || !id) {
      setAllQueries((props.query && props.query.length > 0) ? props.query : [DummyQuery]);
    }else{
      setAllQueries(props.query);
    }
  }, [id, newRule, props.query]);

  const addNewSection = React.useCallback(() => {
    const _allQueries = allQueries.slice();
    setAllQueries([..._allQueries, DummyQuery]);
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  },[allQueries]);

  const showMatches = () => {
    alert(showMatches);
  };

  const openSub = Boolean(anchorEl);
  const sid = openSub ? "simple-popover" : undefined;
  const StyledLink = styled(Link)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    // Apply any additional styles if needed
  }));

  useEffect(() => {
    if(allQueries){
      const _reqBoolStr = getMapBootStr(allQueries);
      setRequirements([{
        bool_str: _reqBoolStr,
        section_type: "Must Have",
        section_name: "Map",
        section_id: "map"
      }]);
    }
  },[allQueries]);

  return (
    <ThemeProvider theme={appTheme}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Breadcrumb-like navigation */}
          <Breadcrumbs
            aria-label="breadcrumb"
          >
            <StyledLink href={bc_settings.url} underline="hover">
              <ArrowBackIcon
                fontSize="small"
                sx={{ verticalAlign: "middle", mr: 0.5 }}
              />
              {bc_settings.name}
            </StyledLink>
            <Box
              sx={{
                display: "flex",
                '&:hover': {
                  backgroundColor: colors['grey-100'],
                  '.map-edit-icon': {
                    display: 'block !important',
                  }
                }
              }}
            >
              <EditText
                style={{
                  position: "relative",
                  padding: '0 2px',
                  margin: 0,
                  lineHeight: "2em",
                  borderRadius: "4px",
                }}
                name="textbox1"
                onSave={handleSave}
                defaultValue={name ? name : "New Mapping"}
                showEditButton={true}
                editButtonContent={<EditIcon className="map-edit-icon" sx={{
                  fontSize: "14px",
                  color: colors['grey-600'],
                  cursor: "pointer",
                  display: "none",
                }} />}
              />
            </Box>
          </Breadcrumbs>
          {countsData?.data?.base_filter_count && countsData?.data?.base_filter_count > 0 ? <Box
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: colors['grey-600'],
              marginRight: "30px",
            }}
          >
            Match Pool: {humanizeCount(countsData?.data?.base_filter_count)}
          </Box> : <></>}
          {/* Action buttons on the right */}
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => { handleClose(false) }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: colors['grey-600'],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent sx={{
        "& .ruleGroup":{
          flexDirection: "column-reverse"
        }
      }}>
        {fragments}
        <div ref={messagesEndRef} />
        <LoadingButton
          loading={isSubmitting}
          variant="outlined"
          onClick={addNewSection}
          sx={{ 
            mr: 1,
            mt: '20px',
            width: '100%',
            borderStyle: "dashed",
            '&:hover': {
              borderStyle: "dashed",
            }
           }}
        >
          + Add another group
        </LoadingButton>
      </DialogContent>
      <DialogActions>
        <Box>          
          <LoadingButton
            loading={isSubmitting}
            variant="outlined"
            onClick={() => {
              saveQuery(false);
            }}
            sx={{ mr: 1 }}
          >
            {id ? 'Update' : 'Create'} Map (Self Serve)
          </LoadingButton>
          {/* <Button variant="contained" onClick={submitRequest}> */}
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            aria-describedby={sid}
            onClick={handlePopOpen}
          >
            {id ? 'Update' : 'Create'} Map (Findem Assist)
          </LoadingButton>
          <Popover
            id={id}
            open={openSub}
            anchorEl={anchorEl}
            onClose={handlePopClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box p={2} sx={{ minWidth: 400 }}>
              <Typography variant="h6" component="h2">
                Submit Request
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body2">
                This request will be sent. Findem researchers will verify
                candidates and notify you when it's ready.
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Optional: Add special instructions for researchers."
                margin="normal"
                value={specialInstructions}
                onChange={(e) => setSpecialInstructions(e.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handlePopClose}
                  color="inherit"
                >
                  Back
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
      </DialogActions>
    </ThemeProvider>
  );
}
