import axios from "axios";
import { matchesUrl, pubUrl, searchUrl } from "findem-helpers";
import { router } from 'react-query-kit'

const map = {
  getAllMaps: router.query({
    fetcher: (variables: { id: string }) =>
    axios.post(`${pubUrl}/pub/api/execmap${variables.id ? `?joid=${variables.id}` : ''}`,{joid: variables.id, type: 'listall'}, { withCredentials: true})
  }),
  deleteMapsIcp: router.mutation({
    mutationFn: (variables: { ids: string[], joid: string }) =>
    axios.post(`${matchesUrl}/hm/api/icp`, {joid: variables.joid, icpids: variables.ids.toString(), type: 'DeleteIcps'}, { withCredentials: true})
  }),
  getMultiMatchCount: router.query({
    fetcher: (variables: { requirements: any, joid: string }) =>
    axios.post(`${searchUrl}/pub/api/multi_matchesV2`, {
      requirements: variables.requirements,
      sandbox: false,
      joid: variables.joid,
    }, { withCredentials: true})
  }),
  getCompaniesByIds: router.query({
    fetcher: (variables: { ids: string[] }) =>
    axios.post(`${pubUrl}/pub/api/execmap`, { refs: variables.ids, type: "fetchCompaniesById" }, { withCredentials: true})
  }),
  getICPsByType: router.query({
    fetcher: (variables: { joid: string }) =>
    axios.post(`${matchesUrl}/hm/api/icp_list_by_type`, { 
      icp_type: "7", joid: variables.joid, limit: 50 }, { withCredentials: true})
  })
};

// get root key
// mapApis.getKey();

export default map;