import { Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../common/constants';
import UserContext from '../../common/contexts/UserContext';
import { userApis } from '../../common/services/apis';

interface INavUserMenuProps {
  isCollapsed: boolean;
}

const NavUserMenu = (props: INavUserMenuProps) => {
  const { isCollapsed } = props;
  const {user} = React.useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = React.useCallback(async () => {
    userApis.logoutUser.mutationFn().then(() => {
      handleClose();
      window.location.reload();
  });
  }, [handleClose]);

  return (
    <>
      <StyledUserMenuButton sx={{
        backgroundColor: colors.black,
        "&:hover": {
          backgroundColor: colors['grey-600'],
        },
      }} fullWidth onClick={handleClick}>
        <Stack direction="row" flex={1} alignItems="center" overflow="hidden" maxHeight="48px" height="48px">
          <Box component="img" ml="4px" sx={{ height: "40px", width: "40px", borderRadius: "50%" }} src={"/profile-graphic.svg"} alt="Avatar" />
          <Stack direction="column" justifyContent="space-between" pl="16px" alignItems="flex-start" whiteSpace="nowrap" sx={{ opacity: isCollapsed ? 0 : 1 }}>
            <Typography variant="subtitle1" sx={{ color: colors.white }}>
              {user.name}
            </Typography>
            {user?.company && (
              <Typography variant="body1" sx={{ color: colors.white }}>
                {user.company}
              </Typography>
            )}
          </Stack>
        </Stack>
      </StyledUserMenuButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "250px",
          },
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default NavUserMenu;

const StyledUserMenuButton = styled(Button)`
  &:hover {
    background-color: #222222;
  }
`;