import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { userApis } from '../../../common/services/apis';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Backdrop, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { APP_THEME } from '../../../common/constants/themes/AppTheme';
import useDummyFn from '../../../common/hooks/useDummyFn';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://findem.ai/">
        Findem.ai
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const appTheme = createTheme(APP_THEME);

export default function Login() {

  const [helperText, setHelperText] = React.useState('');
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    data: csrfTokenObj
  } = userApis.getCsrfToken.useQuery();

  const handleSubmit = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
    setError(false);
    setHelperText('');
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // check for valid email
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    // email validation
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!email || !password || !isValidEmail) {
      setError(true);
      setHelperText('Invalid email or password');
      return;
    }
    if (!csrfTokenObj || !csrfTokenObj?.data?.csrfToken) {
      setError(true);
      alert('Please try again');
      return;
    }
    setIsLoading(true);
    userApis.authenticateUser.mutationFn({email, password, csrf: csrfTokenObj?.data?.csrfToken}).then((response) => {
      if (response.data) {
        window.location.href = '/';
      } else {
        setError(true);
        setHelperText('Invalid email or password');
      }
      setIsLoading(false);
    }).catch((error) => {
      setError(true);
      setHelperText('Invalid email or password');
      setIsLoading(false);
    });
  },[csrfTokenObj]);

  return (
    <ThemeProvider theme={appTheme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={useDummyFn}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <FormControl error={error} sx={{width: 400}}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <FormHelperText sx={{
                fontSize: 14,
                display: 'flex',
                justifyContent: 'center',
              }}>{helperText}</FormHelperText>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </FormControl>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}