// dummy function use callback

import { useCallback } from 'react';

const useDummyFn = () => {
  return useCallback(() => {
    return;
  }, []);
};

export default useDummyFn;