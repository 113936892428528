import { Container, Box, Divider } from '@mui/material';
import PageHeader from '../common/PageHeader';
import MapGenerator from './MapLists/Map';

const MapPage = () => {
  return (
    <Container sx={{ mt: 4, minWidth: "100%" }}>
      <PageHeader 
        title='Findem Research Accelerator'
        description={<>👋 Maximize your research with powerful tools for executive scouting, company analysis, and market insights—all in one suite..</>}
      />
      <Divider sx={{m: '20px 0', borderColor: '#EAECF0'}}/>
      <Box sx={{ width: '100%' }}>
        <MapGenerator />
      </Box>
    </Container>
  );
};

export default MapPage;