import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from '@mui/icons-material/Business';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ContactsIcon from '@mui/icons-material/Contacts';
import { SvgIcon } from "@mui/material";
import { IUser } from "findem-types";
import { hasUserCompanyViewAccess, hasUserMapAccess, hasUserQueriesAccess, hasUserCrmAccess } from "./accessUtils";
import { appNextUrl, matchesUrl } from "findem-helpers";

export interface ISidebarMenuItem {
  name: string;
  path: string;
  icon: typeof SvgIcon;
  end?: boolean;
  isIframe?: boolean;
  iframe_route?: string;
}

export const HOME_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "Home",
  path: "/",
  icon: HomeIcon,
  end: true,
};

export const MAP_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "Maps",
  path: `/maps`,
  icon: AssignmentIndIcon,
};

export const COMPANY_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "Company Research",
  path: `/company_research`,
  icon: BusinessIcon,
};

export const INSIGHTS_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "Insights",
  path: `/insights`,
  icon: AssessmentIcon,
};

export const QUERIES_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "Queries",
  path: `/queries`,
  icon: ChecklistIcon,
  isIframe: true,
  iframe_route: `${matchesUrl}/hm/queriesv2_slonly?iframe=true`
};

export const CRM_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "CRM",
  path: `/crm`,
  icon: ContactsIcon,
  isIframe: true,
  iframe_route: `${appNextUrl}/crm?iframe=true`
};

export function getEnabledSidebarItems(user: IUser): ISidebarMenuItem[] {
  const sidebarItems: ISidebarMenuItem[] = [];

  if (user && hasUserMapAccess(user.roles)) {
    sidebarItems.push(MAP_SIDEBAR_ITEM);
  }

  if (user && hasUserCompanyViewAccess(user.roles)) {
    sidebarItems.push(COMPANY_SIDEBAR_ITEM);
  }

  if (user && hasUserMapAccess(user.roles)) {
    sidebarItems.push(INSIGHTS_SIDEBAR_ITEM);
  }

  if (user && hasUserQueriesAccess(user.roles)) {
    sidebarItems.push(QUERIES_SIDEBAR_ITEM);
  }

  if (user && hasUserCrmAccess(user.roles)) {
    sidebarItems.push(CRM_SIDEBAR_ITEM);
  }

  return sidebarItems;
}
