import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { colors } from '../../common/constants';
import { MAP_SIDEBAR_ITEM } from '../../utils/navigationUtils';
import AssessmentIcon from "@mui/icons-material/Assessment";
import { constructUrlWithParams } from 'findem-helpers';

const EmptyInsights = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(constructUrlWithParams(MAP_SIDEBAR_ITEM.path,{
      ref: 'new'
    }));
  }, [navigate]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minWidth: '100%',
          backgroundColor: colors['white'],
          gap: '1em'
        }}
      >
        <AssessmentIcon sx={{fontSize: '18em', color: colors['grey-500']}}/>
        <Typography variant="h1" sx={{ color: colors['grey-500']}}>
          No Map Selected
        </Typography>
        <Typography variant="subtitle1" sx={{color:colors['grey-500'], fontWeight: 400}}>
          Please select a map to view insights.
        </Typography>
        <Button 
          variant="contained" 
          color="primary"
          onClick={handleGoBack}
        >
          Create Map
        </Button>
      </Box>
    </>
  );
};

export default EmptyInsights;