import { Container } from "@mui/material";
import { useUrlParams } from "findem-helpers";
import { useRef } from "react";
import styled from "styled-components";

interface IIframePageProps {
  iframeRoute: string;
}

const IframePage: React.FC<IIframePageProps> = ({ iframeRoute }) => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const {joid} = useUrlParams();
  
  return (
    <Container sx={{ p: '0px !important', minWidth: "100%" }}>
      <IframeWrapper ref={iFrameRef} src={`${iframeRoute}${joid ? `&joid=${joid}` : ''}`}/>
    </Container>
  );
}

export default IframePage;

export const IframeWrapper = styled.iframe`
  border: none;
  height: ${(props) => props.height || '100vh'};
  min-width: 100%;
`;

