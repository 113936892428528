import React, { useEffect } from 'react';
import { useCurrentUser } from '../../common/hooks/useCurrentUser';

const RouteRequiresLogin = React.lazy(() => import('./RequiresLogin'));

function PrivateRoute({ children, ...rest }: { children?: React.ReactNode }) {
  const [showLoading, setShowLoading] = React.useState(true);
  const { data: user, isLoading } = useCurrentUser();

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowLoading(false);
      }, 200);
    }
  }, [isLoading]);

  if (showLoading) {
    return <></>;
  } else {
    return (
      <>
        {user?.uid ? (
          children
        ) : (
          <React.Suspense fallback={null}>
            <RouteRequiresLogin />
          </React.Suspense>
        )}
      </>
    );
  }
}

export default PrivateRoute;
