export const biz_models = [
    'Advertising',
    'Affiliate',
    'Aircraft Management',
    'Aircraft Sales',
    'Assisted Living',
    'B2B',
    'B2B & B2B',
    'B2B.B2C',
    'B2B2C',
    'B2B2G',
    'B2C',
    'B2C.',
    'B3B',
    'Banking',
    'Brick and Mortar',
    'C2C',
    'Catering',
    'Charter',
    'Classifieds',
    'Completions',
    'Consulting',
    'Contracting',
    'Defence',
    'Direct Sales',
    'Distressed Debt',
    'Donations',
    'Drive-Through',
    'Education',
    'Energy Storage',
    'Engineering',
    'Events',
    'FBO',
    'Fleet Outsourcing',
    'Franchise',
    'Freemium',
    'Gathering',
    'General Contractor',
    'Healthcare Staffing',
    'Home Care',
    'Hospice Care',
    'Independent Living',
    'Integrated Transportation',
    'Investment Sales',
    'Investments',
    'Last Mile Services',
    'Lead Generation',
    'Licensing',
    'Liscensing',
    'Loan Servicing',
    'Loans',
    'Logistics',
    'Maintenance',
    'MarketPlace',
    'Marketing',
    'Marketplace',
    'Memory Care',
    'Metamediary',
    'Mortgage',
    'Mortgage Banking',
    'Natural gas',
    'On-Demand',
    'On-Demand Brick and Mortar',
    'Operations and Maintenance',
    'Outpatient Rehabilitation',
    'Outreach',
    'Outsourced Services',
    'Patient Care',
    'Private Equity',
    'Processing',
    'Procurement Construction',
    'Project Development Support',
    'Recruitment',
    'Rent to Own',
    'Repowering',
    'Research',
    'Respite Care Short Term Stays',
    'SaaS',
    'Specialized Care',
    'Staffing',
    'Subsciption',
    'Subscription',
    'Technology',
    'Training',
    'Transportation Management',
    'Volunteering',
    'Wholesale',
    'Workforce Solutions',
    'marketplace'
]