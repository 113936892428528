export const JOID_QUERY_PARAM = "joid";

export function getQueryParamsToPersist(queryParams: URLSearchParams): URLSearchParams {
  const queryParamsToKeep: URLSearchParams = new URLSearchParams();

  if (queryParams.has(JOID_QUERY_PARAM)) {
    queryParamsToKeep.set(JOID_QUERY_PARAM, queryParams.get(JOID_QUERY_PARAM) ?? "");
  }

  return queryParamsToKeep;
}