import { decodeString, getPrimaryIPOInfo, humanizeCount, useUrlParams } from 'findem-helpers';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../common/contexts/UserContext';
import { companyListApis } from '../../../common/services/apis';
import { ICompanyProfile, IEdgarFinancialInfo, IIPOInfo } from '../../../common/types/companyDetails';
import styled from 'styled-components';
import { Box, Drawer, Skeleton, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { colors } from '../../../common/constants';
import { Description } from './Description';
import TableDetails from './TableDetails';
import CompanyGraph from './CompanySizeGraph';
import { curveBumpX } from 'd3-shape';
import CompanyEdgarGraph from './CompanyEdgarGraph';
import Funding from './Funding';
import Products from './Products';
import { CompanyImage, LOGO_URL } from '../../../CompanyColumns';
import { imgOnError } from '../../../utils';
import { getCompLinkedInId } from '../../../common/utils/getCompanyLinkedInId';
import CloseIcon from '@mui/icons-material/Close';

interface ICompanyProfileDetailsProps {
  companyId: string;
  open: boolean;
  onClose: () => void;
}
export const CHART_WIDTH: number = 450;
export const BUMP_INT = curveBumpX;
export const DATE_FORMAT: string = 'MMMM D, YYYY';
export const MAX_CONTENT_LENGTH: number = 8;

const CompanyProfileDetails: React.FC<ICompanyProfileDetailsProps> = ({ companyId, open, onClose }) => {

  const { joid } = useUrlParams();
  const {user} = useContext(UserContext);
  const [companyDetails, setCompanyDetails] = useState<ICompanyProfile | undefined>(undefined);
  const [edgarData, setEdgarData] = useState<IEdgarFinancialInfo | undefined>(undefined);

  const { data: companyDetailsData, isLoading } = companyListApis.getCompanyDetails.useQuery({
    variables: {
      joid: joid || user.uid,
      id: companyId,
    }
  });


  const parseIntAndHumanizeCount = (data: number | string | null | undefined) => {
    if (data == undefined || (typeof data === 'string' && data.trim().length === 0)) {
      return undefined;
    }
    const numericValue = typeof data !== 'number' ? parseInt(data) : data;
    if (isNaN(numericValue)) {
      return data;
    }
    return humanizeCount(numericValue);
  };



  const cik: string | undefined = React.useMemo(
    () => companyDetails?.ext_refs.find((ref) => ref.source === 'edgar')?.ref, [companyDetails]);

  const ipo_info: IIPOInfo | undefined = React.useMemo(
    () => getPrimaryIPOInfo(companyDetails),
    [companyDetails]
  );

  const ticker: string | undefined = React.useMemo(
    () => ipo_info && ipo_info.stock_id ? ipo_info.stock_id : undefined,
    [ipo_info]
  );

  const exchange: string | undefined = React.useMemo(
    () => companyDetails && companyDetails.ipo_info && companyDetails.ipo_info.length > 0 ? companyDetails.ipo_info[0].stock_market_id : undefined, [companyDetails]);
 

  const currentEmployeeCount = React.useMemo(() => {
    if(!companyDetails || companyDetails.comp_size.length === 0){
      return null;
    }
    return parseIntAndHumanizeCount(companyDetails.comp_size[companyDetails.comp_size.length - 1].employees);
  }, [companyDetails]);

  const revenue = React.useMemo(
    () => parseIntAndHumanizeCount(companyDetails?.curr_financial_info?.est_revenue),
    [companyDetails?.curr_financial_info?.est_revenue]
  );

  const marketCap = React.useMemo(
    () => parseIntAndHumanizeCount(companyDetails?.curr_financial_info?.latest_valuation),
    [companyDetails?.curr_financial_info?.latest_valuation]
  );

  const netIncome = React.useMemo(
    () => parseIntAndHumanizeCount(companyDetails?.curr_financial_info?.net_income),
    [companyDetails?.curr_financial_info?.net_income]
  );

  const competitors: string[] | undefined = React.useMemo(
    () => companyDetails?.competitors.map((competitor) => competitor.name),
    [companyDetails]
  );

  const logoId: string | undefined = React.useMemo(() => companyDetails ? getCompLinkedInId(companyDetails) : undefined, [companyDetails]);

  const tableData: { title: string, value: (undefined | string | JSX.Element) }[] = React.useMemo(() => {
    const data: { title: string, value: string | JSX.Element }[] = [];
    if (companyDetails) {
      data.push({ title: 'Company:', value: decodeString(companyDetails.company_name) });
      data.push({ title: 'Domain:', value: companyDetails.domain ?? '-' });
      data.push({ title: 'CIK:', value: cik ? <a href={`https://www.sec.gov/edgar/browse/?CIK=${cik}`} target="_blank">{cik}</a> : '' });
      data.push({ title: 'Status:', value: companyDetails?.last_known_state ?? '' });
      data.push({ title: 'Organization Type:', value: companyDetails?.org_type_raw ?? companyDetails?.org_type ?? '' });
      data.push({ title: 'Stock ID:', value: ticker ?? '' });
      data.push({ title: 'Exchange:', value: exchange ?? '' });
      data.push({ title: 'Industry:', value: companyDetails?.industry.categories.length > 0 ? companyDetails?.industry.categories.join(', ') : '' });
      data.push({ title: 'Industry Subcategory:', value: companyDetails?.industry?.sub_categories.length > 0 ? companyDetails.industry?.sub_categories.join(', ') ?? '' : '' });
      data.push({ title: 'Employee Count:', value: currentEmployeeCount?.toString() ?? '' });
      data.push({ title: 'Revenue:', value: revenue?.toString() ?? '' });
      data.push({ title: 'Market Cap:', value: marketCap?.toLocaleString() ?? '' });
      data.push({ title: 'Business Models:', value: companyDetails?.biz_models?.join(', ') ?? '' });
      data.push({ title: 'Groups:', value: companyDetails?.industry?.crunchbase_category_groups?.join(', ') ?? '' });
      data.push({ title: 'Competitors:', value: competitors?.join(', ') ?? '' });
    }
    return data;
  }, [cik, companyDetails, competitors, currentEmployeeCount, exchange, marketCap, revenue, ticker]);

  useEffect(() => {
    if (companyDetailsData && companyDetailsData.data && companyDetailsData.data.data) {
      setCompanyDetails(companyDetailsData.data.data.companyListDetails[0]);
      setEdgarData(companyDetailsData.data.data.edgarFinancialInfo[0] ?? undefined);
    }
  }, [companyDetailsData]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='right'
      sx={{
        zIndex: 1400
      }}
      PaperProps={{
        sx: {
          width: 600,
          padding: 3,
          paddingBottom: 3,
        }
      }}
    >
      {isLoading && <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton animation="wave" width={'100%'}/>
        <Skeleton animation="wave" width={'100%'} />
        <Skeleton animation="wave" width={'100%'} />
        <Skeleton variant="rectangular" width={'100%'} height={'80vh'} />
      </Box>}
      {!isLoading && <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', marginBottom: '50px', overflowY: 'scroll' }}>
        <HeaderLine>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {logoId && <CompanyImage onError={imgOnError} src={LOGO_URL + unescape(logoId) + '.jpg'} alt={decodeString(companyDetails?.company_name)} style={{ width: '30px', marginRight: '10px' }} />}
            <Typography variant='h4'>{decodeString(companyDetails?.company_name)}</Typography>
            {companyDetails?.domain && <CompanyLink
              target="_blank"
              href={`https://${companyDetails.domain}`}
              rel="noreferrer"
            >
              <OpenInNewIcon sx={{fontSize: 16}} />
            </CompanyLink>}
          </Box>
          {/* close icon */}
          <CloseIcon onClick={onClose} sx={{cursor: 'pointer', color: colors['grey-500'], fontSize: '20px'}} />
        </HeaderLine>
        {companyDetails?.description && (
          <>
            <Typography 
              variant="h5"
              sx={{ fontSize: '14px', color: colors['grey-500'], margin: '8px 0px', fontWeight: 400, fontStretch: 'condensed', textTransform: 'uppercase'}}
              >Summary</Typography>
            <Description description={companyDetails.description} autoExpand={true} />
          </>
        )}
        <TableDetails data={tableData} />
        {companyDetails?.comp_size && companyDetails?.comp_size?.length > 0 && (<>
          <Typography 
            variant="h5"
            sx={{ fontSize: '14px', color: colors['grey-500'], margin: '8px 0px', fontWeight: 400, fontStretch: 'condensed', textTransform: 'uppercase'}}
            >Company Size</Typography>
          <CompanyGraph
            size={companyDetails.comp_size}
          />
        </>)}
        {edgarData && <CompanyEdgarGraph companyEdgarData={edgarData} />}
        {companyDetails && <Funding funding={companyDetails.funding} />}
        {companyDetails && <Products products={companyDetails.products} />}
      </Box>}
    </Drawer>
  );
};

export default CompanyProfileDetails;

const HeaderLine = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2px;
  justify-content: space-between;
`;

const CompanyLink = styled.a`
  margin-left: 0.5em;
  color: ${colors['grey-500']};
  align-items: flex-end;
  display: flex;

  &:hover,
  &:focus {
    color: ${colors['grey-700']};
  }
`;