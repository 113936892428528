import { ICompanyProfile } from "../types/companyDetails";
import { ICompanyDetails } from "../types/companyListType";

export const getCompLinkedInId = (comp: ICompanyDetails | ICompanyProfile) => {
  if(comp.logos){
    const linkedInRef = comp.logos.find((ref) => ref.source === 'linkedin');
    if(linkedInRef){
      const liId = linkedInRef.ref;
      return liId;
    }
  }
  return undefined;
}