export interface ICompanyListType {
  companies: string[],
  name: string,
  owner_id: string,
  ref: string,
  created_at: string,
  updated_at: string
}

interface ICompanySize {
  date: string,
  employees: string
}

interface IFinancialInfo {
  est_revenue: string,
  last_funding_amount: number,
  last_funding_date: string,
  latest_valuation: string | null,
}

export interface ILogos {
  ref: string,
  source: string,
}

interface ICompanyExtRefs extends ILogos {
  url: string | null,
}

export interface ICompanyDetails {
  acquisition_info: string[],
  alexa_ranking: string,
  biz_models: string[],
  company_name: string,
  comp_size: ICompanySize[],
  competitors: string[],
  curr_financial_info: IFinancialInfo,
  description: string,
  domain: string,
  email_domains: string[],
  emp_count_range: string,
  exit_info: any,
  ext_refs: ICompanyExtRefs[],
  logos: ILogos[],
  financial_info: IFinancialInfo[],
  funding: any,
  hq_location: any,
  industry: any,
  last_known_state: string,
  majestic_ranking: number,
  meta_ref: string,
  org_info: any,
  org_type: string,
  other_domains: string[],
  other_names: string[],
  parent_companies: string[],
  pb_data: any,
  products: string[],
  quantcast_ranking: number,
  ref: string,
  score: number,
  start_date: number,
  subsidiaries: string[],
  tech_stacks: any,
  umbrella_ranking: number,
  updated_at: string,
  website?: string,
}

export interface ICompanyDetailsRow {
  id: string,
  company_name: { url: string, name: string },
  description: string,
  last_known_state: string,
  'industry.crunchbase_category_groups': string [],
  'industry.sub_categories': string[],
  'curr_financial_info.est_revenue': string,
  comp_size: ICompanySize,
  org_type: string,
  website: string,
  biz_models: string[],
  "hq_location.state": string,
  "hq_location.country": string,
  "pb_data.ownership_status": string,
  "funding": any,
  linkedIn_id: string | undefined,
}

export interface ICompanyListRow {
  id: string,
  name: string,
  ref: string,
  date: string,
  companies: string[]
}

export enum RegencyType {
  current = 'current',
  past = 'past',
  recent = 'recent',
  past_and_current = 'past_and_current'
}