export const categories = [
  { title: "Industry" },
  { title: "Company" },
  { title: "Company Lists" },
  { title: "Job Title" },
  { title: "Skills" },
  { title: "Company Business Model"},
  { title: "Company Stage" },
];

export const operations = [
  { title: "Current Experience" },
  { title: "Last 3 years" },
  { title: "Anytime" }
];