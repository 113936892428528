import { createTheme, Theme, ThemeOptions } from "@mui/material";
import { colors } from "..";
import { BorderBottom } from "@mui/icons-material";

export const APP_THEME_OPTIONS: ThemeOptions = {
  typography: {
    fontSize: 20,
    fontFamily: "Roboto, sans-serif",
    h1: {
      color: colors.black,
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "25px",
    },
    h4: {
      color: colors["grey-700"],
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "25px",
    },
    h5: {
      color: colors["grey-700"],
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "25.6px",
    },
    subtitle1: {
      color: colors["grey-700"],
      fontSize: "14px",
      fontWeight: 500,
    },
    subtitle2: {
      color: colors["grey-600"],
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14px",
    },
    body1: {
      color: colors["grey-600"],
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      color: colors["grey-500"],
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    button: {
      color: colors.white,
      fontSize: "14px",
      textTransform: "none",
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: colors["blue-300"],
      contrastText: colors.white,
    },
    secondary: {
      main: colors["grey-500"],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "13px",
          boxShadow: "none",
          "&.MuiButton-containedPrimary": {
            backgroundColor: colors["blue-300"],
            "&:hover":{
              backgroundColor: colors["blue-400"],
            },
          },
          "&:hover":{
            boxShadow: "none",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides:{
        paper:{
          boxShadow: "none",
          border: 'none',
          // select 3rd child div
          "& div:nth-child(3)":{
            border: `1px solid ${colors['grey-300']}`,
            borderRadius: "8px",
          },
        },
      }
    },
    MUIDataTableHeadRow:{
      styleOverrides: {
        root: {
          "& th":{
            // borderTop: `1px solid ${colors["grey-300"]}`,
          },
          "& th:last-child":{
            // borderRight: `1px solid ${colors["grey-300"]}`,
            "& div":{
              float: "right",
              paddingRight: "16px",
            }
          },
          "& th:first-child":{
            // borderLeft: `1px solid ${colors["grey-300"]}`,
          }   
        }
      }
    },
    MUIDataTableBodyRow:{
      styleOverrides: {
        root: {
          "& td:last-child":{
            // borderRight: `1px solid ${colors["grey-300"]}`,
          },
          "& td:first-child":{
            // borderLeft: `1px solid ${colors["grey-300"]}`,
          },
          // last tr of the tbody // 
          "&:last-child":{
            "& td":{
              borderBottom: `none`,
            }
          }
        }
      }
    },
  },
};

export const APP_THEME: Theme = createTheme(APP_THEME_OPTIONS);
