import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import HotTubIcon from '@mui/icons-material/HotTub';
import { colors } from '../../../common/constants';

const DefaultNoAccessComponent = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '100vh',
          minWidth: '100%',
          backgroundColor: colors['white'],
          gap: '1em'
        }}
      >
        <HotTubIcon sx={{fontSize: '18em', color: colors['grey-500']}}/>
        <Typography variant="h1" sx={{ color: colors['grey-500'] }}>
          Not Found
        </Typography>
        <Typography variant="subtitle1" sx={{color:colors['grey-500'], fontWeight: 400}}>
          Either you typed a wrong URL, or you followed a bad link.
        </Typography>
        <Button 
          variant="contained" 
          color="primary"
          onClick={handleGoBack}
        >
          Go Back
        </Button>
      </Box>
    </>
  );
};

export default DefaultNoAccessComponent;